 
import AppConsts from './../lib/appconst';
import axios from 'axios';



declare var abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 80000, 
});
  

http.defaults.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
http.defaults.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();
http.defaults.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken(); 
http.interceptors.response.use(
  response => {
    return response;
  },
  error => {

  //  if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
      //  Modal.error({
      //   title: error.response.data.error.message,
      //   content: error.response.data.error.details,
   // } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
      //Modal.error({
      //  title: error.response.data.error.message,
      //  content: error.response.data.error.response,
      //});
 //   } else if (!error.response) {
     // Modal.error({ content: error.toString() });
   // }

    //setTimeout(() => { }, 15000);
    return;
  }
);

 

export default http;
