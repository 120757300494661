import { action, observable } from 'mobx';
import type { CreateRoleInput } from '../services/role/dto/createRoleInput';
import { EntityDto } from '../services/dto/entityDto';
import { GetAllRoleOutput } from '../services/role/dto/getAllRoleOutput';
import type { GetRoleAsyncInput } from '../services/role/dto/getRolesAsyncInput';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import type { PagedRoleResultRequestDto } from '../services/role/dto/PagedRoleResultRequestDto';
import RoleEditModel from '../models/Roles/roleEditModel';
import type { UpdateRoleInput } from '../services/role/dto/updateRoleInput';
import roleService from '../services/role/roleService';
import RoleModel from '../models/Roles/roleModel';
import type { RcFile } from 'antd/lib/upload';
import { FileUpload } from '../services/dto/fileActionResultDto';
import type { Data, Fields, Meta, S2DataConfig } from '@antv/s2'; 
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto'; 


class RoleStore {
  
  @observable entity_MaxResultCount: number = 10000;
  @observable entity_SkipCount: number = 0;
  @observable action_MaxResultCount: number = 1000;
  @observable action_SkipCount: number = 0;

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto> ;
  @observable roles!: PagedResultDto<GetAllRoleOutput>;
  @observable roleEdit: RoleEditModel = new RoleEditModel();

  @observable assetCache!: PagedResultDto<FileUpload>;
  @observable profileID: number = 0;


  @observable search_Mentions: any[] = [];
  @observable search_Hashtags: any[] = [];

  @observable dataExportTable!: S2DataConfig;
  @observable dataExportTable_Analytic!: S2DataConfig;
  @observable dataExportTableData: Data[] = [];
  @observable dataExportTableTotalData: Data[] = [];
  @observable dataTableFields_Analytic: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'name', 'displayName'],
  };
  @observable dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'name', 'displayName'],
  };
  @observable dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "displayName",
      "name": "Display Name"
    }, 
  ];

  
  @observable entityActivity_dataExportTable!: any;
  @observable entityActivity_dataExportTableData: Data[] = [];
  @observable entityActivity_dataExportTableTotalData: Data[] = [];
  @observable entityActivity_dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'actionType', 'entityName'],
  };
  @observable entityActivity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "actionType",
      "name": "Type"
    },
    {
      "field": "actionID",
      "name": "Action ID"
    },
    {
      "field": "entityName",
      "name": "Entity"
    },
    {
      "field": "entityID",
      "name": "Entity ID"
    },
    {
      "field": "previousStateName",
      "name": "Previous State"
    },
    {
      "field": "previousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "previousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "newStateName",
      "name": "New State"
    },
    {
      "field": "newStateAction",
      "name": "New Action"
    },
    {
      "field": "newStateID",
      "name": "New State ID"
    },
    {
      "field": "itemName",
      "name": "Item"
    },
    {
      "field": "itemID",
      "name": "Item ID"
    },
    {
      "field": "itemValue",
      "name": "Item Value"
    },
    {
      "field": "notes",
      "name": "Notes"
    },
    {
      "field": "createdTime",
      "name": "Created"
    },
    {
      "field": "creatorID",
      "name": "Creator"
    },
    {
      "field": "ownerID",
      "name": "Owner"
    },
    {
      "field": "relatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "relatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "linkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "linkedEntityID",
      "name": "Linked Entity ID"
    },
  ];


  //--------------------------------------------------------------------------

  @action
  async initUpdateObject() {
    
    
  }

  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await roleService.uploadImage(formData);
    this.assetCache.items.push(result);
    return result;
  }

  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await roleService.uploadImage(formData);
    this.assetCache.items.push(result);
    this.profileID = result.recordID;
    return result;
  }


  @action
  async getProfileImage(recordID: number) {
 
    if (recordID === 0 || recordID === 1) {  
      return '_';
    }
    else
    { 
      let result = await roleService.getImage(recordID);
      return result.url;
    }
  }


  public async resetAssetCache() {
    this.assetCache = {
      totalCount: 0,
      items: []
    };

    this.profileID = 0;
  }

  public async commitAssetCache(linkedID: number, entityType: number) {

    this.assetCache.items.map((x: FileUpload) => {

      return roleService.updateRecord({
        id: x.recordID,
        relatedEntityType: entityType,
        relatedEntityID: linkedID,
        linkedEntityType: 14,
        LinkedEntityID: x.assetID,
      });
    })

    this.assetCache = {
      totalCount: 0,
      items: []
    };

  }


  //--------------------------------------------------------------------------

  @action
  async create(createRoleInput: CreateRoleInput) { 
    let result = await roleService.create(createRoleInput);
    this.commitAssetCache(result.items[0].id, 0);
  }

  @action
  async getRolesAsync(getRoleAsyncInput: GetRoleAsyncInput) {
    await roleService.getRolesAsync(getRoleAsyncInput);
 
  }

  @action
  async update(updateRoleInput: UpdateRoleInput) {
    let result = await roleService.update(updateRoleInput);
 
    this.commitAssetCache(result.id, 0);
    this.roles.items
      .filter((x: GetAllRoleOutput) => x.id === updateRoleInput.id)
      .map((x: GetAllRoleOutput) => {
        return (x = updateRoleInput);
      });
  }

  @action
  async delete(entityDto: EntityDto) { 

    await roleService.delete(entityDto);
    this.roles.items = this.roles.items.filter((x: GetAllRoleOutput) => x.id !== entityDto.id);
  }

  @action
  async getRoleForEdit(entityDto: EntityDto) {
    let result = await roleService.getRoleForEdit(entityDto);
    this.roleEdit.grantedPermissionNames = result.grantedPermissionNames;
    this.roleEdit.permissions = result.permissions;
    this.roleEdit.role = result.role;
 
  }

  @action
  async clearEditRole() {
    this.roleEdit.grantedPermissionNames = [];
    this.roleEdit.permissions = [];
    this.roleEdit.role = new RoleModel();
  }

  @action
  async addGrantedPermission(name: string) {
    this.roleEdit.grantedPermissionNames.push(name);

  }

  @action
  async removeGrantedPermission(name: string) {
    this.roleEdit.grantedPermissionNames = this.roleEdit.grantedPermissionNames.filter((x: string) => x !== name);


  }

  @action
  async get(entityDto: EntityDto) {
    var result = await roleService.get(entityDto);
    this.roles = result.data.result;
  }

  @action
  async getAll(pagedFilterAndSortedRequest: PagedRoleResultRequestDto) {
    
    this.roles =
    {
      totalCount: 0,
      items: []
    } 

    this.dataExportTableData = [];
    this.dataExportTableTotalData = [];
  
    let result = await roleService.getAll(pagedFilterAndSortedRequest);

    if (result !== undefined) {
      this.roles =
      {
        totalCount: result.totalCount,
        items: result.items
      }
    }
   
 
    // Loop Users
    result.items.map((x: GetAllRoleOutput) => {
      let resultString = x.name === undefined ? '' : x.name;

      // Get Data for Mentions
      if (resultString !== '' && this.search_Mentions.includes(resultString) === false) {
        this.search_Mentions.push(resultString);
      }

      // Get Data for Export Table
      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
      }

      return this.dataExportTableData.push(dataItem);

    });

    this.dataExportTable = {
      fields: this.dataTableFields,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    } 
    this.dataExportTable_Analytic = {
      fields: this.dataTableFields_Analytic,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    } 
  }
}

export default RoleStore;
