export const SummaryReportSourceTypes = {
 
    stateTypeSummary: 0,
    slaSummary: 1, 

    creatorSummary: 2, 
    ownerTypeSummary: 3,   
    ownerSummary_Users: 4,
    ownerSummary_People: 5, 
    ownerSummary_Teams: 6,
    ownerSummary_Combined: 7,

    stateSummary: 8,
    stateByCreator: 9,
    stateByOwner: 10,

    templateSummary: 11,
    templateByCreator: 12,
    templateByOwner: 13,

    totalBudgetCost: 14,
    budgetCostByCreator: 15,
    budgetCostByOwner: 16,
    budgetCostByState: 17,
    budgetCostByTempalte: 18,

    totalBudgetHours: 19,
    budgetHoursByCreator: 20,
    budgetHoursByOwner: 21,
    budgetHoursByState: 22,
    budgetHoursByTempalte: 23,

    totalCost: 24,
    costByCreator: 25,
    costByOwner: 26,
    costByState: 27,
    costByTempalte: 28,

    totalHours: 29,
    hoursByCreator: 30,
    hoursByOwner: 31,
    hoursByState: 32,
    hoursByTemplate: 33,

    completedOnTimeRatio: 34,
    onTimeByCreator: 35,
    onTimeByOwner: 36,
    onTimeByState: 37,
    onTimeByTempalte: 38,

    averageTimeToStart: 39,
    averageTimeToStartByCreator: 40,
    averageTimeToStartByOwner: 41,
    averageTimeToStartByState: 42,
    averageTimeToStartByTempalte: 43,

    averagetimeToComplete: 44,
    averageTimeToCompleteByCreator: 45,
    averageTimeToCompleteByOwner: 46,
    averageTimeToCompleteByState: 47,
    averageTimeToCompleteByTempalte: 48,

    averageTotalTime: 49,
    averageTotalTimeByCreator: 50,
    averageTotalTimeByOwner: 51,
    averageTotalTimeByState: 52,
    averageTotalTimeByTempalte: 53,

    averageTimeOverdue: 54,
    averageTimeOverdueByCreator: 55,
    averageTimeOverdueByOwner: 56,
    averageTimeOverdueByState: 57,
    averageTimeOverdueByTempalte: 58,

    totalTimeOverdue: 59,
    totalTimeOverdueByCreator: 60,
    totalTimeOverdueByOwner: 61,
    totalTimeOverdueByState: 62,
    totalTimeOverdueByTempalte: 63,

    totalCostOverdue: 64,
    totalCostOverdueByCreator: 65,
    totalCostOverdueByOwner: 66,
    totalCostOverdueByState: 67,
    totalCostOverdueByTempalte: 68,

    averageBudgetHours: 69,
    averageBudgetHoursByCreator: 70,
    averageBudgetHoursByOwner: 71,
    averageBudgetHoursByState: 72,
    averageBudgetHoursByTempalte: 73,

    averageBudgetCost: 74,
    averageBudgetCostByCreator: 75,
    averageBudgetCostByOwner: 76,
    averageBudgetCostByState: 77,
    averageBudgetCostByTempalte: 78,

    averageTotalCost: 79,
    averageTotalCostByCreator: 80,
    averageTotalCostByOwner: 81,
    averageTotalCostByState: 82,
    averageTotalCostByTempalte: 83,

    completedInBudgetRatio: 84,
    completedInBudgetRatioByCreator: 85,
    completedInBudgetRatioByOwner: 86,

    inQueuePercentage: 87,
    inQueueByCreator: 88,
    inQueueByOwner: 89,
    inQueuePercentageByCreator: 90,
    inQueuePercentageByOwner: 91,

    inProgressPercentage: 92,
    inProgressByCreator: 93,
    inProgressByOwner: 94, 
    inProgressPercentageByCreator: 95,
    inProgressPercentageByOwner: 96,

    completePercentage: 97,
    completePercentageByCreator: 98,
    completePercentageByOwner: 99,
    completePercentagePercentageByCreator: 100,
    completePercentagePercentageByOwner: 101,

    slaOverduePercentage: 102,
    slaOverdueByCreator: 103,
    slaOverdueByOwner: 104,
    slaOverduePercentageByCreator: 105,
    slaOverduePercentageByOwner: 106,

    completedInTime: 107,
    completedInBudget: 108,

    slaOverdue: 109,
    myTickets: 110,

    beginningStates: 111,
    workflowStates: 112,
    endState: 113,
    terminalState: 114,
    userOwners: 115,
    peopleOwners: 116,
    teamOwners: 117,

    created: 118,
    lastModified: 119,

}