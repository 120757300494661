
import { InventorySummaryReturnDto } from './dto/summaries/inventorySummaryReturnDto'
import { InventoryReturnDto } from './dto/inventoryReturnDto'; 
import { InventoryCreateDto } from './dto/inventoryCreateDto';
import { InventoryAskDto } from './dto/inventoryAskDto';
import http from '../httpService';
import { InventoryEntityDto } from './dto/entities/inventoryEntityDto';
import { InventoryItemDto } from './dto/entities/inventoryItemDto';
import { InventoryOptionDto } from './dto/entities/inventoryOptionDto';
import { InventoryRecordDto } from './dto/entities/inventoryRecordDto';
import { InventoryStateDto } from './dto/entities/inventoryStateDto';
import { InventoryTypeDto } from './dto/entities/inventoryTypeDto'; 
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { InventoryTemplateDto } from './dto/entities/inventoryTemplateDto';
import { InventoryEntitySummaryDto } from './dto/summaries/inventoryEntitySummaryDto';
import { InventoryItemSummaryDto } from './dto/summaries/inventoryItemSummaryDto';
import { InventoryOptionSummaryDto } from './dto/summaries/inventoryOptionSummaryDto';
import { InventoryStateSummaryDto } from './dto/summaries/inventoryStateSummaryDto';
import { InventoryTemplateSummaryDto } from './dto/summaries/inventoryTemplateSummaryDto';
import { InventoryTypeSummaryDto } from './dto/summaries/inventoryTypeSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto'; 
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto'; 
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto';
import { InfoHubArticleSummaryDto } from '../infoHub/dto/infoHubArticleSummaryDto';
import { CommunicationListSummaryDto } from '../dto/summaries/communicationListSummaryDto';
import { InventoryListSummaryDto } from '../dto/summaries/inventoryListSummaryDto';
import { MenuListSummaryDto } from '../dto/summaries/menuListSummaryDto';
import { PaymentsListSummaryDto } from '../dto/summaries/paymentsListSummaryDto';
import { ReportsListSummaryDto } from '../dto/summaries/reportsListSummaryDto';
import { SchedulingListSummaryDto } from '../dto/summaries/schedulingListSummaryDto';
import { WorkOrderListSummaryDto } from '../dto/summaries/workOrderListSummary';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { IntegrationListSummaryDto } from '../dto/summaries/integrationListSummaryDto';



class InventoryService {

    public async get(askDto: InventoryAskDto): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Inventory/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Inventory/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.post('api/services/app/Inventory/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let deleteResult = await http.post('api/services/app/Inventory/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: InventoryAskDto): Promise<InventorySummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, InventoryEntitySummaryDto, InventoryTypeSummaryDto, InventoryTemplateSummaryDto, InventoryStateSummaryDto, InventoryOptionSummaryDto, InventoryItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Inventory/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }
  
    //------------------------------------------------------------------
 
    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Inventory/UploadFile', file);
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Inventory/GetFile?id=' + id);
        return getResult.data.result;
    }

    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Inventory/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }

    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Inventory/CommitEntityFiles', input);
        return getResult.data.result;
    }

    //------------------------------------------------------------------

    public async getNotes(askDto: InventoryAskDto): Promise<InventorySummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, InventoryEntitySummaryDto, InventoryTypeSummaryDto, InventoryTemplateSummaryDto, InventoryStateSummaryDto, InventoryOptionSummaryDto, InventoryItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getRecordsResult = await http.get('api/services/app/Inventory/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Inventory/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto:  InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Inventory/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }
 
}

export default new InventoryService();
