 
import TenantLoginInfoDto from './tenantLoginInfoDto';
import UserLoginInfoDto from './userLoginInfoDto';

export class GetCurrentLoginInformations {
  application!: any;
  user!: UserLoginInfoDto;
  tenant!: TenantLoginInfoDto;
  roles!: string[];
 
  communication?: OwnerObjet;
  integrations?:  OwnerObjet;
  inventory?:  OwnerObjet;
  menus?:  OwnerObjet;
  payments?:  OwnerObjet;
  personas?:  OwnerObjet;
  teams?:  OwnerObjet;
  reports?:  OwnerObjet;
  schedules?:  OwnerObjet;
  workOrders?:  OwnerObjet;
}

export class OwnerObjet {

  communication?: number[];
  integrations?: number[];
  inventory?: number[];
  menus?: number[];
  payments?: number[];
  personas?: number[];
  teams?: number[];
  reports?: number[];
  schedules?: number[]; 
  workOrders?: number[]; 
}
