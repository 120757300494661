import { action, observable, } from 'mobx';
import type { InventoryReturnDto } from '../services/inventory/dto/inventoryReturnDto';
import type { InventoryCreateDto } from '../services/inventory/dto/inventoryCreateDto';
import type { InventoryAskDto } from '../services/inventory/dto/inventoryAskDto';
import { InventoryEntityDto } from '../services/inventory/dto/entities/inventoryEntityDto';
import { InventoryItemDto } from '../services/inventory/dto/entities/inventoryItemDto';
import { InventoryOptionDto } from '../services/inventory/dto/entities/inventoryOptionDto';
import { InventoryRecordDto } from '../services/inventory/dto/entities/inventoryRecordDto';
import { InventoryStateDto } from '../services/inventory/dto/entities/inventoryStateDto';
import { InventoryTypeDto } from '../services/inventory/dto/entities/inventoryTypeDto';
import inventoryService from '../services/inventory/inventoryService';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import { InventoryTemplateDto } from '../services/inventory/dto/entities/inventoryTemplateDto';
import { UpdateInventoryOptionList } from '../services/inventory/dto/views/updateInventoryOptionList';
import { UpdateInventoryItemList } from '../services/inventory/dto/views/updateInventoryItemList';
import type { UpdateInventoryStateList } from '../services/inventory/dto/views/updateInventoryStateList';
import type { UpdateInventoryTypeList } from '../services/inventory/dto/views/updateInventoryTypeList';

import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import type { UpdateInventoryTemplateList } from '../services/inventory/dto/views/updateInventoryTemplateList';
import { EntityDto } from '../services/dto/entityDto';
import type { RcFile } from 'antd/lib/upload';
import type { UploadFile } from 'antd/lib/upload/interface';
import type { EntityCommitContainer, FileUpload } from '../services/dto/fileActionResultDto';

import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import type { Data, Fields, Meta, SortParam } from '@antv/s2';
import { FormGridDto } from '../components/EntityConfigs/formGridDto';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import { NoteDto } from '../services/dto/entities/noteDto';
import { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import { NotificationDto } from '../services/dto/entities/notificationDto';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import type { UserListSummaryDto } from '../services/dto/summaries/userListSummaryDto';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import { ValueReturnDto } from '../services/dto/values/valueReturnDto';
import { L, isGranted } from '../lib/abpUtility';
import communicationService from '../services/communication/communicationService';
import integrationService from '../services/integrations/integrationService';
import paymentService from '../services/payments/paymentService';
import peopleService from '../services/people/peopleService';
import reportService from '../services/report/reportService';
import schedulingService from '../services/scheduling/schedulingService';
import teamService from '../services/team/teamService';
import workOrderService from '../services/workOrder/workOrderService';
import { TemplateRecordDto } from '../services/dto/records/templateRecordDto';
import type { PersonListSummaryDto } from '../services/dto/summaries/personListSummaryDto';
import type { TeamListSummaryDto } from '../services/dto/summaries/teamListSummaryDto';
import { GetAllRoleOutput } from '../services/role/dto/getAllRoleOutput';
import { formItemTypes } from '../components/EntityConfigs/formItemTypes';
import userService from '../services/user/userService';
import infoHubService from '../services/infoHub/infoHubService';
import type { CommunicationListSummaryDto } from '../services/dto/summaries/communicationListSummaryDto';
import type { InventoryListSummaryDto } from '../services/dto/summaries/inventoryListSummaryDto';
import type { MenuListSummaryDto } from '../services/dto/summaries/menuListSummaryDto';
import type { PaymentsListSummaryDto } from '../services/dto/summaries/paymentsListSummaryDto';
import type { ReportsListSummaryDto } from '../services/dto/summaries/reportsListSummaryDto';
import type { SchedulingListSummaryDto } from '../services/dto/summaries/schedulingListSummaryDto';
import type { WorkOrderListSummaryDto } from '../services/dto/summaries/workOrderListSummary';
import menuService from '../services/menu/menuService';
import { PermissionNames } from '../components/EntityConfigs/permissionNames';
import { ApprovalTypes } from '../components/EntityConfigs/approvalTypes';
import { StateTypes } from '../components/EntityConfigs/stateTypes';
import { TemplateFormTypes } from '../components/EntityConfigs/templateFormTypes';
import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import dayjs from 'dayjs';
import { DashboardItemTypes } from '../components/EntityConfigs/dashboardItemTypes';
import { SummaryReportSourceTypes } from '../components/EntityConfigs/summaryReportSourceTypes';
import type { IntegrationListSummaryDto } from '../services/dto/summaries/integrationListSummaryDto';


class InventoryStore {
  //--------------------------------------------------------------------------

  @observable entity_MaxResultCount: number = 10000;
  @observable entity_SkipCount: number = 0;
  @observable action_MaxResultCount: number = 10000;
  @observable action_SkipCount: number = 0;

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;
  @observable entityAsk!: InventoryAskDto;

  @observable entityCreate!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityEdit!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityDelete!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
  @observable entityReturn!: InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

  //-------------------------------------------------------------------------- 

  @observable multiEntityList!: PagedResultDto<any>;

  @observable entitySummary!: PagedResultDto<any>;
  @observable entities!: PagedResultDto<any>;
  @observable entitiesList!: PagedResultDto<any>;

  @observable entitySummaryCondensed!: PagedResultDto<any>;

  @observable entityOptionSummary!: PagedResultDto<any>;
  @observable entityOptions!: PagedResultDto<any>;
  @observable optionsList!: PagedResultDto<any>;

  @observable entityStateSummary!: PagedResultDto<any>;
  @observable entityStates!: PagedResultDto<any>;
  @observable statesList!: PagedResultDto<any>;

  @observable entityItemSummary!: PagedResultDto<any>;
  @observable entityItems!: PagedResultDto<any>;
  @observable itemsList!: PagedResultDto<any>;
  @observable itemOptionsCache!: PagedResultDto<any>;

  @observable entityTypeSummary!: PagedResultDto<any>;
  @observable entityTypes!: PagedResultDto<any>;
  @observable typesList!: PagedResultDto<any>;
  @observable typeItemsCache!: PagedResultDto<any>;
  @observable typeStatesCache!: PagedResultDto<any>;
  @observable typeItemsEdit!: PagedResultDto<any>;
  @observable typeStatesEdit!: PagedResultDto<any>;

  @observable entityTemplateSummary!: PagedResultDto<any>;
  @observable entityTemplates!: PagedResultDto<any>;
  @observable templatesList!: PagedResultDto<any>;
  @observable templateItems!: PagedResultDto<any>;
  @observable defaultTemplateRecords!: Array<TemplateRecordDto>;
  @observable templateRecordCache!: Array<TemplateRecordDto>;

  @observable defaultValues!: FormGridDto[];
  @observable valuesCache!: FormGridDto[];

  //--------------------------------------------------------------------------

  @observable defaultEntityImages!: Array<UploadFile>;
  @observable defaultEntityFiles!: Array<UploadFile>;
  @observable fileCache!: EntityCommitContainer;

  //--------------------------------------------------------------------------



  //--------------------------------------------------------------------------


  @observable profileID: number = 0;
  @observable entityID: number = 0;
  @observable entityType: number = 0;
  @observable currentState: number = 0;
  @observable defaultEntityNotes!: Array<NoteDto>;
  @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;
  @observable currentNotifications: NotificationDto[] = [];
  @observable entityNotesCache!: Array<NoteDto>;
  @observable entityNoteRecordsCache!: Array<NoteRecordDto>;
  @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

  @observable users!: PagedResultDto<UserListSummaryDto>;
  @observable defaultUserList!: Array<UserListSummaryDto>;
  @observable userListCache!: Array<UserListSummaryDto>;
  @observable userListSummary!: PagedResultDto<UserListSummaryDto>;

  @observable docs!: PagedResultDto<any>;
  @observable defaultDocsList!: Array<any>;
  @observable docListCache!: Array<any>;
  @observable docListSummary!: PagedResultDto<any>;

  @observable users_Security!: PagedResultDto<UserListSummaryDto>;
  @observable defaultUserList_Security!: Array<UserListSummaryDto>;
  @observable userListCache_Security!: Array<UserListSummaryDto>;
  @observable userListSummary_Security!: PagedResultDto<UserListSummaryDto>;

  @observable persons!: PagedResultDto<PersonListSummaryDto>;
  @observable defaultPersonList!: Array<PersonListSummaryDto>;
  @observable personListCache!: Array<PersonListSummaryDto>;
  @observable personListSummary!: PagedResultDto<PersonListSummaryDto>;

  @observable persons_Security!: PagedResultDto<PersonListSummaryDto>;
  @observable defaultPersonList_Security!: Array<PersonListSummaryDto>;
  @observable personListCache_Security!: Array<PersonListSummaryDto>;
  @observable personListSummary_Security!: PagedResultDto<PersonListSummaryDto>;

  @observable teams!: PagedResultDto<TeamListSummaryDto>;
  @observable defaultTeamList!: Array<TeamListSummaryDto>;
  @observable teamListCache!: Array<TeamListSummaryDto>;
  @observable teamListSummary!: PagedResultDto<TeamListSummaryDto>;

  @observable teams_Security!: PagedResultDto<TeamListSummaryDto>;
  @observable defaultTeamList_Security!: Array<TeamListSummaryDto>;
  @observable teamListCache_Security!: Array<TeamListSummaryDto>;
  @observable teamListSummary_Security!: PagedResultDto<TeamListSummaryDto>;

  @observable communication!: PagedResultDto<CommunicationListSummaryDto>;
  @observable defaultCommunicationList!: Array<CommunicationListSummaryDto>;
  @observable communicationListCache!: Array<CommunicationListSummaryDto>;
  @observable communicationListSummary!: PagedResultDto<CommunicationListSummaryDto>;

  @observable inventory!: PagedResultDto<InventoryListSummaryDto>;
  @observable defaultInventoryList!: Array<InventoryListSummaryDto>;
  @observable inventoryListCache!: Array<InventoryListSummaryDto>;
  @observable inventoryListSummary!: PagedResultDto<InventoryListSummaryDto>;

  @observable menus!: PagedResultDto<MenuListSummaryDto>;
  @observable defaultMenuList!: Array<MenuListSummaryDto>;
  @observable menuListCache!: Array<MenuListSummaryDto>;
  @observable menuListSummary!: PagedResultDto<MenuListSummaryDto>;

  @observable payments!: PagedResultDto<PaymentsListSummaryDto>;
  @observable defaultPaymentsList!: Array<PaymentsListSummaryDto>;
  @observable paymentsListCache!: Array<PaymentsListSummaryDto>;
  @observable paymentsListSummary!: PagedResultDto<PaymentsListSummaryDto>;

  @observable reports!: PagedResultDto<ReportsListSummaryDto>;
  @observable defaultReportsList!: Array<ReportsListSummaryDto>;
  @observable reportsListCache!: Array<ReportsListSummaryDto>;
  @observable reportsListSummary!: PagedResultDto<ReportsListSummaryDto>;

  @observable schedules!: PagedResultDto<SchedulingListSummaryDto>;
  @observable defaultSchedulesList!: Array<SchedulingListSummaryDto>;
  @observable schedulesListCache!: Array<SchedulingListSummaryDto>;
  @observable schedulesListSummary!: PagedResultDto<SchedulingListSummaryDto>;

  @observable workOrders!: PagedResultDto<WorkOrderListSummaryDto>;
  @observable defaultWorkOrdersList!: Array<WorkOrderListSummaryDto>;
  @observable workOrdersListCache!: Array<WorkOrderListSummaryDto>;
  @observable workOrdersListSummary!: PagedResultDto<WorkOrderListSummaryDto>;

  @observable integrations!: PagedResultDto<IntegrationListSummaryDto>;
  @observable defaultIntegrationsList!: Array<IntegrationListSummaryDto>;
  @observable integrationsListCache!: Array<IntegrationListSummaryDto>;
  @observable integrationsListSummary!: PagedResultDto<IntegrationListSummaryDto>;

  @observable rolesSummary!: PagedResultDto<GetAllRoleOutput>;
  //--------------------------------------------------------------------------

  @observable entitySummaryList!: Array<any>;
  @observable templateSummaryList!: Array<any>;
  @observable typeSummaryList!: Array<any>;
  @observable fieldSummaryList!: Array<any>;
  @observable statesSummaryList!: Array<any>;
  @observable peopleSummaryList!: Array<any>;
  @observable teamSummaryList!: Array<any>;
  @observable communicationSummaryList!: Array<any>;

  //--------------------------------------------------------------------------

  @observable enable_AreaChart: boolean = false;
  @observable enable_BarChart: boolean = false;
  @observable enable_BoxPlot: boolean = false;
  @observable enable_BubbleGraph: boolean = false;
  @observable enable_Bullet: boolean = false;
  @observable enable_CirclePacking: boolean = false;
  @observable enable_ColumnGraph: boolean = false;
  @observable enable_DonutChart: boolean = false;
  @observable enable_FunnelChart: boolean = false;
  @observable enable_Gauge: boolean = false;
  @observable enable_HeatMap: boolean = false;
  @observable enable_Histogram: boolean = false;
  @observable enable_LineChart: boolean = false;
  @observable enable_Liquid: boolean = false;
  @observable enable_MultiLineChart: boolean = false;
  @observable enable_PieChart: boolean = false;
  @observable enable_Progress: boolean = false;
  @observable enable_RadarChart: boolean = false;
  @observable enable_RadialBarChart: boolean = false;
  @observable enable_Ring: boolean = false;
  @observable enable_RoseChart: boolean = false;
  @observable enable_SankeyPlot: boolean = false;
  @observable enable_ScatterPlot: boolean = false;
  @observable enable_StockPlot: boolean = false;
  @observable enable_SunburstPlot: boolean = false;
  @observable enable_VennPlot: boolean = false;
  @observable enable_ViolinPlot: boolean = false;
  @observable enable_WaterfallChart: boolean = false;

  @observable enable_TinyAreaPlot: boolean = false;
  @observable enable_TinyColumnPlot: boolean = false;
  @observable enable_TinyLinePlot: boolean = false;
  @observable enable_TinyLiquidPlot: boolean = false;
  @observable enable_TinyProgress: boolean = false;
  @observable enable_TinyRingProgress: boolean = false;
  @observable enable_TinyStatistic: boolean = false;

  @observable source_AreaChart: number = 0;
  @observable source_BarChart: number = 0;
  @observable source_BoxPlot: number = 0;
  @observable source_BubbleGraph: number = 0;
  @observable source_Bullet: number = 0;
  @observable source_CirclePacking: number = 0;
  @observable source_ColumnGraph: number = 0;
  @observable source_DonutChart: number = 0;
  @observable source_FunnelChart: number = 0;
  @observable source_Gauge: number = 0;
  @observable source_HeatMap: number = 0;
  @observable source_Histogram: number = 0;
  @observable source_LineChart: number = 0;
  @observable source_Liquid: number = 0;
  @observable source_MultiLineChart: number = 0;
  @observable source_PieChart: number = 0;
  @observable source_Progress: number = 0;
  @observable source_RadarChart: number = 0;
  @observable source_RadialBarChart: number = 0;
  @observable source_Ring: number = 0;
  @observable source_RoseChart: number = 0;
  @observable source_SankeyPlot: number = 0;
  @observable source_ScatterPlot: number = 0;
  @observable source_StockPlot: number = 0;
  @observable source_SunburstPlot: number = 0;
  @observable source_VennPlot: number = 0;
  @observable source_ViolinPlot: number = 0;
  @observable source_WaterfallChart: number = 0;

  @observable source_TinyAreaPlot: number = 0;
  @observable source_TinyColumnPlot: number = 0;
  @observable source_TinyLinePlot: number = 0;
  @observable source_TinyLiquidPlot: number = 0;
  @observable source_TinyProgress: number = 0;
  @observable source_TinyRingProgress: number = 0;
  @observable source_TinyStatistic: number = 0;

  //--------------------------------------------------------------------------

  //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
  @observable cards_CommentData!: Array<any>;
  @observable cards_BodyData!: Array<any>;
  @observable cards_HeaderData!: Array<any>;
  @observable cards_TagData!: Array<any>;

  @observable cards_BodyConfig!: Array<any>;
  @observable cards_HeaderConfig!: Array<any>;
  @observable cards_TagConfig!: Array<any>;

  @observable usersearch_Mentions: any[] = [];
  @observable usersearch_Hashtags: any[] = [];
  @observable personsearch_Mentions: any[] = [];
  @observable personrsearch_Hashtags: any[] = [];
  @observable docsearch_Mentions: any[] = [];
  @observable docsearch_Hashtags: any[] = [];
  @observable teamsearch_Mentions: any[] = [];
  @observable teamsearch_Hashtags: any[] = [];
  @observable communicationsearch_Mentions: any[] = [];
  @observable communicationsearch_Hashtags: any[] = [];
  @observable inventorysearch_Mentions: any[] = [];
  @observable inventorysearch_Hashtags: any[] = [];
  @observable menusearch_Mentions: any[] = [];
  @observable menusearch_Hashtags: any[] = [];
  @observable paymentsearch_Mentions: any[] = [];
  @observable paymentearch_Hashtags: any[] = [];
  @observable reportsearch_Mentions: any[] = [];
  @observable reportsearch_Hashtags: any[] = [];
  @observable schedulingsearch_Mentions: any[] = [];
  @observable schedulingsearch_Hashtags: any[] = [];
  @observable workordersearch_Mentions: any[] = [];
  @observable workordersearch_Hashtags: any[] = [];
  @observable integrationsearch_Mentions: any[] = [];
  @observable integrationsearch_Hashtags: any[] = [];
  @observable entitysearch_Mentions: any[] = [];
  @observable itemsearch_Mentions: any[] = [];
  @observable optionsearch_Mentions: any[] = [];
  @observable statesearch_Mentions: any[] = [];
  @observable templatesearch_Mentions: any[] = [];
  @observable typesearch_Mentions: any[] = [];
  @observable entitysearch_Hashtags: any[] = [];
  @observable itemsearch_Hashtags: any[] = [];
  @observable optionsearch_Hashtags: any[] = [];
  @observable statesearch_Hashtags: any[] = [];
  @observable templatesearch_Hashtags: any[] = [];
  @observable typesearch_Hashtags: any[] = [];

  //--------------------------------------------------------------------------
  // Set Three Unique Data Tables for Entity Data
  //--------------------------------------------------------------------------
 
  @observable entityCondensed_dataExportTable!: any;
  @observable entityCondensed_dataExportTable_Analytic!: any;
  @observable entityCondensed_dataExportTableData: Data[] = [];
  @observable entityCondensed_dataExportTableTotalData: Data[] = [];
  @observable entityCondensed_dataTableFields: Fields = {
    rows: [],
    columns: ['title', 'total', 'beginning', 'workflow', 'end', 'terminal'],
    values: ['ptitle', 'ptotal', 'pbeginning', 'pworkflow', 'pend', 'pterminal'],
  };
  @observable entityCondensed_dataTableFields_Analytic: Fields = {
    rows: ['title', 'total', 'beginning', 'workflow', 'end', 'terminal'],
    columns: [],
    values: ['ptitle', 'ptotal', 'pbeginning', 'pworkflow', 'pend', 'pterminal'],
  };
  @observable entityCondensed_dataTableMeta: Meta[] = [
    {
      "field": "type",
      "name": L('Type')
    },
    {
      "field": "title",
      "name": L('Title')
    },
    {
      "field": "total",
      "name": L('Total')
    },
    {
      "field": "beginning",
      "name": L('Beginning')
    },
    {
      "field": "workflow",
      "name": L('Workflow')
    },
    {
      "field": "end",
      "name": L('End')
    },
    {
      "field": "terminal",
      "name": L('Terminal')
    },

    {
      "field": "ptype",
      "name": L('Type')
    },
    {
      "field": "ptitle",
      "name": L('Title')
    },
    {
      "field": "ptotal",
      "name": L('Total')
    },
    {
      "field": "pbeginning",
      "name": L('Beginning')
    },
    {
      "field": "pworkflow",
      "name": L('Workflow')
    },
    {
      "field": "pend",
      "name": L('End')
    },
    {
      "field": "pterminal",
      "name": L('Terminal')
    },

  ];
  @observable entityCondensed_sortParams: SortParam[] = [
  ];

  @observable entity_dataExportTable!: any;
  @observable entity_dataExportTable_Analytic!: any;
  @observable entity_dataExportTableData: Data[] = [];
  @observable entity_dataExportTableTotalData: Data[] = [];
  @observable entity_dataTableFields: Fields = {
    rows: [],
    columns: ['id',
      'name',
      'title',
      'currentState',
      'creator',
      'ownerType',
      'owner',
      'template',
      'category',
      'lastModified',
      'created',
      'completed',
      'archived',
      'nextSLA',
      'slaOverdue',
      'slaChildrenOverdue',
      'timeToStart',
      'timeToComplete',
      'totalTime',
      'timeOverdue',
      'budgetHours',
      'budgetCost',
      'totalCost',
      'isRestricted',
      'currentlyApproved',],
    values: ['pid',
      'pname',
      'ptitle',
      'pcurrentState',
      'pcreator',
      'pownerType',
      'powner',
      'ptemplate',
      'pcategory',
      'plastModified',
      'pcreated',
      'pcompleted',
      'parchived',
      'pnextSLA',
      'pslaOverdue',
      'pslaChildrenOverdue',
      'ptimeToStart',
      'ptimeToComplete',
      'ptotalTime',
      'ptimeOverdue',
      'pbudgetHours',
      'pbudgetCost',
      'ptotalCost',
      'pisRestricted',
      'pcurrentlyApproved',],
  };
  @observable entity_dataTableFields_Analytic: Fields = {
    rows: ['id',
      'name',
      'title',
      'currentState',
      'creator',
      'ownerType',
      'owner',
      'template',
      'category',
      'lastModified',
      'created',
      'completed',
      'archived',
      'nextSLA',
      'slaOverdue',
      'slaChildrenOverdue',
      'timeToStart',
      'timeToComplete',
      'totalTime',
      'timeOverdue',
      'budgetHours',
      'budgetCost',
      'totalCost',
      'isRestricted',
      'currentlyApproved',],
    columns: [],
    values: ['pname', 'ptitle', 'ptemplate', 'pcurrentState', 'pcreator', 'pcreated', 'powner', 'plastModified'],
  };
  @observable entity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": L('ID')
    },
    {
      "field": "name",
      "name": L('Name')
    },
    {
      "field": "title",
      "name": L('Title')
    },
    {
      "field": "currentState",
      "name": L('Status')
    },
    {
      "field": "creator",
      "name": L('Creator')
    },
    {
      "field": "ownerType",
      "name": L('Owner Type')
    },
    {
      "field": "owner",
      "name": L('Owner')
    },
    {
      "field": "template",
      "name": L('Template')
    },
    {
      "field": "category",
      "name": L('Category')
    },
    {
      "field": "lastModified",
      "name": L('Last Modified')
    },
    {
      "field": "created",
      "name": L('Created')
    },
    {
      "field": "completed",
      "name": L('Completed')
    },
    {
      "field": "archived",
      "name": L('Archived')
    },
    {
      "field": "nextSLA",
      "name": L('Next SLA')
    },
    {
      "field": "slaOverdue",
      "name": L('SLA Overdue')
    },
    {
      "field": "slaChildrenOverdue",
      "name": L('Downstream SLA Overdue')
    },
    {
      "field": "timeToStart",
      "name": L('Time to Start')
    },
    {
      "field": "timeToComplete",
      "name": L('Time to Complete')
    },
    {
      "field": "totalTime",
      "name": L('Total Time')
    },
    {
      "field": "timeOverdue",
      "name": L('Time Overdue')
    },
    {
      "field": "budgetHours",
      "name": L('Budget Hours')
    },
    {
      "field": "budgetCost",
      "name": L('Budget Cost')
    },
    {
      "field": "totalCost",
      "name": L('Total Cost')
    },
    {
      "field": "isRestricted",
      "name": L('Restricted')
    },
    {
      "field": "currentlyApproved",
      "name": L('Awaiting Approval')
    },




    {
      "field": "pid",
      "name": L('ID')
    },
    {
      "field": "pname",
      "name": L('Name')
    },
    {
      "field": "ptitle",
      "name": L('Title')
    },
    {
      "field": "pcurrentState",
      "name": L('Status')
    },
    {
      "field": "pcreator",
      "name": L('Creator')
    },
    {
      "field": "pownerType",
      "name": L('Owner Type')
    },
    {
      "field": "powner",
      "name": L('Owner')
    },
    {
      "field": "ptemplate",
      "name": L('Template')
    },
    {
      "field": "pcategory",
      "name": L('Category')
    },
    {
      "field": "plastModified",
      "name": L('Last Modified')
    },
    {
      "field": "pcreated",
      "name": L('Created')
    },
    {
      "field": "pcompleted",
      "name": L('Completed')
    },
    {
      "field": "parchived",
      "name": L('Archived')
    },
    {
      "field": "pnextSLA",
      "name": L('Next SLA')
    },
    {
      "field": "pslaOverdue",
      "name": L('SLA Overdue')
    },
    {
      "field": "pslaChildrenOverdue",
      "name": L('Downstream SLA Overdue')
    },
    {
      "field": "ptimeToStart",
      "name": L('Time to Start')
    },
    {
      "field": "ptimeToComplete",
      "name": L('Time to Complete')
    },
    {
      "field": "ptotalTime",
      "name": L('Total Time')
    },
    {
      "field": "ptimeOverdue",
      "name": L('Time Overdue')
    },
    {
      "field": "pbudgetHours",
      "name": L('Budget Hours')
    },
    {
      "field": "pbudgetCost",
      "name": L('Budget Cost')
    },
    {
      "field": "ptotalCost",
      "name": L('Total Cost')
    },
    {
      "field": "pisRestricted",
      "name": L('Restricted')
    },
    {
      "field": "pcurrentlyApproved",
      "name": L('Awaiting Approval')
    },

  ];
  @observable entity_sortParams: SortParam[] = [
  ];

  @observable entityActivity_dataExportTable!: any;
  @observable entityActivity_dataExportTable_Analytic!: any;
  @observable entityActivity_dataExportTableData: Data[] = [];
  @observable entityActivity_dataExportTableTotalData: Data[] = [];
  @observable entityActivity_dataTableFields: Fields = {
    rows: [],
    columns: ['id', 'createdTime', 'entityName', 'notes', 'entityID', 'previousStateName', 'previousStateAction', 'newStateName', 'newStateAction', 'creatorID', 'ownerID'],
    values: ['pid', 'pcreatedTime', 'pentityName', 'pnotes', 'pentityID', 'ppreviousStateName', 'ppreviousStateAction', 'pnewStateName', 'pnewStateAction', 'pcreatorID', 'pownerID'],
  };
  @observable entityActivity_dataTableFields_Analytic: Fields = {
    rows: ['id', 'createdTime', 'entityName', 'notes', 'entityID', 'previousStateName', 'previousStateAction', 'newStateName', 'newStateAction', 'creatorID', 'ownerID'],
    columns: [],
    values: ['pid', 'pcreatedTime', 'pentityName', 'pnotes', 'pentityID', 'ppreviousStateName', 'ppreviousStateAction', 'pnewStateName', 'pnewStateAction', 'pcreatorID', 'pownerID'],
  };
  @observable entityActivity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "actionType",
      "name": "Type"
    },
    {
      "field": "actionID",
      "name": "Action ID"
    },
    {
      "field": "entityName",
      "name": "Entity"
    },
    {
      "field": "entityID",
      "name": "Entity ID"
    },
    {
      "field": "previousStateName",
      "name": "Previous State"
    },
    {
      "field": "previousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "previousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "newStateName",
      "name": "New State"
    },
    {
      "field": "newStateAction",
      "name": "New Action"
    },
    {
      "field": "newStateID",
      "name": "New State ID"
    },
    {
      "field": "itemName",
      "name": "Item"
    },
    {
      "field": "itemID",
      "name": "Item ID"
    },
    {
      "field": "itemValue",
      "name": "Item Value"
    },
    {
      "field": "notes",
      "name": "Notes"
    },
    {
      "field": "createdTime",
      "name": "Created"
    },
    {
      "field": "creatorID",
      "name": "Creator"
    },
    {
      "field": "ownerID",
      "name": "Owner"
    },
    {
      "field": "relatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "relatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "linkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "linkedEntityID",
      "name": "Linked Entity ID"
    },


    {
      "field": "pid",
      "name": "ID"
    },
    {
      "field": "pactionType",
      "name": "Type"
    },
    {
      "field": "pactionID",
      "name": "Action ID"
    },
    {
      "field": "pentityName",
      "name": "Entity"
    },
    {
      "field": "pentityID",
      "name": "Entity ID"
    },
    {
      "field": "ppreviousStateName",
      "name": "Previous State"
    },
    {
      "field": "ppreviousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "ppreviousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "pnewStateName",
      "name": "New State"
    },
    {
      "field": "pnewStateAction",
      "name": "New Action"
    },
    {
      "field": "pnewStateID",
      "name": "New State ID"
    },
    {
      "field": "pitemName",
      "name": "Item"
    },
    {
      "field": "pitemID",
      "name": "Item ID"
    },
    {
      "field": "pitemValue",
      "name": "Item Value"
    },
    {
      "field": "pnotes",
      "name": "Notes"
    },
    {
      "field": "pcreatedTime",
      "name": "Created"
    },
    {
      "field": "pcreatorID",
      "name": "Creator"
    },
    {
      "field": "pownerID",
      "name": "Owner"
    },
    {
      "field": "prelatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "prelatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "plinkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "plinkedEntityID",
      "name": "Linked Entity ID"
    },
  ];
  @observable entityActivity_sortParams: SortParam[] = [
  ];
  //--------------------------------------------------------------------------

  @observable template_dataExportTable!: any;
  @observable template_dataExportTableData: Data[] = [];
  @observable template_dataExportTableTotalData: Data[] = [];
  @observable template_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable template_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable type_dataExportTable!: any;
  @observable type_dataExportTableData: Data[] = [];
  @observable type_dataExportTableTotalData: Data[] = [];
  @observable type_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable type_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable state_dataExportTable!: any;
  @observable state_dataExportTableData: Data[] = [];
  @observable state_dataExportTableTotalData: Data[] = [];
  @observable state_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id',
      'name',
      'title',
      'forwardText',
      'revertText',
      'allowSkipTo',
      'allowRevert',
      'nextActionOnly',
      'lastActionOnly',
      'noActions'],
  };
  @observable state_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
    {
      "field": "displayType",
      "name": "Display Type"
    },
    {
      "field": "forwardText",
      "name": "Forward Text"
    },
    {
      "field": "revertText",
      "name": "Revert Text"
    },
    {
      "field": "restricted",
      "name": "Restricted"
    },
    {
      "field": "allowSkipTo",
      "name": "Allow Skip"
    },
    {
      "field": "allowRevert",
      "name": "Allow Revert"
    },
    {
      "field": "nextActionOnly",
      "name": "Next Only"
    },
    {
      "field": "lastActionOnly",
      "name": "Last Only"
    },
    {
      "field": "noActions",
      "name": "No Actions"
    },

  ];

  @observable item_dataExportTable!: any;
  @observable item_dataExportTableData: Data[] = [];
  @observable item_dataExportTableTotalData: Data[] = [];
  @observable item_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id', 'name', 'title'],
  };
  @observable item_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];

  @observable option_dataExportTable!: any;
  @observable option_dataExportTableData: Data[] = [];
  @observable option_dataExportTableTotalData: Data[] = [];
  @observable option_dataTableFields: Fields = {
    rows: ['id'],
    columns: [],
    values: ['id',
      'name',
      'title',
      'optionType',
      'boundState',
      'boundRole',
      'boundPerson',
      'boundTeam',
      'inputType'],
  };

  @observable option_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
  ];


  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await inventoryService.uploadImage(formData);
    result.uid = result.assetID.toString();
    this.fileCache.fileList.push(result.assetID);

    return result;
  }

  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await inventoryService.uploadImage(formData);

    this.profileID = result.assetID;
    return result;
  }

  @action
  async getProfileImage(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await inventoryService.getImage(recordID);
      return result.url;
    }
  }

  @action //gets files for initial list
  async getFiles() {

    try {

      let result = await inventoryService.getFiles(this.entityID, this.entityType);

      //Add to Default Loads
      result.map((x: FileUpload) => {

        if (x.type === 'image/png') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpeg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else if (x.type === 'image/jpg') {
          this.defaultEntityImages.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }
        else {
          this.defaultEntityFiles.push({
            uid: x.assetID.toString(),
            size: 0,
            name: x.name,
            url: x.url,
            thumbUrl: x.thumbUrl,
            type: x.type,
            status: 'done'

          })
        }

        return this.fileCache.fileList.push(x.assetID);
      })
    }
    catch { }
    return;
  }

  @action // 
  async removeFileFromList(file: UploadFile) {
    this.fileCache.fileList = this.fileCache.fileList.filter((x: number) => x.toString() !== file.uid);
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

    this.multiEntityList = {
      totalCount: 0,
      items: []
    };


    this.entityCreate = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityCreate.maxResultCount = maxResultCount;

    this.entityEdit = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityEdit.maxResultCount = maxResultCount;
    this.entityDelete = {
      relatedEntityType: entityType,
      itemType: itemType,
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    this.entityDelete.maxResultCount = maxResultCount;

    this.entityReturn = {
      name: '',
      value: '',
      description: '',
      id: 0,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    };

    this.entityStates = {
      totalCount: 0,
      items: []
    };

    this.entityItems = {
      totalCount: 0,
      items: []
    };

    this.entityOptions = {
      totalCount: 0,
      items: []
    };

    this.entityNotesCache = [];
    this.entityNoteRecordsCache = [];
    this.defaultEntityNotes = [];

    this.defaultEntityNoteRecords = [];
    this.entityNotesSummary = {
      totalCount: 0,
      items: []
    }


    this.currentNotifications = [{
      id: 0,
      message: 'Offices Closed Monday: 10/10/22',
      type: 'info'
    }];

  }

  @action
  async initFileStores() {

    this.defaultEntityImages = [];
    this.defaultEntityFiles = [];
    this.fileCache =
    {
      entityType: 0,
      entityID: 0,
      fileList: []
    };
  }

  @action
  async initCacheStores() {

    this.valuesCache = [];
    this.rolesSummary = {
      totalCount: 0,
      items: []
    };
    this.templateRecordCache = [];


    this.itemOptionsCache = {
      totalCount: 0,
      items: []
    };

    this.typeItemsCache = {
      totalCount: 0,
      items: []
    };

    this.typeStatesCache = {
      totalCount: 0,
      items: []
    };

    this.typeItemsEdit = {
      totalCount: 0,
      items: []
    };

    this.typeStatesEdit = {
      totalCount: 0,
      items: []
    };

    this.userListCache = [];
    this.defaultUserList = [];
    this.userListSummary = {
      totalCount: 0,
      items: []
    }
    this.userListSummary_Security = {
      totalCount: 0,
      items: []
    }

    this.personListSummary = {
      totalCount: 0,
      items: []
    }
    this.personListSummary_Security = {
      totalCount: 0,
      items: []
    }
    this.teamListSummary = {
      totalCount: 0,
      items: []
    }
    this.teamListSummary_Security = {
      totalCount: 0,
      items: []
    }




    this.communicationListCache = [];
    this.defaultCommunicationList = [];
    this.communicationListSummary = {
      totalCount: 0,
      items: []
    }

    this.inventoryListCache = [];
    this.defaultInventoryList = [];
    this.inventoryListSummary = {
      totalCount: 0,
      items: []
    }

    this.menuListCache = [];
    this.defaultMenuList = [];
    this.menuListSummary = {
      totalCount: 0,
      items: []
    }

    this.paymentsListCache = [];
    this.defaultPaymentsList = [];
    this.paymentsListSummary = {
      totalCount: 0,
      items: []
    }

    this.reportsListCache = [];
    this.defaultReportsList = [];
    this.reportsListSummary = {
      totalCount: 0,
      items: []
    }

    this.schedulesListCache = [];
    this.defaultSchedulesList = [];
    this.schedulesListSummary = {
      totalCount: 0,
      items: []
    }

    this.workOrdersListCache = [];
    this.defaultWorkOrdersList = [];
    this.workOrdersListSummary = {
      totalCount: 0,
      items: []
    }



    this.entitySummaryList = [];
    this.templateSummaryList = [];
    this.typeSummaryList = [];
    this.fieldSummaryList = [];
    this.peopleSummaryList = [];
    this.teamSummaryList = [];

  }

  //------------------------------------
  //NOTE: Filters will be handled in EntityTabView State ONLY
  //becuase there's no need to update/COM with server at any point

  @action
  async getCardData(filter: any) {




    this.cards_CommentData = [{
      email: 'Product was Sold',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Noah James',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample1.png',
        medium: 'string',
        thumbnail: 'string',
      },
    },
    {
      email: 'Product was Purchased',
      gender: 'string',
      name: {
        first: 'A few moments ago',
        last: 'Abigail Smith',
        title: 'string',
      },
      nat: 'string',
      picture: {
        large: 'https://swilab.com/images/profileExamples/profileExample3.png',
        medium: 'string',
        thumbnail: 'string',
      },

    },];

    this.cards_HeaderData = [{
      year: '',
      value: 30,
      type: 'Not for Sale',
    },
    {
      year: '',
      value: 50,
      type: 'Processing',
    },
    {
      year: '',
      value: 70,
      type: 'Available',
    },];

    this.cards_HeaderConfig = [{
      data: this.cards_HeaderData.reverse(),
      isStack: true,
      xField: 'value',
      yField: 'year',
      seriesField: 'type',
      label: {
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' },
        ],
      },
    }];

    this.cards_BodyData = [
      {
        type: 'Main Warehouse',
        value: 67,
      },
      {
        type: 'Location A',
        value: 45,
      },
      {
        type: 'Location B',
        value: 21,
      },
      {
        type: 'In Transit',
        value: 17,
      },];
    this.cards_BodyConfig = [{
      appendPadding: 0,
      data: this.cards_BodyData,
      innerRadius: 0.71,
      angleField: 'value',
      colorField: 'type',
      radius: 1,

      label: {
        type: 'inner',
        autoRotate: true,
        style: { fontSize: 14, textAlign: 'center' },

      },

    }];

    this.cards_TagData = [
      {
        title: 'AT&T',
        color: 'blue'
      },
      {
        title: 'Verizon',
        color: 'red'
      },
      {
        title: 'Boost',
        color: 'orange'
      },
    ];

    this.cards_TagConfig = [];



  }


  @action
  async clearCardData() {

    this.cards_CommentData = [];
    this.cards_BodyData = [];
    this.cards_HeaderData = [];
    this.cards_TagData = [];
    this.cards_BodyConfig = [];
    this.cards_HeaderConfig = [];
    this.cards_TagConfig = [];
  }

  //------------------------------------

  @action
  async updateMultiEntityList(updateMemberList: any) {


    this.multiEntityList.items.push(updateMemberList);

  }

  @action
  async deleteMultiEntityList(recordID: number) {
    this.multiEntityList.items = this.multiEntityList.items.filter((x: any) => x !== recordID);
  }


  @action
  async removeMultiEntityDuplicates() {

    let newList: any = [];

    this.multiEntityList.items.map((x: any) => {

      let isDuplicate = false;

      newList.map((y: any) => {
        if (x.value === y.value) {
          isDuplicate = true;
        }
      });

      if (isDuplicate === false) {
        newList.push({ id: 0, value: x.value });
      }

    });

    this.multiEntityList.items = newList;
  }


  @action
  async getEntity(askDto: EntityDto) {
    this.entityID = askDto.id;
    this.entityType = RelatedEntityTypes.Entity;
    ////this.getFiles();

    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.Entity,
      entityID: askDto.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });
 
    this.defaultUserList = [];
    this.userListCache = [];

    this.users.items.map((x: any) => {

      this.entityReturn.records.map((y: any) => {
        if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
          this.userListSummary.items.push(x);
          this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
          this.defaultUserList.push(x);
          this.userListCache.push(x);
        }

      });

    });
    
    
  }

  @action
  async createEntity() {
 

   
    let result = await inventoryService.create(this.entityCreate);
    this.entityReturn = result; 
 

    if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Entity) {
 
      let entitiesList = this.entityReturn.entities;
      let entityRecords = this.entityReturn.records;
      this.entityCreate.records = [];
      this.entityDelete.records = [];

      let tempNotes = this.entityNotesCache;
      let tempNoteRecords = this.entityNoteRecordsCache;

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.entities[0].id;

 
      await inventoryService.commitEntityFiles(this.fileCache);

      this.entityNotesCache = tempNotes;
      this.entityNoteRecordsCache = tempNoteRecords;


      
      await this.syncNotes(this.entityReturn.entities[0].id);
 
      entitiesList.map((x: any) => { 
        this.syncUsers(x.id, entityRecords); 
        this.syncCommunication(x.id, entityRecords); 
       // this.syncDocs(x.id, entityRecords); 
        this.syncInventory(x.id, entityRecords); 
        this.syncMenus(x.id, entityRecords); 
        this.syncPayments(x.id, entityRecords); 
        this.syncPeople(x.id, entityRecords); 
        this.syncReports(x.id, entityRecords); 
        this.syncSchedules(x.id, entityRecords); 
        this.syncTeams(x.id, entityRecords); 
        this.syncWorkOrders(x.id, entityRecords);
        this.syncMenus(x.id, entityRecords);

      })

       

    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Template) {

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.templates[0].id;

      this.initUpdateObject(RelatedEntityTypes.TemplateRecord, itemTypes.Inventory, 0, 100);

      //Create Value (Global) Template Records based on the Return as Value Records need to be linked
      result.values.map((valueItem: any) => {

        //valueItem.buttonRecords.map((valueRecord: any) => {
        //});

        valueItem.datePickerRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.datePicker,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        //valueItem.entityGridRecords.map((valueRecord: any) => {
        //});

        //valueItem.entityPickerRecords.map((valueRecord: any) => {
        //});

        //valueItem.fileUploadRecords.map((valueRecord: any) => {
        //});

        //valueItem.imageRecords.map((valueRecord: any) => {
        //});

        valueItem.multiSelectRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.multiSelect,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        valueItem.numberPickerRecords.map((valueRecord: any) => {

          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.numberPicker,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);

        });

        valueItem.paragraphRecords.map((valueRecord: any) => {

          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.paragraph,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);

        });

        //valueItem.profileImageRecords.map((valueRecord: any) => {
        //});

        //valueItem.searchSelectRecords.map((valueRecord: any) => {
        //});

        valueItem.singleSelectRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.singleSelect,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

        //valueItem.switchRecords.map((valueRecord: any) => {
        //});

        valueItem.textBoxRecords.map((valueRecord: any) => {
          let newTemplateRecord: TemplateRecordDto = {
            id: 0,
            formItemType: formItemTypes.textBox,
            templateID: this.fileCache.entityID,
            valueID: valueRecord.id,
            itemID: valueRecord.linkedEntityID,
            optionID: 0,
            optionType: 0,
            isActive: true,
            stateID: -1, // Implies that it's a value Item, which can't be set per stage
            approvalType: 0,
            approvalFormat: 0,
            visible: true,
            disabled: false,
            required: false
          }

          this.entityCreate.templateRecords.push(newTemplateRecord);
        });

      })

      // Create Static Field & Per-State (Switch) Template Records based 
      // on Cache as value records don't need to be linked,
      // just the Type Item with the state specified. 
      this.templateRecordCache.map((record: TemplateRecordDto) => {

        let recordUpdate: TemplateRecordDto = {
          id: 0,
          formItemType: record.formItemType,
          templateID: this.fileCache.entityID,
          valueID: record.valueID,
          itemID: record.itemID,
          optionID: record.optionID,
          optionType: record.optionType,
          isActive: record.isActive,
          stateID: record.stateID,
          approvalType: record.approvalType,
          approvalFormat: record.approvalFormat,
          visible: record.visible,
          disabled: record.disabled,
          required: record.required
        }

        this.entityCreate.templateRecords.push(recordUpdate);
      })

      // Create Template Records for Automations, Users, etc. via OptionType & ID


      await inventoryService.create(this.entityCreate);

    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Type) {
      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.types[0].id;
      await inventoryService.commitEntityFiles(this.fileCache);

      this.initUpdateObject(RelatedEntityTypes.Record, itemTypes.Inventory, 0, 100);

      //Sync Workflow Steps
      this.typeStatesEdit.items.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Type,
          relatedEntityID: result.types[0].id,
          linkedEntityType: RelatedEntityTypes.State,
          linkedEntityID: x.id
        });
      })

      //Sync Items
      this.typeItemsEdit.items.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Type,
          relatedEntityID: result.types[0].id,
          linkedEntityType: RelatedEntityTypes.Item,
          linkedEntityID: x.id
        });
      })


      await inventoryService.create(this.entityCreate);


    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Item) {

      let entityRecords = this.entityCreate.items[0].relatedOptions;
      this.initUpdateObject(RelatedEntityTypes.Record, itemTypes.Inventory, 0, 100);

      entityRecords.map((x: any) => {
        this.entityCreate.records.push({
          id: 0,
          relatedEntityType: RelatedEntityTypes.Item,
          relatedEntityID: result.items[0].id,
          linkedEntityType: RelatedEntityTypes.Option,
          linkedEntityID: x
        });

      });

      await inventoryService.create(this.entityCreate);

    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Option) {



    } else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.State) {



    }
  }

  @action
  async updateEntity() {
   
    let result = await inventoryService.update(this.entityCreate);
    this.entityReturn = result;

    let entityRecords = this.entityReturn.records;
    this.entityCreate.records = [];
    this.entityDelete.records = [];

    if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Entity) {
      let tempNotes = this.entityNotesCache;
      let tempNoteRecords = this.entityNoteRecordsCache;

      let entityRecords = this.entityReturn.records;
      this.entityCreate.records = [];
      this.entityDelete.records = [];

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.entities[0].id;
      await inventoryService.commitEntityFiles(this.fileCache);

      this.entityNotesCache = tempNotes;
      this.entityNoteRecordsCache = tempNoteRecords;
      await this.syncNotes(this.entityReturn.entities[0].id);

      this.syncUsers(this.entityReturn.entities[0].id, entityRecords);
      this.syncCommunication(this.entityReturn.entities[0].id, entityRecords);
     // this.syncDocs(this.entityReturn.entities[0].id, entityRecords);
      this.syncInventory(this.entityReturn.entities[0].id, entityRecords);
      this.syncMenus(this.entityReturn.entities[0].id, entityRecords);
      this.syncPayments(this.entityReturn.entities[0].id, entityRecords);
      this.syncPeople(this.entityReturn.entities[0].id, entityRecords);
      this.syncReports(this.entityReturn.entities[0].id, entityRecords);
      this.syncSchedules(this.entityReturn.entities[0].id, entityRecords);
      this.syncTeams(this.entityReturn.entities[0].id, entityRecords);
      this.syncWorkOrders(this.entityReturn.entities[0].id, entityRecords);

    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Template) {
      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.templates[0].id;
      await inventoryService.commitEntityFiles(this.fileCache);


    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Type) {

      this.fileCache.entityType = this.entityCreate.relatedEntityType;
      this.fileCache.entityID = this.entityReturn.types[0].id;
      await inventoryService.commitEntityFiles(this.fileCache);

      let entityID = this.entityReturn.types[0].id;
      this.entityCreate.relatedEntityType = RelatedEntityTypes.Record;
      this.entityCreate.types = [];
      this.entityCreate.records = [];

      //Determine if Cache Deosn't Contain Edit Item, Then Delete

      this.typeItemsEdit.items.map((x: any) => {

        var containsItem = false;
        this.typeItemsCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        //If Cache doesnt contain edit item
        if (containsItem === false) {

          //Delete Edit Item
          let deleteID = 0;
          entityRecords.map((z: any) => {
            if (z.LinkedEntityID === x.id) {
              deleteID = z.id;
            }
          })

          this.entityCreate.records.push({
            id: deleteID,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.Item,
            linkedEntityID: x.id
          });
        }
      });

      this.typeStatesCache.items.map((x: any) => {

        var containsItem = false;
        this.typeStatesEdit.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          let deleteID = 0;
          entityRecords.map((z: any) => {
            if (z.LinkedEntityID === x.id) {
              deleteID = z.id;
            }
          })

          this.entityCreate.records.push({
            id: deleteID,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.State,
            linkedEntityID: x.id
          });
        }
      });

      if (this.entityCreate.records.length > 0) {
        await inventoryService.delete(this.entityCreate);
      }

      this.entityCreate.records = [];

      this.typeItemsEdit.items.map((x: any) => {
        var containsItem = false;
        this.typeItemsCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.Item,
            linkedEntityID: x.id
          });

        }
      })

      this.typeStatesEdit.items.map((x: any) => {
        var containsItem = false;
        this.typeStatesCache.items.map((y: any) => { if (x.id === y.id) { containsItem = true; } })

        if (containsItem === false) {

          this.entityCreate.records.push({
            id: 0,
            relatedEntityType: RelatedEntityTypes.Type,
            relatedEntityID: entityID,
            linkedEntityType: RelatedEntityTypes.State,
            linkedEntityID: x.id
          });

        }
      })


      if (this.entityCreate.records.length > 0) {
        await inventoryService.create(this.entityCreate);
      }

    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.Option) {
 
    }
    else if (this.entityCreate.relatedEntityType === RelatedEntityTypes.State) {
       
    }

  }


  @action
  async deleteEntity(input: EntityDto) {

    this.entitySummary.items = this.entitySummary.items.filter((x: any) => x.id !== input.id);

    this.entityDelete.entities.push({
      id: input.id,
      name: '',
      title: '',
      templateID: 0,
      typeID: 0,
      currentState: 0,
      externalID: 0,
      isActive: true,
      creatorID: 0,
      profileImageID: 0,
      identifier: 0,
      relatedStatesVerbose: [],
      relatedItemsVerbose: [],
      values: [],

      owner: 0,
      ownerType: 0,
      dependant: 0,
      dependantType: 0,
      cost: 0,
      value: 0,
      sLAChildrenOverdue: false,
      sLAOverdue: false,
      nextSLA: '2001-03-14T05:00:00.000Z',
      TimeOverdue: 0,
      TimeToComplete: 0,
      TimeToStart: 0,
      TotalCost: 0,
      TotalTime: 0,
      BudgetCost: 0,
      BudgetHours: 0,
      isRestricted: false,
      currentlyApproved: true,
      docEditBody: '',
      pdfBody: ''
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Entity;
    this.entityDelete.itemType = itemTypes.Inventory;
 
    await inventoryService.delete(this.entityDelete);
  };

  @action
  async unDeleteEntity() {
    await this.initUpdateObject(RelatedEntityTypes.Entity, itemTypes.Inventory, 0, 100);
    this.entityCreate.entities.push(this.entityReturn.entities[0]);
    await this.createEntity();
  }

  //--------------------------------------------------------------------------

  @action //gets Users for initial list
  async getUsers(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    var userAsk = {
      entityType: RelatedEntityTypes.User,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(userAsk);

    this.users = {
      totalCount: 0,
      items: []
    }

    this.userListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultUserList = [];
    this.userListCache = [];
    this.usersearch_Mentions = [];

    result.users.map((x: any) => {
      this.users.items.push(x);
      this.users.totalCount = this.users.totalCount + 1;

      this.usersearch_Mentions.push({
        id: x.id,
        relatedEntity: x.userID,
        title: x.firstName + ' ' + x.lastName
      });

      this.entitysearch_Mentions.push({
        id: x.id,
        relatedEntity: x.userID,
        title: x.firstName + ' ' + x.lastName
      });


      if (mapRecords === true) {

        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.userListSummary.items.push(x);
            this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
            this.defaultUserList.push(x);
            this.userListCache.push(x);
          }

        });
      }
    });


    return;
  }

  @action //Add Notes to Temp List
  async addUser(user: UserListSummaryDto, isSecurity: boolean) {



    let userCache = user;

    if (isSecurity === true) {
      this.userListCache_Security.push(userCache);

      if (userCache.profileImageID === 0) {
        let url = await this.getProfileImage(userCache.profileImageID);
        if (url === '_') {
          userCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          userCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.userListSummary_Security.items.push(userCache);
      this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount + 1;
    }
    else {
      this.userListCache.push(userCache);

      if (userCache.profileImageID === 0) {
        let url = await this.getProfileImage(userCache.profileImageID);
        if (url === '_') {
          userCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          userCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.userListSummary.items.push(userCache);
      this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
    }

    return;
  }

  @action //Remove Notes from Temp List
  async removeUser(recordID: number, isSecurity: boolean) {



    if (isSecurity === true) {
      this.userListCache_Security = this.userListCache_Security.filter((x: UserListSummaryDto) => x.userID !== recordID);
      this.userListSummary_Security.items = this.userListSummary_Security.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
      this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount - 1;
    }
    else {
      this.userListCache = this.userListCache.filter((x: UserListSummaryDto) => x.userID !== recordID);
      this.userListSummary.items = this.userListSummary.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
      this.userListSummary.totalCount = this.userListSummary.totalCount - 1;
    }

    return;
  }

  @action //Sync Notes
  async syncUsers(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.userListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.User) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.User,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Users,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.User,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await userService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getDocs(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    var docAsk = {
      entityType: RelatedEntityTypes.InfoHub,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }


    let result = await inventoryService.getSummaryView(docAsk);


    this.docs = {
      totalCount: 0,
      items: []
    }

    this.docListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultDocsList = [];
    this.docListCache = [];
    this.docsearch_Mentions = [];

    result.docs.map((x: any) => {
      this.docs.items.push(x);
      this.docs.totalCount = this.docs.totalCount + 1;
      this.docsearch_Mentions.push(x.name);


      if (mapRecords === true) {

        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.InfoHub && y.linkedEntityID === x.docID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.docListSummary.items.push(x);
            this.docListSummary.totalCount = this.docListSummary.totalCount + 1;
            this.defaultDocsList.push(x);
            this.docListCache.push(x);
          }

        });
      }
    });




    return;
  }

  @action
  async addDoc(doc: any) {

    let docCache = doc;
    this.docListCache.push(docCache);

    if (docCache.profileImageID === 0) {
      let url = await this.getProfileImage(docCache.profileImageID);
      if (url === '_') {
        docCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        docCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.docListSummary.items.push(docCache);
    this.docListSummary.totalCount = this.docListSummary.totalCount + 1;


    return;
  }

  @action
  async removeDoc(recordID: number) {

    this.docListCache = this.docListCache.filter((x: any) => x.docID !== recordID);
    this.docListSummary.items = this.docListSummary.items.filter((x: any) => x.docID !== recordID);
    this.docListSummary.totalCount = this.docListSummary.totalCount - 1;

    return;
  }

  @action //Sync Notes
  async syncDocs(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.docListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.InfoHub) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.InfoHub,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.InfoHub,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.InfoHub,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      //await infoHubService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getPeople(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    var teamAsk = {
      entityType: RelatedEntityTypes.People,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(teamAsk);

    this.persons = {
      totalCount: 0,
      items: []
    }

    this.personListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultPersonList = [];
    this.personListCache = [];
    this.personsearch_Mentions = [];

    result.people.map((x: any) => {
      this.persons.items.push(x);
      this.persons.totalCount = this.persons.totalCount + 1;
      this.personsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.People && y.linkedEntityID === x.personID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.personListSummary.items.push(x);
            this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
            this.defaultPersonList.push(x);
            this.personListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addPerson(person: PersonListSummaryDto, isSecurity: boolean) {

    let personCache = person;

    if (isSecurity === true) {
      this.personListCache_Security.push(personCache);

      if (personCache.profileImageID === 0) {
        let url = await this.getProfileImage(personCache.profileImageID);
        if (url === '_') {
          personCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          personCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.personListSummary_Security.items.push(personCache);
      this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount + 1;
    }
    else {
      this.personListCache.push(personCache);

      if (personCache.profileImageID === 0) {
        let url = await this.getProfileImage(personCache.profileImageID);
        if (url === '_') {
          personCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          personCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.personListSummary.items.push(personCache);
      this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
    }




    return;
  }

  @action
  async removePerson(recordID: number, isSecurity: boolean) {


    if (isSecurity === true) {
      this.personListCache_Security = this.personListCache_Security.filter((x: PersonListSummaryDto) => x.personID !== recordID);
      this.personListSummary_Security.items = this.personListSummary_Security.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
      this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount - 1;
    }
    else {
      this.personListCache = this.personListCache.filter((x: PersonListSummaryDto) => x.personID !== recordID);
      this.personListSummary.items = this.personListSummary.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
      this.personListSummary.totalCount = this.personListSummary.totalCount - 1;
    }


    return;
  }

  @action
  async syncPeople(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.personListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.People) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.People,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Persons,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.People,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await peopleService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action //gets Teams for initial list
  async getTeams(entityID: number, relatedEntityType: number, mapRecords: boolean) {



    var teamAsk = {
      entityType: RelatedEntityTypes.Teams,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(teamAsk);

    this.teams = {
      totalCount: 0,
      items: []
    }

    this.teamListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultTeamList = [];
    this.teamListCache = [];
    this.teamsearch_Mentions = [];

    result.teams.map((x: any) => {
      this.teams.items.push(x);
      this.teams.totalCount = this.teams.totalCount + 1;
      this.teamsearch_Mentions.push(x.name);
      if (mapRecords === true) {

        this.entityReturn.records.map((y: any) => {

          if (y.linkedEntityType === RelatedEntityTypes.Teams && y.linkedEntityID === x.teamID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.teamListSummary.items.push(x);
            this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
            this.defaultTeamList.push(x);
            this.teamListCache.push(x);
          }

        });

      }

    });


    return;
  }

  @action //Add Notes to Temp List
  async addTeam(team: TeamListSummaryDto, isSecurity: boolean) {


    let teamCache = team;

    if (isSecurity === true) {
      this.teamListCache_Security.push(teamCache);

      if (teamCache.profileImageID === 0) {
        let url = await this.getProfileImage(teamCache.profileImageID);
        if (url === '_') {
          teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          teamCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.teamListSummary_Security.items.push(teamCache);
      this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount + 1;
    }
    else {
      this.teamListCache.push(teamCache);

      if (teamCache.profileImageID === 0) {
        let url = await this.getProfileImage(teamCache.profileImageID);
        if (url === '_') {
          teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
        }
        else {
          teamCache.profileImageURL = url;
        }
      }

      //Add Note to Summary View
      this.teamListSummary.items.push(teamCache);
      this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
    }


    return;
  }

  @action //Remove Notes from Temp List
  async removeTeam(recordID: number, isSecurity: boolean) {



    if (isSecurity === true) {
      this.teamListCache_Security = this.teamListCache_Security.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
      this.teamListSummary_Security.items = this.teamListSummary_Security.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
      this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount - 1;
    }
    else {
      this.teamListCache = this.teamListCache.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
      this.teamListSummary.items = this.teamListSummary.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
      this.teamListSummary.totalCount = this.teamListSummary.totalCount - 1;
    }


    return;
  }

  @action //Sync Notes
  async syncTeams(entityID: number, entityRecords: any[]) {

 
    var userAddList = this.teamListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Teams) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Teams,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Teams,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Teams,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await teamService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getCommunication(entityID: number, relatedEntityType: number, mapRecords: boolean) {



    var summaryAsk = {
      entityType: RelatedEntityTypes.Communication,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.communication = {
      totalCount: 0,
      items: []
    }

    this.communicationListSummary = {
      totalCount: 0,
      items: []
    }


    this.defaultCommunicationList = [];
    this.communicationListCache = [];
    this.communicationsearch_Mentions = [];
    result.communication.map((x: any) => {

      this.communication.items.push(x);
      this.communication.totalCount = this.communication.totalCount + 1;
      this.communicationsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Communication && y.linkedEntityID === x.communicationID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.communicationListSummary.items.push(x);
            this.communicationListSummary.totalCount = this.communicationListSummary.totalCount + 1;
            this.defaultCommunicationList.push(x);
            this.communicationListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addCommunication(communication: CommunicationListSummaryDto) {



    let communicationCache = communication;

    this.communicationListCache.push(communicationCache);

    if (communicationCache.profileImageID === 0) {
      let url = await this.getProfileImage(communicationCache.profileImageID);
      if (url === '_') {
        communicationCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        communicationCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.communicationListSummary.items.push(communicationCache);
    this.communicationListSummary.totalCount = this.communicationListSummary.totalCount + 1;

    return;
  }

  @action
  async removeCommunication(recordID: number) {



    this.communicationListCache = this.communicationListCache.filter((x: CommunicationListSummaryDto) => x.communicationID !== recordID);
    this.communicationListSummary.items = this.communicationListSummary.items.filter((x: CommunicationListSummaryDto) => x.communicationID !== recordID);
    this.communicationListSummary.totalCount = this.communicationListSummary.totalCount - 1;


    return;
  }

  @action
  async syncCommunication(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.communicationListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Communication) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Communication,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Communication,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Communication,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await communicationService.create(externalDto);
    }
 
    return;
  }


  //--------------------------------------------------------------------------

  @action
  async getInventory(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    var summaryAsk = {
      entityType: RelatedEntityTypes.Inventory,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.inventory = {
      totalCount: 0,
      items: []
    }

    this.inventoryListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultInventoryList = [];
    this.inventoryListCache = [];
    this.inventorysearch_Mentions = [];

    result.inventory.map((x: any) => {
      this.inventory.items.push(x);
      this.inventory.totalCount = this.inventory.totalCount + 1;
      this.inventorysearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Inventory && y.linkedEntityID === x.inventoryID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.inventoryListSummary.items.push(x);
            this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount + 1;
            this.defaultInventoryList.push(x);
            this.inventoryListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addInventory(inventory: InventoryListSummaryDto) {



    let inventoryCache = inventory;
    this.inventoryListCache.push(inventoryCache);

    if (inventoryCache.profileImageID === 0) {
      let url = await this.getProfileImage(inventoryCache.profileImageID);
      if (url === '_') {
        inventoryCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        inventoryCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.inventoryListSummary.items.push(inventoryCache);
    this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount + 1;

    return;
  }

  @action
  async removeInventory(recordID: number) {



    this.inventoryListCache = this.inventoryListCache.filter((x: InventoryListSummaryDto) => x.inventoryID !== recordID);
    this.inventoryListSummary.items = this.inventoryListSummary.items.filter((x: InventoryListSummaryDto) => x.inventoryID !== recordID);
    this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount - 1;


    return;
  }

  @action
  async syncInventory(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.inventoryListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Inventory) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Inventory,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await inventoryService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getMenus(entityID: number, relatedEntityType: number, mapRecords: boolean) {


    var summaryAsk = {
      entityType: RelatedEntityTypes.Menu,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);
 
    this.menus = {
      totalCount: 0,
      items: []
    }

    this.menuListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultMenuList = [];
    this.menuListCache = [];
    this.menusearch_Mentions = [];

    result.menus.map((x: any) => {
      this.menus.items.push(x);
      this.menus.totalCount = this.menus.totalCount + 1;
      this.menusearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Menu && y.linkedEntityID === x.menuID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.menuListSummary.items.push(x);
            this.menuListSummary.totalCount = this.menuListSummary.totalCount + 1;
            this.defaultMenuList.push(x);
            this.menuListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addMenu(menu: MenuListSummaryDto) {



    let menuCache = menu;
    this.menuListCache.push(menuCache);

    if (menuCache.profileImageID === 0) {
      let url = await this.getProfileImage(menuCache.profileImageID);
      if (url === '_') {
        menuCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        menuCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.menuListSummary.items.push(menuCache);
    this.menuListSummary.totalCount = this.menuListSummary.totalCount + 1;

    return;
  }

  @action
  async removeMenu(recordID: number) {



    this.menuListCache = this.menuListCache.filter((x: MenuListSummaryDto) => x.menuID !== recordID);
    this.menuListSummary.items = this.menuListSummary.items.filter((x: MenuListSummaryDto) => x.menuID !== recordID);
    this.menuListSummary.totalCount = this.menuListSummary.totalCount - 1;

    return;
  }

  @action
  async syncMenus(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.menuListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Menu) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Menu,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Menu,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Menu,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await menuService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getPayments(entityID: number, relatedEntityType: number, mapRecords: boolean) {


    var summaryAsk = {
      entityType: RelatedEntityTypes.Payments,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.payments = {
      totalCount: 0,
      items: []
    }

    this.paymentsListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultPaymentsList = [];
    this.paymentsListCache = [];
    this.paymentsearch_Mentions = [];

    result.payments.map((x: any) => {
      this.payments.items.push(x);
      this.payments.totalCount = this.payments.totalCount + 1;
      this.paymentsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Payments && y.linkedEntityID === x.paymentID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.paymentsListSummary.items.push(x);
            this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount + 1;
            this.defaultPaymentsList.push(x);
            this.paymentsListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addPayment(payment: PaymentsListSummaryDto) {



    let paymentCache = payment;
    this.paymentsListCache.push(paymentCache);

    if (paymentCache.profileImageID === 0) {
      let url = await this.getProfileImage(paymentCache.profileImageID);
      if (url === '_') {
        paymentCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        paymentCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.paymentsListSummary.items.push(paymentCache);
    this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount + 1;

    return;
  }

  @action
  async removePayment(recordID: number) {



    this.paymentsListCache = this.paymentsListCache.filter((x: PaymentsListSummaryDto) => x.paymentID !== recordID);
    this.paymentsListSummary.items = this.paymentsListSummary.items.filter((x: PaymentsListSummaryDto) => x.paymentID !== recordID);
    this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount - 1;


    return;
  }

  @action
  async syncPayments(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.paymentsListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Payments) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Payments,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Payments,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Payments,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await paymentService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getReports(entityID: number, relatedEntityType: number, mapRecords: boolean) {

    var summaryAsk = {
      entityType: RelatedEntityTypes.Reports,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.reports = {
      totalCount: 0,
      items: []
    }

    this.reportsListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultReportsList = [];
    this.reportsListCache = [];
    this.reportsearch_Mentions = [];

    result.reports.map((x: any) => {
      this.reports.items.push(x);
      this.reports.totalCount = this.reports.totalCount + 1;
      this.reportsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Reports && y.linkedEntityID === x.reportID && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.reportsListSummary.items.push(x);
            this.reportsListSummary.totalCount = this.reportsListSummary.totalCount + 1;
            this.defaultReportsList.push(x);
            this.reportsListCache.push(x);
          }

        });

      }
    });

    return;
  }

  @action
  async addReport(report: ReportsListSummaryDto) {



    let reportCache = report;
    this.reportsListCache.push(reportCache);

    if (reportCache.profileImageID === 0) {
      let url = await this.getProfileImage(reportCache.profileImageID);
      if (url === '_') {
        reportCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        reportCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.reportsListSummary.items.push(reportCache);
    this.reportsListSummary.totalCount = this.reportsListSummary.totalCount + 1;

    return;
  }

  @action
  async removeReport(recordID: number) {



    this.reportsListCache = this.reportsListCache.filter((x: ReportsListSummaryDto) => x.reportID !== recordID);
    this.reportsListSummary.items = this.reportsListSummary.items.filter((x: ReportsListSummaryDto) => x.reportID !== recordID);
    this.reportsListSummary.totalCount = this.reportsListSummary.totalCount - 1;


    return;
  }

  @action
  async syncReports(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.reportsListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Reports) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Reports,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Reports,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Reports,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await reportService.create(externalDto);
    }
 
    return;
  }


  //--------------------------------------------------------------------------

  @action
  async getSchedules(entityID: number, relatedEntityType: number, mapRecords: boolean) {



    var summaryAsk = {
      entityType: RelatedEntityTypes.Scheduling,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.schedules = {
      totalCount: 0,
      items: []
    }

    this.schedulesListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultSchedulesList = [];
    this.schedulesListCache = [];
    this.schedulingsearch_Mentions = [];

    result.schedules.map((x: any) => {
      this.schedules.items.push(x);
      this.schedules.totalCount = this.schedules.totalCount + 1;
      this.schedulingsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Scheduling && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.schedulesListSummary.items.push(x);
            this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount + 1;
            this.defaultSchedulesList.push(x);
            this.schedulesListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addSchedule(schedule: SchedulingListSummaryDto) {



    let scheduleCache = schedule;
    this.schedulesListCache.push(scheduleCache);

    if (scheduleCache.profileImageID === 0) {
      let url = await this.getProfileImage(scheduleCache.profileImageID);
      if (url === '_') {
        scheduleCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        scheduleCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.schedulesListSummary.items.push(scheduleCache);
    this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount + 1;

    return;
  }

  @action
  async removeSchedule(recordID: number) {



    this.schedulesListCache = this.schedulesListCache.filter((x: SchedulingListSummaryDto) => x.scheduleID !== recordID);
    this.schedulesListSummary.items = this.schedulesListSummary.items.filter((x: SchedulingListSummaryDto) => x.scheduleID !== recordID);
    this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount - 1;

    return;
  }

  @action
  async syncSchedules(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.schedulesListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Scheduling) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Scheduling,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Schedules,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Scheduling,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await schedulingService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------

  @action
  async getWorkOrders(entityID: number, relatedEntityType: number, mapRecords: boolean) {


    var summaryAsk = {
      entityType: RelatedEntityTypes.WorkOrder,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getSummaryView(summaryAsk);

    this.workOrders = {
      totalCount: 0,
      items: []
    }

    this.workOrdersListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultWorkOrdersList = [];
    this.workOrdersListCache = [];
    this.workordersearch_Mentions = [];

    result.workOrders.map((x: any) => {
      this.workOrders.items.push(x);
      this.workOrders.totalCount = this.workOrders.totalCount + 1;
      this.workordersearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.WorkOrder && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.workOrdersListSummary.items.push(x);
            this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount + 1;
            this.defaultWorkOrdersList.push(x);
            this.workOrdersListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addWorkOrder(workOrder: WorkOrderListSummaryDto) {



    let workOrderCache = workOrder;
    this.workOrdersListCache.push(workOrderCache);

    if (workOrderCache.profileImageID === 0) {
      let url = await this.getProfileImage(workOrderCache.profileImageID);
      if (url === '_') {
        workOrderCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        workOrderCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.workOrdersListSummary.items.push(workOrderCache);
    this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount + 1;

    return;
  }

  @action
  async removeWorkOrder(recordID: number) {



    this.workOrdersListCache = this.workOrdersListCache.filter((x: WorkOrderListSummaryDto) => x.workOrderID !== recordID);
    this.workOrdersListSummary.items = this.workOrdersListSummary.items.filter((x: WorkOrderListSummaryDto) => x.workOrderID !== recordID);
    this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount - 1;

    return;
  }

  @action
  async syncWorkOrders(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.workOrdersListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.WorkOrder) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.WorkOrder,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.WorkOrders,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.WorkOrder,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await workOrderService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------
  
  @action
  async getIntegrations(entityID: number, relatedEntityType: number, mapRecords: boolean) {


    var summaryAsk = {
      entityType: RelatedEntityTypes.Integrations,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      relatedEntityType: relatedEntityType,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await integrationService.getSummaryView(summaryAsk);

    this.integrations = {
      totalCount: 0,
      items: []
    }

    this.integrationsListSummary = {
      totalCount: 0,
      items: []
    }
 
    this.defaultIntegrationsList = [];
    this.integrationsListCache = [];
    this.integrationsearch_Mentions = [];

    result.integrations.map((x: any) => {
      this.integrations.items.push(x);
      this.integrations.totalCount = this.integrations.totalCount + 1;
      this.integrationsearch_Mentions.push(x.name);

      if (mapRecords === true) {


        this.entityReturn.records.map((y: any) => {
          if (y.linkedEntityType === RelatedEntityTypes.Integrations && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
            this.integrationsListSummary.items.push(x);
            this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount + 1;
            this.defaultIntegrationsList.push(x);
            this.integrationsListCache.push(x);
          }

        });

      }
    });


    return;
  }

  @action
  async addIntegration(integration: IntegrationListSummaryDto) {



    let integrationCache = integration;
    this.integrationsListCache.push(integrationCache);

    if (integrationCache.profileImageID === 0) {
      let url = await this.getProfileImage(integrationCache.profileImageID);
      if (url === '_') {
        integrationCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        integrationCache.profileImageURL = url;
      }
    }

    //Add Note to Summary View
    this.integrationsListSummary.items.push(integrationCache);
    this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount + 1;

    return;
  }

  @action
  async removeIntegration(recordID: number) {


    this.integrationsListCache = this.integrationsListCache.filter((x: IntegrationListSummaryDto) => x.integrationID !== recordID);
    this.integrationsListSummary.items = this.integrationsListSummary.items.filter((x: IntegrationListSummaryDto) => x.integrationID !== recordID);
    this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount - 1;

    return;
  }

  @action
  async syncIntegrations(entityID: number, entityRecords: any[]) {
 
    var userAddList = this.integrationsListCache;

    var deletDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    entityRecords.map((z: any) => {

      if (z.linkedEntityType === RelatedEntityTypes.Integrations) {

        deletDto.records.push({
          id: z.id,
          relatedEntityType: RelatedEntityTypes.Record,
          relatedEntityID: z.relatedEntityID,
          linkedEntityType: RelatedEntityTypes.Integrations,
          linkedEntityID: z.linkedEntityID
        })
      }

    })

    var externalDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Integrations,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    var entityDto: any = {
      relatedEntityType: RelatedEntityTypes.Record,
      itemType: itemTypes.Inventory,
      skipCount: 0,
      maxResultCount: 100,
      entities: [],
      items: [],
      options: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: []
    }

    userAddList.map((x: any) => {

      entityDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: entityID,
        linkedEntityType: RelatedEntityTypes.Integrations,
        linkedEntityID: x.id
      })

      externalDto.records.push({
        id: 0,
        relatedEntityType: RelatedEntityTypes.Record,
        relatedEntityID: x.id,
        linkedEntityType: RelatedEntityTypes.Inventory,
        linkedEntityID: entityID
      })

    });

    await inventoryService.delete(deletDto);
    if (userAddList.length > 0) {
      await inventoryService.create(entityDto);
      await integrationService.create(externalDto);
    }
 
    return;
  }

  //--------------------------------------------------------------------------
 
  @action //gets Notes for initial list
  async getNotes(entityID: number) {



    var noteAsk = {
      entityType: RelatedEntityTypes.Note,
      entityID: entityID,
      filter: '',
      moduleType: itemTypes.Inventory,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    }

    let result = await inventoryService.getNotes(noteAsk);

    result.notes.map((x: any) => {

      this.entityReturn.notes.push(x);

    })


    return;
  }

  @action //Add Notes to Temp List
  async addNote(note: NoteSummaryDto) {



    let noteCache = note;

    var noteItem: NoteDto = {
      id: noteCache.id,
      title: noteCache.title,
      type: noteCache.type,
      relatedEntity: noteCache.relatedEntity,
      replyID: noteCache.replyID,
      customColor: noteCache.customColor,
      created: noteCache.created,
      body: noteCache.body,
      isPrivate: noteCache.isPrivate,
      notifyTagged: noteCache.notifyTagged,
      notifyAll: noteCache.notifyAll
    }

    this.entityNotesCache.push(noteItem);

    noteCache.noteRecords.map((y: NoteRecordDto) => {
      var recordItem: NoteRecordDto = {
        id: y.id,
        noteID: y.noteID,
        relatedUser: y.relatedUser,
        relatedEntity: y.relatedEntity,
        title: ''
      }

      this.entityNoteRecordsCache.push(recordItem);

    })
    if (note.id < 0) {
      let url = await this.getProfileImage(note.currentUserImageID);
      if (url === '_') {
        noteCache.profileImageURL = DataConsts.defaultProfileImageURL;
      }
      else {
        noteCache.profileImageURL = url;
      }
    }
    //Add Note to Summary View
    this.entityNotesSummary.items.push(noteCache);
    this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount + 1;

    return;
  }

  @action //Remove Notes from Temp List
  async removeNote(recordID: number) {


    this.entityNotesCache = this.entityNotesCache.filter((x: NoteDto) => x.id !== recordID);
    this.entityNoteRecordsCache = this.entityNoteRecordsCache.filter((x: NoteRecordDto) => x.noteID !== recordID);
    this.entityNotesSummary.items = this.entityNotesSummary.items.filter((x: NoteSummaryDto) => x.id !== recordID);
    this.entityNotesSummary.totalCount = this.entityNotesSummary.totalCount - 1;

    return;
  }

  @action //Sync Notes
  async syncNotes(entityID: number) {


    this.entityDelete = {
      relatedEntityType: RelatedEntityTypes.Note,
      itemType: itemTypes.Inventory,
      entities: [],
      options: [],
      items: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: [],
      skipCount: 0,
      maxResultCount: 100
    }

    this.entityEdit = {
      relatedEntityType: RelatedEntityTypes.Note,
      itemType: itemTypes.Inventory,
      entities: [],
      options: [],
      items: [],
      states: [],
      types: [],
      records: [],
      templates: [],
      values: [],
      notes: [],
      noteRecords: [],
      templateRecords: [],
      skipCount: 0,
      maxResultCount: 100
    }

    //Parse default against temp for Deletes 
    this.defaultEntityNotes.map((x: any) => {

      var containsNote = false;
      this.entityNotesCache.map((y: any) => {
        if (x.id === y.id) { containsNote = true; }
      })

      if (containsNote === false) {
        var updateItem = x;
        updateItem.relatedEntity = entityID;
        this.entityDelete.notes.push(updateItem);
      }

    })

    this.defaultEntityNoteRecords.map((x: any) => {

      var containsNote = false;
      this.entityNoteRecordsCache.map((y: any) => {
        if (x.id === y.id) { containsNote = true; }
      })

      if (containsNote === false) {
        var updateItem = x;
        updateItem.relatedEntity = entityID;
        this.entityDelete.noteRecords.push(updateItem);
      }

    })

    await inventoryService.deleteNotes(this.entityDelete);

    this.entityNotesCache.map((x: any) => {
      if (x.id < 0) {
        var updateItem = x;
        updateItem.id = 0;
        updateItem.relatedEntity = entityID;
        this.entityEdit.notes.push(updateItem);

      }
    });

    this.entityNoteRecordsCache.map((x: any) => {
      if (x.id < 0) {
        var updateItem = x;
        updateItem.relatedEntity = entityID;
        this.entityEdit.noteRecords.push(updateItem);
      }
    });


    await inventoryService.addNotes(this.entityEdit);



    return;
  }

  @action
  async getFileDownload(recordID: number) {

    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await inventoryService.getImage(recordID);

      return result;
    }
  }

  //--------------------------------------------------------------------------
  @action
  async getMatchingUserImage(entityID: number) {

    let userImage = '_';

    if (entityID !== undefined) {
      let result = await userService.getImage(entityID);
      userImage = result.url;

    }
    return userImage;
  }


  @action
  async getMatchingCommunicationImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await communicationService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingInventoryImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await inventoryService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }


  @action
  async getMatchinMenuImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await menuService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }


  @action
  async getMatchingPaymentImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await paymentService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }


  @action
  async getMatchingPeopleImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await peopleService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingTeamImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await teamService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }


  @action
  async getMatchingReportImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await reportService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingWScheduleImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await schedulingService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingWorkOrderImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await workOrderService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingDocImage(entityID: number) {

    let docImage = '_';

    if (entityID !== undefined) {
      let result = await infoHubService.getImage(entityID);
      docImage = result.url;

    }
    return docImage;
  }

  @action
  async getMatchingUserName(entityID: number) {

    let userName = L('Unknown');

    this.users.items.map((x: any) => {

      if (x.userID === entityID) {
        userName = x.firstName + ' ' + x.lastName;
      }

    })
    return userName;

  }


  @action
  async getMatchingPeopleName(entityID: number) {

    let userName = L('Unknown');
    this.persons.items.map((x: any) => {

      if (x.personID === entityID) {
        userName = x.name;
      }

    })
    return userName;

  }

  @action
  async getMatchingTeamName(entityID: number) {


    let userName = L('Unknown');
    this.teams.items.map((x: any) => {

      if (x.teamID === entityID) {
        userName = x.name;
      }

    })


    return userName;

  }

  @action
  async getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

     

    let userRoles = await userService.getRoles();
    let canViewOwn = false;
    let canviewOthers = false;
    let canViewRestricted = false;
 
    if (isGranted(PermissionNames.Inventory) === true) {
      if (isGranted(PermissionNames.Inventory_ViewOwn) === true) {
        canViewOwn = true;
      }
      if (isGranted(PermissionNames.Inventory_ViewOthers) === true) {
        canviewOthers = true;
      }
      if (isGranted(PermissionNames.Inventory_ViewRestricted) === true) {
        canViewRestricted = true;
      }
    }


    this.entitySummary =
    {
      totalCount: 0,
      items: []
    }

    this.entitySummaryCondensed =
    {
      totalCount: 0,
      items: []
    }

    //-----------------------------------------------------
    // Get Summary
    //-----------------------------------------------------

    let entitySummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.Entity,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

      
    //-----------------------------------------------------
    // User Summary
    //-----------------------------------------------------


    this.users = {
      totalCount: 0,
      items: []
    }

    this.userListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultUserList = [];
    this.userListCache = [];
    this.usersearch_Mentions = [];

    entitySummary.users.map((x: any) => {
      this.users.items.push(x);
      this.users.totalCount = this.users.totalCount + 1;

      this.usersearch_Mentions.push({
        id: x.id,
        relatedEntity: x.userID,
        title: x.firstName + ' ' + x.lastName
      });

      this.entitysearch_Mentions.push({
        id: x.id,
        relatedEntity: x.userID,
        title: x.firstName + ' ' + x.lastName
      });

    });


    //-----------------------------------------------------
    // People Summary
    //-----------------------------------------------------

    this.persons = {
      totalCount: 0,
      items: []
    }

    this.personListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultPersonList = [];
    this.personListCache = [];
    this.personsearch_Mentions = [];

    entitySummary.people.map((x: any) => {
      this.persons.items.push(x);
      this.persons.totalCount = this.persons.totalCount + 1;
      this.personsearch_Mentions.push(x.name);
    });


    //-----------------------------------------------------
    // Teams Summary
    //-----------------------------------------------------

    this.teams = {
      totalCount: 0,
      items: []
    }

    this.teamListSummary = {
      totalCount: 0,
      items: []
    }

    this.defaultTeamList = [];
    this.teamListCache = [];
    this.teamsearch_Mentions = [];

    entitySummary.teams.map((x: any) => {
      this.teams.items.push(x);
      this.teams.totalCount = this.teams.totalCount + 1;
      this.teamsearch_Mentions.push(x.name);
    });

    

    //-----------------------------------------------------
    // Action Summary
    //-----------------------------------------------------

    this.entityActivity_dataExportTableData = [];
    this.actionSummaryReturn = {
      totalCount: 0,
      items: [],
    }

    entitySummary.userActionRecords.map((actionRecord: UserActionRecordDto) => {
      if (actionRecord !== undefined) {

        //Check if can view Restricted
        if ((actionRecord.isRestricted === true && canViewRestricted === true)
          || actionRecord.isRestricted === false) {

          //Check if other to view
          if ((actionRecord.creatorID === currentLoginInfo.user.id && canViewOwn === true)
            || (actionRecord.creatorID !== currentLoginInfo.user.id && canviewOthers === true)) {


            //Add to Summary Views
            this.actionSummaryReturn.items.push(actionRecord);
            this.actionSummaryReturn.totalCount = this.actionSummaryReturn.totalCount + 1;

            var entity_dataItem: Data =
            {


              "id": actionRecord.id === undefined ? 0 : actionRecord.id,
              "actionType": actionRecord.actionType === undefined ? 'None' : actionRecord.actionType,
              "actionID": actionRecord.actionID === undefined ? 'None' : actionRecord.actionID,
              "entityName": actionRecord.entityName === undefined ? 'None' : actionRecord.entityName,
              "entityID": actionRecord.entityID === undefined ? 'None' : actionRecord.entityID,
              "previousStateName": actionRecord.previousStateName === undefined ? 'None' : actionRecord.previousStateName,
              "previousStateAction": actionRecord.previousStateAction === undefined ? 'None' : actionRecord.previousStateAction,
              "previousStateID": actionRecord.previousStateID === undefined ? 'None' : actionRecord.previousStateID,
              "newStateName": actionRecord.newStateName === undefined ? 'None' : actionRecord.newStateName,
              "newStateAction": actionRecord.newStateAction === undefined ? 'None' : actionRecord.newStateAction,
              "newStateID": actionRecord.newStateID === undefined ? 'None' : actionRecord.newStateID,
              "itemName": actionRecord.itemName === undefined ? 'None' : actionRecord.itemName,
              "itemID": actionRecord.itemID === undefined ? 'None' : actionRecord.itemID,
              "itemValue": actionRecord.itemValue === undefined ? 'None' : actionRecord.itemValue,
              "notes": actionRecord.notes === undefined ? 'None' : actionRecord.notes,
              "externalID": actionRecord.externalID === undefined ? 'None' : actionRecord.externalID,
              "isRestricted": actionRecord.isRestricted === undefined ? 'None' : actionRecord.isRestricted,
              "createdTime": actionRecord.createdTime === undefined ? 'None' : dayjs(actionRecord.createdTime).format('DD/MM/YYYY h:mm A'),
              "creatorID": actionRecord.creatorID === undefined ? 'None' : actionRecord.creatorID,
              "owner": actionRecord.ownerID === undefined ? 'None' : actionRecord.ownerID,
              "relatedEntityType": actionRecord.relatedEntityType === undefined ? 'None' : actionRecord.relatedEntityType,
              "relatedEntityID": actionRecord.relatedEntityID === undefined ? 'None' : actionRecord.relatedEntityID,
              "linkedEntityType": actionRecord.linkedEntityType === undefined ? 'None' : actionRecord.linkedEntityType,
              "linkedEntityID": actionRecord.linkedEntityID === undefined ? 'None' : actionRecord.linkedEntityID,



              "pid": actionRecord.id === undefined ? 0 : actionRecord.id,
              "pactionType": actionRecord.actionType === undefined ? 'None' : actionRecord.actionType,
              "pactionID": actionRecord.actionID === undefined ? 'None' : actionRecord.actionID,
              "pentityName": actionRecord.entityName === undefined ? 'None' : actionRecord.entityName,
              "pentityID": actionRecord.entityID === undefined ? 'None' : actionRecord.entityID,
              "ppreviousStateName": actionRecord.previousStateName === undefined ? 'None' : actionRecord.previousStateName,
              "ppreviousStateAction": actionRecord.previousStateAction === undefined ? 'None' : actionRecord.previousStateAction,
              "ppreviousStateID": actionRecord.previousStateID === undefined ? 'None' : actionRecord.previousStateID,
              "pnewStateName": actionRecord.newStateName === undefined ? 'None' : actionRecord.newStateName,
              "pnewStateAction": actionRecord.newStateAction === undefined ? 'None' : actionRecord.newStateAction,
              "pnewStateID": actionRecord.newStateID === undefined ? 'None' : actionRecord.newStateID,
              "pitemName": actionRecord.itemName === undefined ? 'None' : actionRecord.itemName,
              "pitemID": actionRecord.itemID === undefined ? 'None' : actionRecord.itemID,
              "pitemValue": actionRecord.itemValue === undefined ? 'None' : actionRecord.itemValue,
              "pnotes": actionRecord.notes === undefined ? 'None' : actionRecord.notes,
              "pexternalID": actionRecord.externalID === undefined ? 'None' : actionRecord.externalID,
              "ppisRestricted": actionRecord.isRestricted === undefined ? 'None' : actionRecord.isRestricted,
              "pcreatedTime": actionRecord.createdTime === undefined ? 'None' : dayjs(actionRecord.createdTime).format('DD/MM/YYYY h:mm A'),
              "pcreatorID": actionRecord.creatorID === undefined ? 'None' : actionRecord.creatorID,
              "pownerID": actionRecord.ownerID === undefined ? 'None' : actionRecord.ownerID,
              "prelatedEntityType": actionRecord.relatedEntityType === undefined ? 'None' : actionRecord.relatedEntityType,
              "prelatedEntityID": actionRecord.relatedEntityID === undefined ? 'None' : actionRecord.relatedEntityID,
              "plinkedEntityType": actionRecord.linkedEntityType === undefined ? 'None' : actionRecord.linkedEntityType,
              "plinkedEntityID": actionRecord.linkedEntityID === undefined ? 'None' : actionRecord.linkedEntityID,
            }

            this.entityActivity_dataExportTableData.push(entity_dataItem);
          }
        }
      }

      this.entityActivity_dataExportTable = {
        fields: this.entityActivity_dataTableFields,
        "meta": this.entityActivity_dataTableMeta,
        "data": this.entityActivity_dataExportTableData,
        "totalData": this.entityActivity_dataExportTableTotalData,
        sortParams: this.entityActivity_sortParams
      }

      this.entityActivity_dataExportTable_Analytic = {
        fields: this.entityActivity_dataTableFields_Analytic,
        "meta": this.entityActivity_dataTableMeta,
        "data": this.entityActivity_dataExportTableData,
        "totalData": this.entityActivity_dataExportTableTotalData,
        sortParams: this.entityActivity_sortParams
      }
    });

    //-----------------------------------------------------
    //Run through permissoins tier to determine visibility
    //of entities, items, and states
    //-----------------------------------------------------

    const entityParse = entitySummary.entities;
    entityParse.map((entity: any) => {

      

      if (entity !== undefined) {
        //Whether to include in list Summary
        let isTerminalEntity = false;

        //Add these properties to the summary to be 
        //parsed when loading the paricular ticket
        let isApprovalStep = false;
        let canApproveEntity = false;

        //-----Initial Visibility and restricted Test----------
        let passVisibilityCheck = true;
        if (entity.owner === currentLoginInfo.user.userID && canViewOwn === true) {
          if (entity.isRestricted === true && canViewRestricted === false) {
            passVisibilityCheck = false;
          }
        }
        else if (entity.owner !== currentLoginInfo.user.userID && canviewOthers === true) {
          if (entity.isRestricted === true && canViewRestricted === false) {
            passVisibilityCheck = false;
          }
        }
        else { passVisibilityCheck = false; }

        if (passVisibilityCheck === true) {
          //-----STATE PASS #1-----------------------------------
          //-Check if Resticted, if current state, ignore entity
          //-This will be checked again when populating state 
          //-Summary no sense if overall restricted.
          //-Will need to be marked at some point
          //-----------------------------------------------------

          let passRestrictedStateCheck = true;
          if (entity.relatedStatesVerbose[entity.currentState].restricted === true && canViewRestricted === false) {
            passRestrictedStateCheck = false;
          }
          if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.terminal) {
            isTerminalEntity = true;
          }

          //-----TEMPLATE PASS #1--------------------------------
          //-Check if Global Security
          //-Then check if Roles Required
          //-Limit by role, then check the scope
          //-If all, ignore, if by team, user, or
          //persona, filter

          if (passRestrictedStateCheck === true) {

            //Get list of related templateRecords
            let templateRecordList = entitySummary.templateRecords.filter((t: any) => t.templateID !== entity.templateID);

            entitySummary.templates.map((template: any) => {
              let passTemplateSecurity = true;

              if (template.id === entity.templateID) {

                if (template.rolesRequired === true) {
                  //Role Based Security 
                  if (template.globalSecurity === true) {
                    let rolePass = false;
                    if (userRoles !== undefined) {
                      templateRecordList.map((tREcord: any) => {
                        if (tREcord.formItemType === TemplateFormTypes.requiredRoles) {
                          userRoles.items.map((pRecord: any) => {
                            if (pRecord.id === tREcord.optionID) { rolePass = true; }
                          })
                        }
                      });
                    }

                    passTemplateSecurity = rolePass;
                  }
                  else {
                    let rolePass = false;
                    if (userRoles !== undefined) {
                      templateRecordList.map((tREcord: any) => {
                        if (tREcord.formItemType === TemplateFormTypes.requiredRoles && tREcord.stateID === entity.currentState) {
                          userRoles.items.map((pRecord: any) => {
                            if (pRecord.id === tREcord.optionID) { rolePass = true; }
                          })
                        }
                      });
                    }

                    passTemplateSecurity = rolePass;
                  }
                }

                //Check Scope Based Security 
                if (passTemplateSecurity === true) {
                  if (template.globalSecurity === true) {

                    //Security rules apply to the entire entity
                    if (template.securityType == ApprovalTypes.user) {
                      let containsUser = false;
                      templateRecordList.map((tREcord: any) => {
                        if (tREcord.formItemType === TemplateFormTypes.securityGroup && tREcord.optionID === currentLoginInfo.user.userID) { containsUser = true; }
                      });

                      passTemplateSecurity = containsUser;
                    }
                    else if (template.securityType == ApprovalTypes.persona) {

                      let containsUser = false;
                      if (currentLoginInfo.personas !== undefined) {
                        templateRecordList.map((tREcord: any) => {
                          if (tREcord.formItemType === TemplateFormTypes.securityGroup) {
                            currentLoginInfo.personas?.personas?.map((pRecord: any) => {
                              if (pRecord === tREcord.optionID) { containsUser = true; }
                            })
                          }
                        });
                      }
                      passTemplateSecurity = containsUser;

                    }
                    else if (template.securityType == ApprovalTypes.team) {
                      let containsUser = false;

                      if (currentLoginInfo.teams !== undefined) {
                        templateRecordList.map((tREcord: any) => {
                          if (tREcord.formItemType === TemplateFormTypes.securityGroup) {
                            currentLoginInfo.teams?.teams?.map((pRecord: any) => {
                              if (pRecord === tREcord.optionID) { containsUser = true; }
                            })
                          }
                        });
                      }
                      passTemplateSecurity = containsUser;
                    }


                  }
                  else {
                    //Determine security for the particular state
                    //Determine if it's a Securty Step
                    let isSecurityStep = false;
                    templateRecordList.map((tREcord: any) => {
                      if (tREcord.stateID === entity.currentState && tREcord.formItemType === TemplateFormTypes.securityGroup) {
                        isSecurityStep = true;
                      }
                    });

                    if (isSecurityStep !== false) {
                      if (template.securityType == ApprovalTypes.user) {
                        let containsUser = false;
                        templateRecordList.map((tREcord: any) => {
                          if (tREcord.formItemType === TemplateFormTypes.securityGroup && tREcord.optionID === currentLoginInfo.user.userID && tREcord.stateID === entity.currentState) { containsUser = true; }
                        });

                        passTemplateSecurity = containsUser;
                      }
                      else if (template.securityType == ApprovalTypes.persona) {

                        let containsUser = false;
                        if (currentLoginInfo.personas !== undefined) {
                          templateRecordList.map((tREcord: any) => {
                            if (tREcord.formItemType === TemplateFormTypes.securityGroup) {
                              currentLoginInfo.personas?.personas?.map((pRecord: any) => {
                                if (pRecord === tREcord.optionID && tREcord.stateID === entity.currentState) { containsUser = true; }
                              })
                            }
                          });
                        }
                        passTemplateSecurity = containsUser;

                      }
                      else if (template.securityType == ApprovalTypes.team) {
                        let containsUser = false;

                        if (currentLoginInfo.teams !== undefined) {
                          templateRecordList.map((tREcord: any) => {
                            if (tREcord.formItemType === TemplateFormTypes.securityGroup) {
                              currentLoginInfo.teams?.teams?.map((pRecord: any) => {
                                if (pRecord === tREcord.optionID && tREcord.stateID === entity.currentState) { containsUser = true; }
                              })
                            }
                          });
                        }
                        passTemplateSecurity = containsUser;
                      }
                    }
                  }
                }


                //-----------------------------------------------------
                // Assign Approval Info
                //-----------------------------------------------------
                if (passTemplateSecurity === true) {
                  templateRecordList.map((tREcord: any) => {
                    if (tREcord.stateID === entity.currentState) {

                      if (tREcord.formItemType === TemplateFormTypes.approvalGroup) {
                        isApprovalStep = true;

                        if (template.approvalType === ApprovalTypes.user) {
                          if (tREcord.optionID === currentLoginInfo.user.userID) { canApproveEntity = true; }
                        }
                        else if (template.approvalType == ApprovalTypes.persona) {
                          if (currentLoginInfo.personas !== undefined) {
                            currentLoginInfo.personas?.personas?.map((pRecord: any) => {
                              if (pRecord === tREcord.optionID && tREcord.stateID === entity.currentState) { canApproveEntity = true; }
                            });
                          }
                        }
                        else if (template.approvalType == ApprovalTypes.team) {
                          if (currentLoginInfo.teams !== undefined) {
                            currentLoginInfo.teams?.teams?.map((pRecord: any) => {
                              if (pRecord === tREcord.optionID && tREcord.stateID === entity.currentState) { canApproveEntity = true; }
                            });
                          }
                        }
                      }
                    }
                  });

                  //-----------------------------------------------------
                  // Get SLA Info (to be pulled when editing tickets)
                  //-----------------------------------------------------

                  let nextSLA = '';
                  let sLAOverdue = false;
                  let timeToStart = 0;
                  let timeToComplete = 0;
                  let totalTime = 0;
                  let timeOverdue = 0;
                  let budgetHours = 0;
                  let budgetCost = 0;
                  let totalCost = 0;


                  let creatorName: any = '';
                  let ownerName: any = '';
                  let ownerType: any = '';
                  let category: any = '';


                  entitySummary.types.map((typeItems: any) => {
                    if (typeItems.id === entity.typeID) {
                      category = typeItems.name;
                    }

                  });

                  this.users.items.map((x: any) => {
                    if (x.userID === entity.creatorID) {
                      creatorName = x.firstName + ' ' + x.lastName;
                    }

                    if (entity.ownerType === itemTypes.Users) {

                      if (x.userID === entity.owner) {
                        ownerName = x.firstName + ' ' + x.lastName;
                      }
                    }

                  })


                  if (entity.ownerType === itemTypes.Users) {
                    ownerType = L('Contacts')
                  }
                  else if (entity.ownerType === itemTypes.Persons) {
                    ownerType = L('People')
                  }
                  else if (entity.ownerType === itemTypes.Teams) {
                    ownerType = L('Teams')
                  }


                  //-----------------------------------------------------
                  // Compose Entity Object
                  //-----------------------------------------------------



                  var entity_dataItem: Data =
                  {
                    "id": entity.id === undefined ? 0 : entity.id,
                    "name": entity.name === undefined ? '-' : entity.name,
                    "title": entity.title === undefined ? '-' : entity.title,
                    "creator": creatorName === undefined ? '-' : creatorName,
                    "ownerType": ownerType === undefined ? '-' : ownerType,
                    "owner": ownerName === undefined ? '-' : ownerName,
                    "template": entity.templateName === undefined ? '-' : entity.templateName,
                    "currentState": entity.stateName === undefined ? '-' : entity.stateName,
                    "category": category === undefined ? '-' : category,
                    "lastModified": entity.lastModified === undefined ? '-' : dayjs(entity.lastModified).format('MM/DD/YYYY h:mm A'),
                    "created": entity.created === undefined ? '-' : dayjs(entity.created).format('MM/DD/YYYY HH:MM A'),
                    "completed": entity.completed === undefined ? '-' : dayjs(entity.completed).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('Incomplete') : dayjs(entity.completed).format('MM/DD/YYYY h:mm A'),
                    "archived": entity.archived === undefined ? '-' : dayjs(entity.archived).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('Open') : dayjs(entity.archived).format('MM/DD/YYYY h:mm A'),
                    "nextSLA": entity.nextSLA === undefined ? '-' : dayjs(entity.nextSLA).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('None') : dayjs(entity.nextSLA).format('MM/DD/YYYY h:mm A'),
                    "slaOverdue": entity.slaOverdue === undefined ? '-' : entity.slaOverdue === false ? L('No') : L('Yes'),
                    "slaChildrenOverdue": entity.slaChildrenOverdue === undefined ? '-' : entity.slaChildrenOverdue === false ? L('No') : L('Yes'),
                    "timeToStart": entity.timeToStart === undefined ? '-' : dayjs(entity.timeToStart).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('None') : dayjs(entity.timeToStart).format('MM/DD/YYYY h:mm A'),
                    "timeToComplete": entity.timeToComplete === undefined ? '-' : dayjs(entity.timeToComplete).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('Incomplete') : dayjs(entity.timeToComplete).format('MM/DD/YYYY h:mm A'),
                    "totalTime": entity.totalTime === undefined ? '-' : dayjs(entity.totalTime).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('None') : dayjs(entity.totalTime).format('MM/DD/YYYY h:mm A'),
                    "timeOverdue": entity.timeOverdue === undefined ? '-' : dayjs(entity.timeOverdue).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('None') : dayjs(entity.timeOverdue).format('MM/DD/YYYY h:mm A'),
                    "budgetHours": entity.budgetHours === undefined ? '-' : dayjs(entity.budgetHours).format('MM/DD/YYYY h:mm A').toString() === '01/01/1901 12:00 AM' || '12/31/1969 7:00 PM' ? L('None') : dayjs(entity.budgetHours).format('MM/DD/YYYY h:mm A'),
                    "budgetCost": entity.budgetCost === undefined ? '-' : L('$') + entity.budgetCost,
                    "totalCost": entity.totalCost === undefined ? '-' : L('$') + entity.totalCost,
                    "isRestricted": entity.isRestricted === undefined ? '-' : entity.isRestricted === false ? L('No') : L('Yes'),
                    "currentlyApproved": entity.currentlyApproved === undefined ? '-' : entity.currentlyApproved === false ? L('No') : L('Yes'),

                  }

                  //Create Report Object 
                  if (isTerminalEntity === true || entity.restricted === true) {
                    //Update Report View
                    this.entity_dataExportTableData.push(entity_dataItem);
                  }
                  else {

                    var updateItem: any = {
                      id: entity.id,
                      name: entity.name,
                      title: entity.title,
                      currentState: entity.currentState,
                      templateID: entity.templateID,
                      profileImageID: entity.profileImageID,
                      creatorID: entity.creatorID,
                      templateName: entity.templateName,
                      stateName: entity.stateName,
                      lastModified: entity.lastModified,
                      nextSLA: nextSLA,
                      sLAOverdue: sLAOverdue,
                      sLAChildrenOverdue: sLAOverdue,
                      owner: entity.owner,
                      ownerType: entity.ownerType,
                      timeToStart: timeToStart,
                      timeToComplete: timeToComplete,
                      totalTime: totalTime,
                      timeOverdue: timeOverdue,
                      budgetHours: budgetHours,
                      budgetCost: budgetCost,
                      totalCost: totalCost,
                      typeID: template.typeID,
                      typeName: category,
                      isApprovalStep: isApprovalStep,
                      canApproveStep: canApproveEntity,
                      creatorName: creatorName,
                      ownerName: ownerName,
                      relatedStatesVerbose: entity.relatedStatesVerbose,

                    }

                    this.entitySummary.items.push(updateItem);

                    //Update Report View
                    this.entity_dataExportTableData.push(entity_dataItem);

                  }

                }

              }
            });
          }
        }

      }

    });

    
    //-----------------------------------------------------
    // Assign Mention Data & State Steps
    //-----------------------------------------------------

    this.entitysearch_Hashtags = [];
    entitySummary.types.map((x: any) => {
      this.entitysearch_Hashtags.push({
        id: x.id,
        relatedEntity: x.id,
        title: x.name
      });
    });

    this.statesearch_Hashtags = [];
    entitySummary.states.map((x: any) => {
      if (x.restricted === false) {
        this.statesearch_Hashtags.push({
          id: x.id,
          relatedEntity: x.id,
          title: x.name
        });
      }
    });

    //-----------------------------------------------------
    // Push Report Table Data
    //-----------------------------------------------------

    this.entity_dataExportTable = {
      fields: this.entity_dataTableFields,
      "meta": this.entity_dataTableMeta,
      "data": this.entity_dataExportTableData,
      "totalData": this.entity_dataExportTableTotalData,
    }

    this.entity_dataExportTable_Analytic = {
      fields: this.entity_dataTableFields_Analytic,
      sortParams: this.entity_sortParams,
      "meta": this.entity_dataTableMeta,
      "data": this.entity_dataExportTableData,
      "totalData": this.entity_dataExportTableTotalData,
    }

    

    //-----------------------------------------------------
    // Get Summary View Data
    //-----------------------------------------------------


    entitySummary.types.reverse().map((typeItems: any) => {


      if (typeItems.showSummaryCard === true) {

        //Get Related Entities
        let typeEntities = entitySummary.entities.filter((x: any) => x.typeID === typeItems.id);

        //Init Update Object
        let summaryObject: any = {

          showSummaryCard: typeItems.showSummaryCard,
          showListCard: typeItems.showListCard,
          summaryCardType: typeItems.summaryCardType,
          summaryReportOneType: typeItems.summaryReportOneType,
          summaryReportTwoType: typeItems.summaryReportTwoType,
          summaryReportOneSourceType: typeItems.summaryReportOneSourceType,
          summaryReportTwoSourceType: typeItems.summaryReportTwoSourceType,
          summaryReportOneSourceId: typeItems.summaryReportOneSourceId,
          summaryReportTwoSourceId: typeItems.summaryReportTwoSourceId,
          summaryReportOneTitle: typeItems.summaryReportOneTitle,
          summaryReportTwoTitle: typeItems.summaryReportTwoTitle,
          summaryReportOneSource: typeItems.summaryReportOneSource,
          summaryReportTwoSource: typeItems.summaryReportTwoSource,
          listCardType: typeItems.listCardType,
          listReportOneType: typeItems.listReportOneType,
          listReportTwoType: typeItems.listReportTwoType,
          listReportThreeType: typeItems.listReportThreeType,
          listReportOneTitle: typeItems.listReportOneTitle,
          listReportTwoTitle: typeItems.listReportTwoTitle,
          listReportThreeTitle: typeItems.listReportThreeTitle,
          summaryCardOneTitle: typeItems.summaryCardOneTitle,
          summaryCardTwoTitle: typeItems.summaryCardTwoTitle,
          summaryCardThreeTitle: typeItems.summaryCardThreeTitle,
          summaryCardFourTitle: typeItems.summaryCardFourTitle,
          summaryCardOneType: typeItems.summaryCardOneType,
          summaryCardTwoType: typeItems.summaryCardTwoType,
          summaryCardThreeType: typeItems.summaryCardThreeType,
          summaryCardFourType: typeItems.summaryCardFourType,
          summaryCardOneSourceType: typeItems.summaryCardOneSourceType,
          summaryCardTwoSourceType: typeItems.summaryCardTwoSourceType,
          summaryCardThreeSourceType: typeItems.summaryCardThreeSourceType,
          summaryCardFourSourceType: typeItems.summaryCardFourSourceType,
          summaryCardOneSourceId: typeItems.summaryCardOneSourceId,
          summaryCardTwoSourceId: typeItems.summaryCardTwoSourceId,
          summaryCardThreeSourceId: typeItems.summaryCardThreeSourceId,
          summaryCardFourSourceId: typeItems.summaryCardFourSourceId,

          AreaChart: [],
          BarChart: [],
          BoxPlot: [],
          BubbleGraph: [],
          Bullet: 0,
          CirclePacking: {
            "name": "root",
            "children": []
          },
          ColumnGraph: [],
          DonutChart: [],
          FunnelChart: [],
          Gauge: 0,
          HeatMap: [],
          Histogram: [],
          LineChart: [],
          Liquid: 0,
          MultiLineChart: [],
          PieChart: [],
          Progress: 0,
          RadarChart: [],
          RadialBarChart: [],
          Ring: [],
          RoseChart: [],
          SankeyPlot: [],
          ScatterPlot: [],
          StockPlot: [],
          SunburstPlot: [],
          VennPlot: [],
          ViolinPlot: [],
          WaterfallChart: [],

          TinyAreaPlot: [],
          TinyColumnPlot: [],
          TinyLinePlot: [],
          TinyLiquidPlot: 0,
          TinyProgress: 0,
          TinyRingProgress: 0,
          TinyStatistic: 0,

          AreaChart2: [],
          BarChart2: [],
          BoxPlot2: [],
          BubbleGraph2: [],
          Bullet2: 0,
          CirclePacking2: {
            "name": "root",
            "children": []
          },
          ColumnGraph2: [],
          DonutChart2: [],
          FunnelChart2: [],
          Gauge2: 0,
          HeatMap2: [],
          Histogram2: [],
          LineChart2: [],
          Liquid2: 0,
          MultiLineChart2: [],
          PieChart2: [],
          Progress2: 0,
          RadarChart2: [],
          RadialBarChart2: [],
          Ring2: [],
          RoseChart2: [],
          SankeyPlot2: [],
          ScatterPlot2: [],
          StockPlot2: [],
          SunburstPlot2: [],
          VennPlot2: [],
          ViolinPlot2: [],
          WaterfallChart2: [],

          TinyAreaPlot2: [],
          TinyColumnPlot2: [],
          TinyLinePlot2: [],
          TinyLiquidPlot2: 0,
          TinyProgress2: 0,
          TinyRingProgress2: 0,
          TinyStatistic2: 0,

          total: typeEntities.length,
          beginningStates: 0,
          workflowStates: 0,
          endState: 0,
          terminalState: 0,

          id: 0,
          name: typeItems.name,
          title: typeItems.title,
          templateName: '',
          creatorID: 0,
          owner: 0,
          ownerType: 0,
          profileImageID: 0,
          isActive: true,
          lastLoginTime: '',
          lastModified: '',
          currentStage: 0,
          stateName: '',
          relatedStatesVerbose: [],

          userOwners: 0,
          peopleOwners: 0,
          teamOwners: 0,

          //-----------------------------------------------------

          stateTypeSummary: [],
          slaSummary: [],

          creatorSummary: [],
          ownerTypeSummary: [],
          ownerSummary_Users: [],
          ownerSummary_People: [],
          ownerSummary_Teams: [],
          ownerSummary_Combined: [],

          stateSummary: [],
          stateByCreator: [], //TODO
          stateByOwner: [], //TODO

          templateSummary: [],
          templateByCreator: [], //TODO
          templateByOwner: [], //TODO

          totalBudgetCost: 0,
          budgetCostByCreator: [], //TODO
          budgetCostByOwner: [], //TODO
          budgetCostByState: [], //TODO
          budgetCostByTempalte: [],  //TODO

          totalBudgetHours: 0,
          budgetHoursByCreator: [], //TODO
          budgetHoursByOwner: [], //TODO
          budgetHoursByState: [], //TODO
          budgetHoursByTempalte: [],  //TODO

          totalCost: 0,
          costByCreator: [], //TODO
          costByOwner: [], //TODO
          costByState: [], //TODO //TODO
          costByTempalte: [],

          totalHours: 0,
          hoursByCreator: [], //TODO
          hoursByOwner: [], //TODO
          hoursByState: [], //TODO
          hoursByTemplate: [], //TODO

          completedOnTimeRatio: 0,
          onTimeByCreator: [], //TODO
          onTimeByOwner: [], //TODO
          onTimeByState: [], //TODO
          onTimeByTempalte: [],  //TODO

          averageTimeToStart: 0, //TODO
          averageTimeToStartByCreator: [], //TODO
          averageTimeToStartByOwner: [], //TODO
          averageTimeToStartByState: [], //TODO
          averageTimeToStartByTempalte: [],  //TODO

          averagetimeToComplete: 0, //TODO
          averageTimeToCompleteByCreator: [], //TODO
          averageTimeToCompleteByOwner: [], //TODO
          averageTimeToCompleteByState: [], //TODO
          averageTimeToCompleteByTempalte: [],  //TODO

          averageTotalTime: 0,
          averageTotalTimeByCreator: [], //TODO
          averageTotalTimeByOwner: [], //TODO
          averageTotalTimeByState: [], //TODO
          averageTotalTimeByTempalte: [],  //TODO

          averageTimeOverdue: 0, //TODO
          averageTimeOverdueByCreator: [], //TODO
          averageTimeOverdueByOwner: [], //TODO
          averageTimeOverdueByState: [], //TODO
          averageTimeOverdueByTempalte: [],   //TODO

          totalTimeOverdue: 0,
          totalTimeOverdueByCreator: [], //TODO
          totalTimeOverdueByOwner: [], //TODO
          totalTimeOverdueByState: [], //TODO
          totalTimeOverdueByTempalte: [],  //TODO

          totalCostOverdue: 0,
          totalCostOverdueByCreator: [], //TODO
          totalCostOverdueByOwner: [], //TODO
          totalCostOverdueByState: [], //TODO
          totalCostOverdueByTempalte: [], //TODO

          averageBudgetHours: 0,
          averageBudgetHoursByCreator: [], //TODO
          averageBudgetHoursByOwner: [], //TODO
          averageBudgetHoursByState: [], //TODO
          averageBudgetHoursByTempalte: [],  //TODO

          averageBudgetCost: 0,
          averageBudgetCostByCreator: [], //TODO
          averageBudgetCostByOwner: [], //TODO
          averageBudgetCostByState: [], //TODO
          averageBudgetCostByTempalte: [],  //TODO

          averageTotalCost: 0,
          averageTotalCostByCreator: [], //TODO
          averageTotalCostByOwner: [], //TODO
          averageTotalCostByState: [], //TODO
          averageTotalCostByTempalte: [],  //TODO

          completedInBudgetRatio: 0,
          completedInBudgetRatioByCreator: [], //TODO
          completedInBudgetRatioByOwner: [],  //TODO

          inQueuePercentage: 0,
          inQueueByCreator: [],
          inQueueByOwner: [],  //TODO
          inQueuePercentageByCreator: [], //TODO
          inQueuePercentageByOwner: [],  //TODO

          inProgressPercentage: 0,
          inProgressByCreator: [], //TODO
          inProgressByOwner: [],  //TODO
          inProgressPercentageByCreator: [], //TODO
          inProgressPercentageByOwner: [],  //TODO

          completePercentage: 0, //TODO
          completePercentageByCreator: [], //TODO
          completePercentageByOwner: [],  //TODO
          completePercentagePercentageByCreator: [], //TODO
          completePercentagePercentageByOwner: [],  //TODO

          slaOverduePercentage: 0, //TODO
          slaOverdueByCreator: [], //TODO
          slaOverdueByOwner: [],  //TODO
          slaOverduePercentageByCreator: [], //TODO
          slaOverduePercentageByOwner: [],  //TODO

          summaryCardOneValue: '',
          summaryCardTwoValue: '',
          summaryCardThreeValue: '',
          summaryCardFourValue: '',

          completedInTime: 0,
          completedInBudget: 0,

          slaOverdue: 0,
          myTickets: 0,

          createdSummary: [],
          lastModifiedSummary: [],

        }

        //-----------------------------------------------------
        // Top Level Data
        //-----------------------------------------------------

        // Get Top Level Data
        typeEntities.map((z: any) => {


          //Check for Created
          let createdItemExists = false;
          summaryObject.createdSummary.map((createdItem: any) => {

            if (createdItem.name === dayjs(z.created).format('MM/DD/YYYY').toString() && createdItem.template === z.templateName) {
              createdItemExists = true;
            }

          })

          if (createdItemExists === false) {
            let dayTotal = typeEntities.filter((ds: any) => dayjs(ds.created).format('MM/DD/YYYY').toString() && ds.templateName === z.templateName).length;
            let createdSummaryUpdate = {
              name: dayjs(z.created).format('MM/DD/YYYY').toString(),
              value: dayTotal,
              template: z.templateName
            }
            summaryObject.createdSummary.push(createdSummaryUpdate);
          }


          //Check for lastModified
          let modifiedItemExists = false;
          summaryObject.lastModifiedSummary.map((createdItem: any) => {

            if (createdItem.name === dayjs(z.lastModified).format('MM/DD/YYYY').toString() && createdItem.template === z.templateName) {
              modifiedItemExists = true;
            }
          })

          if (modifiedItemExists === false) {
            let dayTotal = typeEntities.filter((ds: any) => dayjs(ds.lastModified).format('MM/DD/YYYY').toString() && ds.templateName === z.templateName).length;
            let createdSummaryUpdate = {
              name: dayjs(z.lastModified).format('MM/DD/YYYY').toString(),
              value: dayTotal,
              template: z.templateName
            }
            summaryObject.lastModifiedSummary.push(createdSummaryUpdate);
          }

          let stateCounter = 0;
          z.relatedStatesVerbose.map((r: any) => {

            if (z.currentState === stateCounter) {
              if (r.type === StateTypes.start) {
                summaryObject.beginningStates = summaryObject.beginningStates + 1;
              }
              else if (r.type === StateTypes.middle) {
                summaryObject.workflowStates = summaryObject.workflowStates + 1;
              }
              else if (r.type === StateTypes.last) {
                summaryObject.endState = summaryObject.endState + 1;
              }
              else if (r.type === StateTypes.terminal) {
                summaryObject.terminalState = summaryObject.terminalState + 1;
              }
            }

            //Get/Determine if SLA Overdue
            //TODO

            stateCounter = stateCounter + 1;

          })

          //Get Additinal Totals
          summaryObject.totalBudgetCost = summaryObject.totalBudgetCost + z.budgetCost;
          summaryObject.totalBudgetHours = summaryObject.totalBudgetHours + z.budgetHours;
          summaryObject.totalCost = summaryObject.totalCost + z.totalCost;
          summaryObject.totalHours = summaryObject.totalHours + z.totalTime;

          if ((z.totalTime - z.budgetHours) > 0) {
            summaryObject.totalTimeOverdue = summaryObject.totalTimeOverdue + (z.totalTime - z.budgetHours);
          }
          else {
            summaryObject.completedInTime = summaryObject.completedInTime + 1;
          }

          if ((z.totalCost - z.budgetCost) > 0) {
            summaryObject.totalCostOverdue = summaryObject.totalCostOverdue + (z.totalCost - z.budgetCost);
          }
          else {
            summaryObject.completedInBudget = summaryObject.completedInBudget + 1;
          }


          if (z.owner === currentLoginInfo.user.userID) {
            summaryObject.myTickets = summaryObject.myTickets + 1;
          }



        })

        //-----------------------------------------------------
        // Summary Data
        //-----------------------------------------------------


        //Set State Type Summary
        summaryObject.stateTypeSummary = [
          {
            type: L('Queued'),
            value: summaryObject.beginningStates
          },
          {
            type: L('In Progress'),
            value: summaryObject.workflowStates
          },
          {
            type: L('Complete'),
            value: summaryObject.endState
          },
          {
            type: L('Archived'),
            value: summaryObject.terminalState
          },
        ];

        //Set State Summary 
        entitySummary.states.reverse().map((xState: any) => {

          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.stateName.toLowerCase() === xState.name.toLowerCase()).length;

          if (stateTotal >= 1) {

            let updateObject: any = {
              type: xState.name,
              value: stateTotal
            }

            summaryObject.stateSummary.push(updateObject);

            if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {

              typeEntities.filter((xStateID: any) => xStateID.stateName.toLowerCase() === xState.name.toLowerCase()).map((multiObject: any) => {

                //Get Total Created That Day
                let dayTotal = typeEntities.filter((ds: any) => ds.stateName.toLowerCase() === xState.name.toLowerCase() && dayjs(ds.created).format('MM/DD/YYYY').toString() === dayjs(multiObject.created).format('MM/DD/YYYY').toString()).length;

                //Set MultiLine Chart Data
                let multiLineObject: any = {
                  year: dayjs(multiObject.created).format('MM/DD/YYYY').toString(),
                  value: dayTotal,
                  category: xState.name
                };

                summaryObject.MultiLineChart.push(multiLineObject);
              })

            }
          }

        });


        //Set Template Summary
        entitySummary.templates.reverse().map((xState: any) => {

          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.templateID === xState.id).length;

          if (stateTotal >= 1) {

            let updateObject: any = {
              type: xState.name,
              value: stateTotal
            }

            summaryObject.templateSummary.push(updateObject);

            if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {

              typeEntities.filter((xStateID: any) => xStateID.templateID === xState.id).map((multiObject: any) => {

                //Get Total Created That Day
                let dayTotal = typeEntities.filter((ds: any) => ds.templateID === xState.id && dayjs(ds.created).format('MM/DD/YYYY').toString() === dayjs(multiObject.created).format('MM/DD/YYYY').toString()).length;

                //Set MultiLine Chart Data
                let multiLineObject: any = {
                  year: dayjs(multiObject.created).format('MM/DD/YYYY').toString(),
                  value: dayTotal,
                  category: xState.name
                };

                summaryObject.MultiLineChart.push(multiLineObject);
              })

            }
          }

        });

        if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.created || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.created) {




          summaryObject.createdSummary.map((subObject: any) => {

            let multiLineObject: any = {
              year: subObject.name,
              value: subObject.value,
              category: subObject.value
            };

            summaryObject.MultiLineChart.push(multiLineObject);
          });
        }

        if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.lastModified || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.lastModified) {
          summaryObject.lastModifiedSummary.map((subObject: any) => {

            let multiLineObject: any = {
              year: subObject.name,
              value: subObject.value,
              category: subObject.template
            };

            summaryObject.MultiLineChart.push(multiLineObject);
          });
        }


        //Set Creator Summary
        this.users.items.map((xState: any) => {

          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.creatorID === xState.id).length;

          if (stateTotal >= 1) {

            let updateObject: any = {
              name: xState.name,
              total: stateTotal
            }

            summaryObject.creatorSummary.push(updateObject);

            if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {

              typeEntities.filter((xStateID: any) => xStateID.creatorID === xState.id).map((multiObject: any) => {

                //Get Total Created That Day
                let dayTotal = typeEntities.filter((ds: any) => ds.creatorID === xState.id && dayjs(ds.created).format('MM/DD/YYYY').toString() === dayjs(multiObject.created).format('MM/DD/YYYY').toString()).length;

                //Set MultiLine Chart Data
                let multiLineObject: any = {
                  year: dayjs(multiObject.created).format('MM/DD/YYYY').toString(),
                  value: dayTotal,
                  category: xState.name
                };

                summaryObject.MultiLineChart.push(multiLineObject);
              })

            }

            if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {

              //Get by Creatotr
              typeEntities.filter((xStateID: any) => xStateID.creatorID === xState.id).map((multiObject: any) => {


                let totalStates = multiObject.relatedStatesVerbose.length;
                let stateCounter = 0;

                //Loop States
                multiObject.relatedStatesVerbose.map((subState: any) => {

                  if (totalStates > stateCounter) {
                    //Get Total in State
                    //let stateTotals = multiObject.filter((stateSub: any) => stateSub.stateName.toLowerCase() === subState.name.toLowerCase()).length;

                    ///let multiLineObject: any = {
                    //  source: subState.name,
                    //  target: multiObject.relatedStatesVerbose[stateCounter + 1].name,
                    // value: stateTotals
                    //};

                    //summaryObject.Sankey.push(multiLineObject);
                  }

                })

              })

            }
          }

        });

        //Get User Owner Summary
        this.users.items.map((xState: any) => {

          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.owner === xState.id && xStateID.ownerType === itemTypes.Users).length;

          if (stateTotal >= 1) {
            let updateObject: any = {
              type: xState.firstName + ' ' + xState.lastName,
              value: stateTotal
            }

           

            summaryObject.ownerSummary_Users.push(updateObject);
            summaryObject.ownerSummary_Combined.push(updateObject);
          }

        });

        //Get People Owner Summary
        this.persons.items.map((xState: any) => {
          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.owner === xState.id && xStateID.ownerType === itemTypes.Persons).length;

          if (stateTotal >= 1) {
            let updateObject: any = {
              name: xState.name,
              total: stateTotal
            }

            summaryObject.ownerSummary_People.push(updateObject);
            summaryObject.ownerSummary_Combined.push(updateObject);
          }
        });

        //Get People Owner Summary
        this.teams.items.map((xState: any) => {
          let stateTotal = typeEntities.filter((xStateID: any) => xStateID.owner === xState.id && xStateID.ownerType === itemTypes.Teams).length;

          if (stateTotal >= 1) {
            let updateObject: any = {
              name: xState.name,
              total: stateTotal
            }

            summaryObject.ownerSummary_Teams.push(updateObject);
            summaryObject.ownerSummary_Combined.push(updateObject);
          }
        });

        //Set Owner Type Summary Object
        summaryObject.ownerTypeSummary = [
          {
            type: L('Users'),
            value: summaryObject.ownerSummary_Users.length
          },
          {
            type: L('People'),
            value: summaryObject.ownerSummary_People.length
          },
          {
            type: L('Teams'),
            value: summaryObject.ownerSummary_Teams.length
          },
        ];

        //Set SLA Summary Object
        summaryObject.slaSummary = [
          {
            type: L('On Time'),
            value: typeEntities.length - summaryObject.slaOverdue
          },
          {
            type: L('Overdue'),
            value: summaryObject.slaOverdue
          },
        ];


        //Get Averages
        summaryObject.completedOnTimeRatio = (summaryObject.completedInTime / summaryObject.total)
        summaryObject.completedInBudgetRatio = (summaryObject.completedInBudget / summaryObject.total)
        summaryObject.averageBudgetCost = (summaryObject.total / summaryObject.totalBudgetCost);
        summaryObject.averageBudgetHours = (summaryObject.total / summaryObject.totalBudgetHours);
        summaryObject.averageTotalTime = (summaryObject.total / summaryObject.totalHours);
        summaryObject.averageTotalCost = (summaryObject.total / summaryObject.totalCost);
        summaryObject.inQueuePercentage = (summaryObject.beginningStates / summaryObject.total);
        summaryObject.inProgressPercentage = (summaryObject.workflowStates / summaryObject.total);
        summaryObject.completePercentage = (summaryObject.endState / summaryObject.total);



        //-----------------------------------------------------
        // Assign Report Data
        //-----------------------------------------------------

        if (summaryObject.summaryReportOneType === DashboardItemTypes.AreaChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });

          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.BarChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {


            summaryObject.stateTypeSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });

          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.created) {

            summaryObject.createdSummary.map((subObject: any) => {
              let updateItemObject: any = { Title: subObject.template, Value: subObject.value }
              summaryObject.BarChart.push(updateItemObject);

            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.lastModified) {
            summaryObject.lastModifiedSummary.map((subObject: any) => {
              let updateItemObject: any = { Title: subObject.template, Value: subObject.value }
              summaryObject.BarChart.push(updateItemObject);

            });
          }


        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.DonutChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
  
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {

            
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.FunnelChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.PieChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.RadarChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.RadialBarChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.ColumnGraph) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.CirclePacking) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking.children.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.LineChart) {

          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType || summaryObject.summaryReportTwoSourceType) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.RoseChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {



            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.WaterfallChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportOneType === DashboardItemTypes.MultiLineChart) {
          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.created) {

            summaryObject.createdSummary.map((subObject: any) => {

              let multiLineObject: any = {
                Multiline_X: subObject.name,
                Multiline_Y: subObject.value,
                Multiline_SeriesField: subObject.value
              };

              summaryObject.MultiLineChart.push(multiLineObject);
            });
          }

          if (summaryObject.summaryReportOneSourceType === SummaryReportSourceTypes.lastModified) {
            summaryObject.lastModifiedSummary.map((subObject: any) => {

              let multiLineObject: any = {
                Multiline_X: subObject.name,
                Multiline_Y: subObject.value,
                Multiline_SeriesField: subObject.template
              };

              summaryObject.MultiLineChart.push(multiLineObject);
            });
          }

        }

        //-----------------------------------------------------
        
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.AreaChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });

          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary || summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Area_X: reportItem.type,  Area_Y: reportItem.value }
              summaryObject.AreaChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.BarChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {


            summaryObject.stateTypeSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });

          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
             let updateItemObject: any = { Bar_X: reportItem.type, Bar_Y: reportItem.value, Bar_SeriesField: reportItem.value,  }
              summaryObject.BarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.created) {

            summaryObject.createdSummary.map((subObject: any) => {
              let updateItemObject: any = { Title: subObject.template, Value: subObject.value }
              summaryObject.BarChart2.push(updateItemObject);

            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.lastModified) {
            summaryObject.lastModifiedSummary.map((subObject: any) => {
              let updateItemObject: any = { Title: subObject.template, Value: subObject.value }
              summaryObject.BarChart2.push(updateItemObject);

            });
          }


        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.DonutChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
  
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {

            
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Donut_AngleField: reportItem.type, Donut_ColorField: reportItem.value }
              summaryObject.DonutChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.FunnelChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Funnel_X: reportItem.type, Funnel_Y: reportItem.value, Funnel_CompareField: '' }
              summaryObject.FunnelChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.PieChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = {Pie_AngleField: reportItem.type, Pie_ColorField: reportItem.value }
              summaryObject.PieChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.RadarChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Radar_X: reportItem.type, Radar_Y: reportItem.value }
              summaryObject.RadarChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.RadialBarChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RadialBar_X: reportItem.type, RadialBar_Y: reportItem.value }
              summaryObject.RadialBarChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.ColumnGraph) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { Column_X: reportItem.type, Column_Y: reportItem.value, Column_SeriesField: reportItem.value,  }
              summaryObject.ColumnGraph2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.CirclePacking) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { CirclePacking_X: reportItem.type, CirclePacking_Y: reportItem.value }
              summaryObject.CirclePacking2.children.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.LineChart) {

          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType || summaryObject.summaryReportTwoSourceType) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { LineChart_X: reportItem.type, LineChart_Y: reportItem.value }
              summaryObject.LineChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.RoseChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {



            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = { RoseChart_X: reportItem.type, RoseChart_Y: reportItem.value, RoseChart_SeriesField: reportItem.value }
              summaryObject.RoseChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.WaterfallChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateTypeSummary) {

            summaryObject.stateTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.slaSummary) {
            summaryObject.slaSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.creatorSummary) {
            summaryObject.creatorSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerTypeSummary) {
            summaryObject.ownerTypeSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Users) {
            summaryObject.ownerSummary_Users.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_People) {
            summaryObject.ownerSummary_People.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Teams) {
            summaryObject.ownerSummary_Teams.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.ownerSummary_Combined) {
            summaryObject.ownerSummary_Combined.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.stateSummary) {
            summaryObject.stateSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
          else if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.templateSummary) {
            summaryObject.templateSummary.map((reportItem: any) => {
              let updateItemObject: any = {WaterfallChart_X: reportItem.type, WaterfallChart_Y: reportItem.value }
              summaryObject.WaterfallChart2.push(updateItemObject);
            });
          }
        }
        if (summaryObject.summaryReportTwoType === DashboardItemTypes.MultiLineChart) {
          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.created) {

            summaryObject.createdSummary.map((subObject: any) => {

              let multiLineObject: any = {
                Multiline_X: subObject.name,
                Multiline_Y: subObject.value,
                Multiline_SeriesField: subObject.value
              };

              summaryObject.MultiLineChart2.push(multiLineObject);
            });
          }

          if (summaryObject.summaryReportTwoSourceType === SummaryReportSourceTypes.lastModified) {
            summaryObject.lastModifiedSummary.map((subObject: any) => {

              let multiLineObject: any = {
                Multiline_X: subObject.name,
                Multiline_Y: subObject.value,
                Multiline_SeriesField: subObject.template
              };

              summaryObject.MultiLineChart2.push(multiLineObject);
            });
          }

        }
 
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Bullet ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Bullet ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Bullet ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Bullet) {



          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.totalBudgetCost) {

            summaryObject.Bullet = [
              {
                title: L('Budget Costs'),
                ranges: [summaryObject.totalCost],
                measures: [summaryObject.averageBudgetCost],
                target: summaryObject.totalBudgetCost,
              }
            ]
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.totalBudgetHours) {

            summaryObject.Bullet = [
              {
                title: L('Budget Hours'),
                ranges: [summaryObject.totalHours],
                measures: [summaryObject.averageBudgetHours],
                target: summaryObject.totalBudgetHours,
              }
            ]

          }

        }
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Gauge ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Gauge ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Gauge ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Gauge) {

          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {

            summaryObject.Gauge = summaryObject.inQueuePercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {

            summaryObject.Gauge = summaryObject.inProgressPercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.completePercentage) {

            summaryObject.Gauge = summaryObject.completePercentage;
          }


        }
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Liquid ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Liquid ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Liquid ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Liquid) {

          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {

            summaryObject.Liquid = summaryObject.inQueuePercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {

            summaryObject.Liquid = summaryObject.inProgressPercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.completePercentage) {

            summaryObject.Liquid = summaryObject.completePercentage;
          }


        }
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Progress ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Progress ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Progress ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Progress) {

          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {

            summaryObject.Progress = summaryObject.inQueuePercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {

            summaryObject.Progress = summaryObject.inProgressPercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.completePercentage) {

            summaryObject.Progress = summaryObject.completePercentage;
          }


        }
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Ring ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Ring ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Ring ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Ring) {

          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {

            summaryObject.Ring = summaryObject.inQueuePercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {

            summaryObject.Ring = summaryObject.inProgressPercentage;
          }
          if (summaryObject.summaryCardOneSourceType ||
            summaryObject.summaryReportTwoSourceType ||
            summaryObject.summaryReportThreeSourceType ||
            summaryObject.summaryReportFourSourceType === SummaryReportSourceTypes.completePercentage) {

            summaryObject.Ring = summaryObject.completePercentage;
          }


        }
        if (summaryObject.summaryCardOneType === DashboardItemTypes.Statistic ||
          summaryObject.summaryCardTwoType === DashboardItemTypes.Statistic ||
          summaryObject.summaryCardThreeType === DashboardItemTypes.Statistic ||
          summaryObject.summaryCardFourType
          === DashboardItemTypes.Statistic) {


          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalBudgetCost ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalBudgetCost ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalBudgetCost ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalBudgetCost) {


            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalBudgetCost) {
              summaryObject.summaryCardOneValue = '$' + summaryObject.totalBudgetCost;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalBudgetCost) {
              summaryObject.summaryCardTwoValue = '$' + summaryObject.totalBudgetCost;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalBudgetCost) {
              summaryObject.summaryCardThreeValue = '$' + summaryObject.totalBudgetCost;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalBudgetCost) {
              summaryObject.summaryCardFourValue = '$' + summaryObject.totalBudgetCost;
            }

            summaryObject.Statistic = '$' + summaryObject.totalBudgetCost;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalBudgetHours ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalBudgetHours ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalBudgetHours ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalBudgetHours) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalBudgetHours) {
              summaryObject.summaryCardOneValue = summaryObject.totalBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalBudgetHours) {
              summaryObject.summaryCardTwoValue = summaryObject.totalBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalBudgetHours) {
              summaryObject.summaryCardThreeValue = summaryObject.totalBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalBudgetHours) {
              summaryObject.summaryCardFourValue = summaryObject.totalBudgetHours + ' hrs';
            }

            summaryObject.Statistic = summaryObject.totalBudgetHours + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalCost ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalCost ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalCost ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalCost) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalCost) {
              summaryObject.summaryCardOneValue = '$' + summaryObject.totalCost;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalCost) {
              summaryObject.summaryCardTwoValue = '$' + summaryObject.totalCost;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalCost) {
              summaryObject.summaryCardThreeValue = '$' + summaryObject.totalCost;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalCost) {
              summaryObject.summaryCardFourValue = '$' + summaryObject.totalCost;
            }

            summaryObject.Statistic = '$' + summaryObject.totalCost;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalHours ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalHours ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalHours ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalHours) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalHours) {
              summaryObject.summaryCardOneValue = summaryObject.totalHours + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalHours) {
              summaryObject.summaryCardTwoValue = summaryObject.totalHours + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalHours) {
              summaryObject.summaryCardThreeValue = summaryObject.totalHours + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalHours) {
              summaryObject.summaryCardFourValue = summaryObject.totalHours + ' hrs';
            }

            summaryObject.Statistic = summaryObject.totalHours + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedOnTimeRatio ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedOnTimeRatio ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedOnTimeRatio ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedOnTimeRatio) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedOnTimeRatio) {
              summaryObject.summaryCardOneValue = (summaryObject.completedOnTimeRatio + 100) + '%';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedOnTimeRatio) {
              summaryObject.summaryCardTwoValue = (summaryObject.completedOnTimeRatio + 100) + '%';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedOnTimeRatio) {
              summaryObject.summaryCardThreeValue = (summaryObject.completedOnTimeRatio + 100) + '%';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedOnTimeRatio) {
              summaryObject.summaryCardFourValue = (summaryObject.completedOnTimeRatio + 100) + '%';
            }

            summaryObject.Statistic = (summaryObject.completedOnTimeRatio + 100) + '%';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTimeToStart ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTimeToStart ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTimeToStart ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTimeToStart) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTimeToStart) {
              summaryObject.summaryCardOneValue = summaryObject.averageTimeToStart + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTimeToStart) {
              summaryObject.summaryCardTwoValue = summaryObject.averageTimeToStart + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTimeToStart) {
              summaryObject.summaryCardThreeValue = summaryObject.averageTimeToStart + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTimeToStart) {
              summaryObject.summaryCardFourValue = summaryObject.averageTimeToStart + ' hrs';
            }

            summaryObject.Statistic = summaryObject.averageTimeToStart + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averagetimeToComplete ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averagetimeToComplete ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averagetimeToComplete ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averagetimeToComplete) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averagetimeToComplete) {
              summaryObject.summaryCardOneValue = summaryObject.averagetimeToComplete + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averagetimeToComplete) {
              summaryObject.summaryCardTwoValue = summaryObject.averagetimeToComplete + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averagetimeToComplete) {
              summaryObject.summaryCardThreeValue = summaryObject.averagetimeToComplete + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averagetimeToComplete) {
              summaryObject.summaryCardFourValue = summaryObject.averagetimeToComplete + ' hrs';
            }

            summaryObject.Statistic = summaryObject.averagetimeToComplete + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTotalTime ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTotalTime ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTotalTime ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTotalTime) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTotalTime) {
              summaryObject.summaryCardOneValue = summaryObject.averageTotalTime + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTotalTime) {
              summaryObject.summaryCardTwoValue = summaryObject.averageTotalTime + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTotalTime) {
              summaryObject.summaryCardThreeValue = summaryObject.averageTotalTime + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTotalTime) {
              summaryObject.summaryCardFourValue = summaryObject.averageTotalTime + ' hrs';
            }

            summaryObject.Statistic = summaryObject.averageTotalTime + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTimeOverdue ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTimeOverdue ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTimeOverdue ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTimeOverdue) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTimeOverdue) {
              summaryObject.summaryCardOneValue = summaryObject.averageTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTimeOverdue) {
              summaryObject.summaryCardTwoValue = summaryObject.averageTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTimeOverdue) {
              summaryObject.summaryCardThreeValue = summaryObject.averageTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTimeOverdue) {
              summaryObject.summaryCardFourValue = summaryObject.averageTimeOverdue + ' hrs';
            }

            summaryObject.Statistic = summaryObject.averageTimeOverdue + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalTimeOverdue ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalTimeOverdue ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalTimeOverdue ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalTimeOverdue) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalTimeOverdue) {
              summaryObject.summaryCardOneValue = summaryObject.totalTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalTimeOverdue) {
              summaryObject.summaryCardTwoValue = summaryObject.totalTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalTimeOverdue) {
              summaryObject.summaryCardThreeValue = summaryObject.totalTimeOverdue + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalTimeOverdue) {
              summaryObject.summaryCardFourValue = summaryObject.totalTimeOverdue + ' hrs';
            }

            summaryObject.Statistic = summaryObject.totalTimeOverdue + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalCostOverdue ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalCostOverdue ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalCostOverdue ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalCostOverdue) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.totalCostOverdue) {
              summaryObject.summaryCardOneValue = '$' + summaryObject.totalCostOverdue;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.totalCostOverdue) {
              summaryObject.summaryCardTwoValue = '$' + summaryObject.totalCostOverdue;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.totalCostOverdue) {
              summaryObject.summaryCardThreeValue = '$' + summaryObject.totalCostOverdue;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.totalCostOverdue) {
              summaryObject.summaryCardFourValue = '$' + summaryObject.totalCostOverdue;
            }

            summaryObject.Statistic = '$' + summaryObject.totalCostOverdue;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageBudgetHours ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageBudgetHours ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageBudgetHours ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageBudgetHours) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageBudgetHours) {
              summaryObject.summaryCardOneValue = summaryObject.averageBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageBudgetHours) {
              summaryObject.summaryCardTwoValue = summaryObject.averageBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageBudgetHours) {
              summaryObject.summaryCardThreeValue = summaryObject.averageBudgetHours + ' hrs';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageBudgetHours) {
              summaryObject.summaryCardFourValue = summaryObject.averageBudgetHours + ' hrs';
            }

            summaryObject.Statistic = summaryObject.averageBudgetHours + ' hrs';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageBudgetCost ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageBudgetCost ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageBudgetCost ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageBudgetCost) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageBudgetCost) {
              summaryObject.summaryCardOneValue = '$' + summaryObject.averageBudgetCost;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageBudgetCost) {
              summaryObject.summaryCardTwoValue = '$' + summaryObject.averageBudgetCost;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageBudgetCost) {
              summaryObject.summaryCardThreeValue = '$' + summaryObject.averageBudgetCost;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageBudgetCost) {
              summaryObject.summaryCardFourValue = '$' + summaryObject.averageBudgetCost;
            }

            summaryObject.Statistic = '$' + summaryObject.averageBudgetCost;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTotalCost ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTotalCost ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTotalCost ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTotalCost) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.averageTotalCost) {
              summaryObject.summaryCardOneValue = '$' + summaryObject.averageTotalCost;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.averageTotalCost) {
              summaryObject.summaryCardTwoValue = '$' + summaryObject.averageTotalCost;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.averageTotalCost) {
              summaryObject.summaryCardThreeValue = '$' + summaryObject.averageTotalCost;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.averageTotalCost) {
              summaryObject.summaryCardFourValue = '$' + summaryObject.averageTotalCost;
            }

            summaryObject.Statistic = '$' + summaryObject.averageTotalCost;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInBudgetRatio ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInBudgetRatio ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInBudgetRatio ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInBudgetRatio) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInBudgetRatio) {
              summaryObject.summaryCardOneValue = (summaryObject.completedInBudgetRatio + 100) + '%';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInBudgetRatio) {
              summaryObject.summaryCardTwoValue = (summaryObject.completedInBudgetRatio + 100) + '%';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInBudgetRatio) {
              summaryObject.summaryCardThreeValue = (summaryObject.completedInBudgetRatio + 100) + '%';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInBudgetRatio) {
              summaryObject.summaryCardFourValue = (summaryObject.completedInBudgetRatio + 100) + '%';
            }

            summaryObject.Statistic = (summaryObject.completedInBudgetRatio + 100) + '%';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInTime ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInTime ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInTime ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInTime) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInTime) {
              summaryObject.summaryCardOneValue = summaryObject.completedInTime;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInTime) {
              summaryObject.summaryCardTwoValue = summaryObject.completedInTime;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInTime) {
              summaryObject.summaryCardThreeValue = summaryObject.completedInTime;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInTime) {
              summaryObject.summaryCardFourValue = summaryObject.completedInTime;
            }

            summaryObject.Statistic = summaryObject.completedInTime;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInBudget ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInBudget ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInBudget ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInBudget) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completedInBudget) {
              summaryObject.summaryCardOneValue = summaryObject.completedInBudget;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completedInBudget) {
              summaryObject.summaryCardTwoValue = summaryObject.completedInBudget;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completedInBudget) {
              summaryObject.summaryCardThreeValue = summaryObject.completedInBudget;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completedInBudget) {
              summaryObject.summaryCardFourValue = summaryObject.completedInBudget;
            }

            summaryObject.Statistic = summaryObject.completedInBudget;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.slaOverdue ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.slaOverdue ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.slaOverdue ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.slaOverdue) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.slaOverdue) {
              summaryObject.summaryCardOneValue = summaryObject.slaOverdue;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.slaOverdue) {
              summaryObject.summaryCardTwoValue = summaryObject.slaOverdue;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.slaOverdue) {
              summaryObject.summaryCardThreeValue = summaryObject.slaOverdue;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.slaOverdue) {
              summaryObject.summaryCardFourValue = summaryObject.slaOverdue;
            }

            summaryObject.Statistic = summaryObject.slaOverdue;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.myTickets ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.myTickets ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.myTickets ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.myTickets) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.myTickets) {
              summaryObject.summaryCardOneValue = summaryObject.myTickets;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.myTickets) {
              summaryObject.summaryCardTwoValue = summaryObject.myTickets;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.myTickets) {
              summaryObject.summaryCardThreeValue = summaryObject.myTickets;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.myTickets) {
              summaryObject.summaryCardFourValue = summaryObject.myTickets;
            }

            summaryObject.Statistic = summaryObject.myTickets;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.beginningStates ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.beginningStates ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.beginningStates ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.beginningStates) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.beginningStates) {
              summaryObject.summaryCardOneValue = summaryObject.beginningStates;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.beginningStates) {
              summaryObject.summaryCardTwoValue = summaryObject.beginningStates;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.beginningStates) {
              summaryObject.summaryCardThreeValue = summaryObject.beginningStates;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.beginningStates) {
              summaryObject.summaryCardFourValue = summaryObject.beginningStates;
            }

            summaryObject.Statistic = summaryObject.beginningStates;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.workflowStates ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.workflowStates ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.workflowStates ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.workflowStates) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.workflowStates) {
              summaryObject.summaryCardOneValue = summaryObject.workflowStates;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.workflowStates) {
              summaryObject.summaryCardTwoValue = summaryObject.workflowStates;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.workflowStates) {
              summaryObject.summaryCardThreeValue = summaryObject.workflowStates;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.workflowStates) {
              summaryObject.summaryCardFourValue = summaryObject.workflowStates;
            }

            summaryObject.Statistic = summaryObject.workflowStates;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.endState ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.endState ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.endState ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.endState) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.endState) {
              summaryObject.summaryCardOneValue = summaryObject.endState;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.endState) {
              summaryObject.summaryCardTwoValue = summaryObject.endState;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.endState) {
              summaryObject.summaryCardThreeValue = summaryObject.endState;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.endState) {
              summaryObject.summaryCardFourValue = summaryObject.endState;
            }

            summaryObject.Statistic = summaryObject.endState;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.terminalState ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.terminalState ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.terminalState ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.terminalState) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.terminalState) {
              summaryObject.summaryCardOneValue = summaryObject.terminalState;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.terminalState) {
              summaryObject.summaryCardTwoValue = summaryObject.terminalState;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.terminalState) {
              summaryObject.summaryCardThreeValue = summaryObject.terminalState;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.terminalState) {
              summaryObject.summaryCardFourValue = summaryObject.terminalState;
            }

            summaryObject.Statistic = summaryObject.terminalState;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.userOwners ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.userOwners ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.userOwners ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.userOwners) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.userOwners) {
              summaryObject.summaryCardOneValue = summaryObject.userOwners;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.userOwners) {
              summaryObject.summaryCardTwoValue = summaryObject.userOwners;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.userOwners) {
              summaryObject.summaryCardThreeValue = summaryObject.userOwners;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.userOwners) {
              summaryObject.summaryCardFourValue = summaryObject.userOwners;
            }

            summaryObject.Statistic = summaryObject.userOwners;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.peopleOwners ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.peopleOwners ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.peopleOwners ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.peopleOwners) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.peopleOwners) {
              summaryObject.summaryCardOneValue = summaryObject.peopleOwners;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.peopleOwners) {
              summaryObject.summaryCardTwoValue = summaryObject.peopleOwners;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.peopleOwners) {
              summaryObject.summaryCardThreeValue = summaryObject.peopleOwners;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.peopleOwners) {
              summaryObject.summaryCardFourValue = summaryObject.peopleOwners;
            }

            summaryObject.Statistic = summaryObject.peopleOwners;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.teamOwners ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.teamOwners ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.teamOwners ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.teamOwners) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.teamOwners) {
              summaryObject.summaryCardOneValue = summaryObject.teamOwners;
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.teamOwners) {
              summaryObject.summaryCardTwoValue = summaryObject.teamOwners;
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.teamOwners) {
              summaryObject.summaryCardThreeValue = summaryObject.teamOwners;
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.teamOwners) {
              summaryObject.summaryCardFourValue = summaryObject.teamOwners;
            }

            summaryObject.Statistic = summaryObject.teamOwners;
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completePercentage ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completePercentage ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completePercentage ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completePercentage) {



            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.completePercentage) {
              summaryObject.summaryCardOneValue = (summaryObject.completePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.completePercentage) {
              summaryObject.summaryCardTwoValue = (summaryObject.completePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.completePercentage) {
              summaryObject.summaryCardThreeValue = (summaryObject.completePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.completePercentage) {
              summaryObject.summaryCardFourValue = (summaryObject.completePercentage * 100).toFixed(0).toString() + '%';
            }

            summaryObject.Statistic = (summaryObject.completePercentage * 100).toFixed(0).toString() + '%';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.inQueuePercentage ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.inQueuePercentage ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.inQueuePercentage ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {

            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.inQueuePercentage) {
              summaryObject.summaryCardOneValue = (summaryObject.inQueuePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.inQueuePercentage) {
              summaryObject.summaryCardTwoValue = (summaryObject.inQueuePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.inQueuePercentage) {
              summaryObject.summaryCardThreeValue = (summaryObject.inQueuePercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.inQueuePercentage) {
              summaryObject.summaryCardFourValue = (summaryObject.inQueuePercentage * 100).toFixed(0).toString() + '%';
            }

            summaryObject.Statistic = (summaryObject.inQueuePercentage * 100).toFixed(0).toString() + '%';
          }
          if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.inProgressPercentage ||
            summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.inProgressPercentage ||
            summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.inProgressPercentage ||
            summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {



            //Set Statistic Value based on the Card Set
            if (summaryObject.summaryCardOneSourceType === SummaryReportSourceTypes.inProgressPercentage) {
              summaryObject.summaryCardOneValue = (summaryObject.inProgressPercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardTwoSourceType === SummaryReportSourceTypes.inProgressPercentage) {
              summaryObject.summaryCardTwoValue = (summaryObject.inProgressPercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardThreeSourceType === SummaryReportSourceTypes.inProgressPercentage) {
              summaryObject.summaryCardThreeValue = (summaryObject.inProgressPercentage * 100).toFixed(0).toString() + '%';
            }
            if (summaryObject.summaryCardFourSourceType === SummaryReportSourceTypes.inProgressPercentage) {
              summaryObject.summaryCardFourValue = (summaryObject.inProgressPercentage * 100).toFixed(0).toString() + '%';
            }

            summaryObject.Statistic = (summaryObject.inProgressPercentag * 100).toFixed(0).toString() + '%';
          }
        }


        //-----------------------------------------------------
        // Set Summaries
        //-----------------------------------------------------

        this.entitySummaryCondensed.items.push(summaryObject);
        this.entitySummaryCondensed.totalCount = this.entitySummaryCondensed.totalCount + 1;

        var entitySummary_dataItem: Data =
        {
          "type": typeItems.name === undefined ? '-' : typeItems.name,
          "title": typeItems.title === undefined ? '-' : typeItems.title,
          "total": this.entitySummaryCondensed.totalCount === undefined ? 0 : this.entitySummaryCondensed.totalCount,
          "beginning": summaryObject.beginningStates === undefined ? 0 : summaryObject.beginningStates,
          "workflow": summaryObject.workflowStates === undefined ? 0 : summaryObject.workflowStates,
          "end": summaryObject.endState === undefined ? 0 : summaryObject.endState,
          "terminal": summaryObject.terminalState === undefined ? 0 : summaryObject.terminalState,
          "owned": summaryObject.owned === undefined ? 0 : summaryObject.owned,

          "ptype": typeItems.name === undefined ? '-' : typeItems.name,
          "ptitle": typeItems.title === undefined ? '-' : typeItems.title,
          "ptotal": this.entitySummaryCondensed.totalCount === undefined ? 0 : this.entitySummaryCondensed.totalCount,
          "pbeginning": summaryObject.beginningStates === undefined ? 0 : summaryObject.beginningStates,
          "pworkflow": summaryObject.workflowStates === undefined ? 0 : summaryObject.workflowStates,
          "pend": summaryObject.endState === undefined ? 0 : summaryObject.endState,
          "pterminal": summaryObject.terminalState === undefined ? 0 : summaryObject.terminalState,
          "powned": summaryObject.owned === undefined ? 0 : summaryObject.owned,
        }

        this.entityCondensed_dataExportTableData.push(entitySummary_dataItem);

      }
    });


    this.entityCondensed_dataExportTable = {
      fields: this.entityCondensed_dataTableFields,
      "meta": this.entityCondensed_dataTableMeta,
      "data": this.entityCondensed_dataExportTableData,
      "totalData": this.entityCondensed_dataExportTableTotalData,
    }

    this.entityCondensed_dataExportTable_Analytic = {
      fields: this.entityCondensed_dataTableFields_Analytic,
      sortParams: this.entityCondensed_sortParams,
      "meta": this.entityCondensed_dataTableMeta,
      "data": this.entityCondensed_dataExportTableData,
      "totalData": this.entityCondensed_dataExportTableTotalData,
    }
    

    

  }

  @action
  async getOptionSummary(keyword: any) {

    this.entityOptionSummary =
    {
      totalCount: 0,
      items: []
    }

    this.option_dataExportTableData = [];
    this.option_dataExportTableTotalData = [];

    let optionSummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });
    if (optionSummary !== undefined) {
      this.entityOptions =
      {
        totalCount: optionSummary.options.length,
        items: optionSummary.options
      }
    }

    // Loop Retrieved States
    optionSummary.options.map((x: any) => {


      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "optionType": x.optionType === undefined ? 'Unknown' : x.optionType,
        "boundState": x.boundState === undefined ? 'Unknown' : x.boundState,
        "boundRole": x.boundRole === undefined ? 'Unknown' : x.boundRole,
        "boundPerson": x.boundPerson === undefined ? 'Unknown' : x.boundPerson,
        "boundTeam": x.boundTeam === undefined ? 'Unknown' : x.boundTeam,
        "inputType": x.inputType === undefined ? 'Unknown' : x.inputType,

      }



      return this.option_dataExportTableData.push(dataItem);

    });

    this.option_dataExportTable = {
      fields: this.option_dataTableFields,
      "meta": this.option_dataTableMeta,
      "data": this.option_dataExportTableData,
      "totalData": this.option_dataExportTableTotalData,
    }

    if (optionSummary.options.length === 0) {
      this.entityOptionSummary =
      {
        totalCount: -1,
        items: optionSummary.options
      }
    }
    else {
      this.entityOptionSummary =
      {
        totalCount: optionSummary.options.length,
        items: optionSummary.options
      }
    }
  }


  @action
  async getAllOptions() {

    this.entityOptions = {
      totalCount: 0,
      items: []
    }

    let result = await inventoryService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    this.optionsList =
    {
      totalCount: result.options.length,
      items: result.options
    }

  }

  @action
  async updateOptionsList(updateMemberList: any) {
    this.entityOptions.items.push(updateMemberList);
  }

  @action
  async deleteOptionsMember(recordID: number) {
    this.entityOptions.items = this.entityOptions.items.filter((x: UpdateInventoryOptionList) => x.id !== recordID);

  }

  @action
  async deleteOption(input: EntityDto) {

    this.entityOptionSummary.items = this.entityOptionSummary.items.filter((x: any) => x.id !== input.id);

    await this.getOption(input);

    this.entityDelete.options.push({
      id: input.id,
      name: '',
      title: '',
      profileImageID: 0,
      optionType: 0,
      boundPerson: 0,
      boundState: 0,
      boundRole: 0,
      boundTeam: 0,
      action: 0,
      module: 0,
      sourceType: 0,
      sourceItem: 0,
      targetType: 0,
      targetItem: 0,
      valueRules: 0,

      stageCondition: 0,
      newStage: 0,
      sourceStage: 0,
      stageConditionValue: 0
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Option;
    this.entityDelete.itemType = itemTypes.Inventory;

    await inventoryService.delete(this.entityDelete);
    this.getAllOptions();
  }

  @action
  async unDeleteOption() {
    await this.initUpdateObject(RelatedEntityTypes.Option, itemTypes.Inventory, 0, 100);
    this.entityCreate.options.push(this.entityReturn.options[0]);
    await this.createEntity();
  }

  @action
  async getOption(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Option;

    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.Option,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

  }

  //------------------------------------

  @action
  async getAllTypes() {

    this.entityTypes = {
      totalCount: 0,
      items: []
    }

    let result = await inventoryService.get({
      entityType: RelatedEntityTypes.Type,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });



    this.typesList =
    {
      totalCount: result.types.length,
      items: result.types
    }

  }

  @action
  async getTypeSummary(keyword: any) {

    this.entityTypeSummary =
    {
      totalCount: 0,
      items: []
    }

    this.type_dataExportTableData = [];
    this.type_dataExportTableTotalData = [];

    let typeSummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.Type,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    if (typeSummary !== undefined) {
      this.entityTypes =
      {
        totalCount: typeSummary.types.length,
        items: typeSummary.types
      }
    }


    // Loop Retrieved States
    typeSummary.types.map((x: any) => {

      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,

      }


      return this.type_dataExportTableData.push(dataItem);

    });

    this.type_dataExportTable = {
      fields: this.type_dataTableFields,
      "meta": this.type_dataTableMeta,
      "data": this.type_dataExportTableData,
      "totalData": this.type_dataExportTableTotalData,
    }



    if (typeSummary.options.length === 0) {
      this.entityTypeSummary =
      {
        totalCount: -1,
        items: typeSummary.types
      }
    }
    else {
      this.entityTypeSummary =
      {
        totalCount: typeSummary.types.length,
        items: typeSummary.types
      }
    }


  }


  @action
  async updateTypesList(updateMemberList: UpdateInventoryTypeList) {
    this.entityTypes.items.push(updateMemberList);
  }

  @action
  async deleteTypesMember(recordID: number) {
    this.entityTypes.items = this.entityTypes.items.filter((x: UpdateInventoryTypeList) => x.id !== recordID);
  }

  @action
  async deleteType(input: EntityDto) {
    this.entityTypeSummary.items = this.entityTypeSummary.items.filter((x: any) => x.id !== input.id);
    //await this.getType(input); 

    this.entityDelete.types.push({
      id: input.id,
      name: '',
      title: '',
      relatedStates: [],
      relatedItems: [],
      profileImageID: 0,
      relatedItemsVerbose: [],
      relatedStatesVerbose: [],
      showSummaryCard: true,
      showListCard: true,
      summaryCardType: 0,
      summaryReportOneType: 0,
      summaryReportTwoType: 0,
      summaryReportOneSourceType: 0,
      summaryReportTwoSourceType: 0,
      summaryReportOneSourceId: 0,
      summaryReportTwoSourceId: 0,
      summaryReportOneTitle: '',
      summaryReportTwoTitle: '',
      summaryReportOneSource: 0,
      summaryReportTwoSource: 0,
      listCardType: 0,
      listReportOneType: 0,
      listReportTwoType: 0,
      listReportThreeType: 0,
      listReportOneTitle: '',
      listReportTwoTitle: '',
      listReportThreeTitle: '',
      summaryCardOneTitle: '',
      summaryCardTwoTitle: '',
      summaryCardThreeTitle: '',
      summaryCardFourTitle: '',
      summaryCardOneType: 0,
      summaryCardTwoType: 0,
      summaryCardThreeType: 0,
      summaryCardFourType: 0,
      summaryCardOneSourceType: 0,
      summaryCardTwoSourceType: 0,
      summaryCardThreeSourceType: 0,
      summaryCardFourSourceType: 0,
      summaryCardOneSourceId: 0,
      summaryCardTwoSourceId: 0,
      summaryCardThreeSourceId: 0,
      summaryCardFourSourceId: 0,

    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Type;
    this.entityDelete.itemType = itemTypes.Inventory;
    await inventoryService.delete(this.entityDelete);
    this.getAllTypes();
  }

  @action
  async unDeleteType() {
    await this.initUpdateObject(RelatedEntityTypes.Type, itemTypes.Inventory, 0, 100);
    this.entityCreate.types.push(this.entityReturn.types[0]);
    await this.createEntity();
  }

  @action
  async getType(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Type;
    ////this.getFiles();

    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.Type,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });



  }

  async getTypeOptions() {

    //Set States
    this.entityItems.items = this.entityReturn.types[0].relatedItemsVerbose;
    this.entityItems.totalCount = this.entityStates.items.length;

    //Set Items
    this.entityStates.items = this.entityReturn.types[0].relatedStatesVerbose;
    this.entityStates.totalCount = this.entityStates.items.length;

  }

  //------------------------------------

  @action
  async getAllTemplates() {

    this.templateItems = {
      totalCount: 0,
      items: []
    }

    let result = await inventoryService.get({
      entityType: RelatedEntityTypes.Template,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });



    this.templatesList =
    {
      totalCount: result.templates.length,
      items: result.templates
    }

  }

  @action
  async getTemplateSummary(keyword: any) {

    this.entityTemplateSummary =
    {
      totalCount: 0,
      items: []
    }

    this.template_dataExportTableData = [];
    this.template_dataExportTableTotalData = [];

    let templateSummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.Template,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });


    if (templateSummary !== undefined) {
      this.entityTemplates =
      {
        totalCount: templateSummary.templates.length,
        items: templateSummary.templates
      }
    }
    // Loop Retrieved States
    templateSummary.templates.map((x: any) => {


      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,

      }



      return this.template_dataExportTableData.push(dataItem);

    });

    this.template_dataExportTable = {
      fields: this.template_dataTableFields,
      "meta": this.template_dataTableMeta,
      "data": this.template_dataExportTableData,
      "totalData": this.template_dataExportTableTotalData,
    }


    if (templateSummary.templates.length === 0) {
      this.entityTemplateSummary =
      {
        totalCount: -1,
        items: templateSummary.templates
      }
    }
    else {
      this.entityTemplateSummary =
      {
        totalCount: templateSummary.templates.length,
        items: templateSummary.templates
      }
    }


  }


  @action
  async getTemplateItemSummary(keyword: any) {

    this.templateItems =
    {
      totalCount: 0,
      items: []
    }
  }

  @action
  async updateTemplatesList(updateMemberList: UpdateInventoryTemplateList) {
    this.entityTemplates.items.push(updateMemberList);
  }

  @action
  async deleteTemplatesMember(recordID: number) {
    this.entityTemplates.items = this.entityTemplates.items.filter((x: UpdateInventoryTemplateList) => x.id !== recordID);
  }

  @action
  async deleteTemplate(input: EntityDto) {
    this.entityTemplateSummary.items = this.entityTemplateSummary.items.filter((x: any) => x.id !== input.id);


    this.entityDelete.templates.push({
      id: input.id,
      name: '',
      title: '',
      typeID: 0,
      profileImageID: 0,
      defaultState: 0,
      globalSecurity: false,
      rolesRequired: false,
      limitedScope: false,
      relatedValues: [],
      enablePeople: false,
      enableTeams: false,
      enableMenus: false,
      enableCommunication: false,
      enableDocuments: false,
      enableInventory: false,
      enablePayments: false,
      enableReports: false,
      enableSchedules: false,
      enableWorkOrders: false,
      enableIntegrations: false,
      owner: 0,
      ownerType: 0,
      securityType: 0,

      enableScanbot: false,
      scanbotTitle: '',
      scanbotKey: '',
      scanbotSecret: '',

      peopleTitle: '',
      teamsTitle: '',
      menusTitle: '',
      communicationTitle: '',
      documentsTitle: '',
      inventoryTitle: '',
      paymentsTitle: '',
      reportsTitle: '',
      schedulesTitle: '',
      workOrdersTitle: '',
      integrationsTitle: '',

      enableSignatures: false,
      enablePDF: false,
      signaturesTitle: '',
      pdfTitle: '', 
      pdfx: 0,
      pdfy: 0,
      pdfName: false,
      pdfDate: false, 
      enableUsers: false,
      usersTitle: '',
      enableDocEditor: false,
      docEditorTitle: '',
      allowDocEditorEdit: false,
      makeDocEditoTitle: false,
      hideBasicInfo: false,
      defaultName: '',
      defaultTitle: '',
      docEditBody: '',
      pdfBody: '',
      nameTitle: '',
      descriptionTitle: '',
      ownerTypeTitle: '',
      ownerTitle: '',

    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Template;
    this.entityDelete.itemType = itemTypes.Inventory;
    await inventoryService.delete(this.entityDelete);
  }

  @action
  async unDeleteTemplate() {
    await this.initUpdateObject(RelatedEntityTypes.Template, itemTypes.Inventory, 0, 100);
    this.entityCreate.templates.push(this.entityReturn.templates[0]);
    await this.createEntity();
  }

  @action
  async getTemplate(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Template;

    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.Template,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });


  }

  @action
  async updateTemplateRecordList(updateMemberList: any) {
    this.templateRecordCache.push(updateMemberList);
  }

  @action
  async deleteTemplateRecordMember(recordID: number) {
    this.templateRecordCache = this.templateRecordCache.filter((x: TemplateRecordDto) => x.id !== recordID);
  }

  @action
  async updateValueList(updateMemberList: any) {
    this.valuesCache.push(updateMemberList);
  }

  @action
  async deleteValueMember(recordID: any) {
    this.valuesCache = this.valuesCache.filter((x: any) => x.id !== recordID);
  }

  //------------------------------------

  @action
  async getAllStates() {

    this.entityStates = {
      totalCount: 0,
      items: []
    }

    let result = await inventoryService.get({
      entityType: RelatedEntityTypes.State,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    this.statesList =
    {
      totalCount: result.states.length,
      items: result.states
    }

  }

  @action
  async getStateSummary(keyword: any) {

    this.entityStateSummary =
    {
      totalCount: 0,
      items: []
    }

    this.state_dataExportTableData = [];
    this.state_dataExportTableTotalData = [];


    let stateSummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.State,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    if (stateSummary !== undefined) {
      this.entityStates =
      {
        totalCount: stateSummary.states.length,
        items: stateSummary.states
      }
    }

    // Loop Retrieved States
    stateSummary.states.map((x: any) => {


      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "displayType": x.displayType === undefined ? 'Unknown' : x.displayType,
        "forwardText": x.forwardText === undefined ? 'Unknown' : x.forwardText,
        "revertText": x.revertText === undefined ? 'Unknown' : x.revertText,
        "allowSkipTo": x.allowSkipTo === undefined ? 'Unknown' : x.allowSkipTo,
        "allowRevert": x.allowRevert === undefined ? 'Unknown' : x.allowRevert,
        "nextActionOnly": x.nextActionOnly === undefined ? 'Unknown' : x.nextActionOnly,
        "lastActionOnly": x.lastActionOnly === undefined ? 'Unknown' : x.title,
        "noActions": x.noActions === undefined ? 'Unknown' : x.noActions,
      }



      return this.state_dataExportTableData.push(dataItem);

    });

    this.state_dataExportTable = {
      fields: this.state_dataTableFields,
      "meta": this.state_dataTableMeta,
      "data": this.state_dataExportTableData,
      "totalData": this.state_dataExportTableTotalData,
    }


    if (stateSummary.states.length === 0) {
      this.entityStateSummary =
      {
        totalCount: -1,
        items: stateSummary.states
      }
    }
    else {
      this.entityStateSummary =
      {
        totalCount: stateSummary.states.length,
        items: stateSummary.states
      }
    }
  }

  @action
  async updateStatesList(updateMemberList: UpdateInventoryStateList) {
    this.typeStatesEdit.items.push(updateMemberList);
  }

  @action
  async deleteStatesMember(recordID: number) {
    this.typeStatesEdit.items = this.typeStatesEdit.items.filter((x: UpdateInventoryStateList) => x.id !== recordID);
  }

  @action
  async deleteState(input: EntityDto) {
    this.entityStateSummary.items = this.entityStateSummary.items.filter((x: any) => x.id !== input.id);
    await this.getState(input);
    this.entityDelete.states.push(
      {
        id: input.id,
        name: '',
        title: '',
        profileImageID: 0,
        displayType: 0,
        forwardText: '',
        revertText: '',
        restricted: false,
        allowSkipTo: false,
        allowRevert: false,
        nextActionOnly: false,
        lastActionOnly: false,
        noActions: false,
        type: 0,
        sLADays: 0,
        sLAHours: 0,
        sLAMinutes: 0,
        isSLA: false,
        sLAType: 0,
        sLANotificationType: 0,
        forwardTitle: '',
        revertTitle: '',
      }
    );

    this.entityDelete.relatedEntityType = RelatedEntityTypes.State;
    this.entityDelete.itemType = itemTypes.Inventory;

    await inventoryService.delete(this.entityDelete);
    this.getAllStates();
  }

  @action
  async unDeleteState() {
    await this.initUpdateObject(RelatedEntityTypes.State, itemTypes.Inventory, 0, 100);
    this.entityCreate.states.push(this.entityReturn.states[0]);
    await this.createEntity();
  }

  @action
  async getState(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.State;

    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.State,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

  }

  //------------------------------------

  @action
  async getAllItems() {
    this.entityItems = {
      totalCount: 0,
      items: []
    }

    let result = await inventoryService.get({
      entityType: RelatedEntityTypes.Item,
      entityID: 0,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });


    this.itemsList =
    {
      totalCount: result.items.length,
      items: result.items
    }

  }

  @action
  async getItemSummary(keyword: any) {

    this.entityItemSummary =
    {
      totalCount: 0,
      items: []
    }

    this.item_dataExportTableData = [];
    this.item_dataExportTableTotalData = [];

    let itemSummary = await inventoryService.getSummaryView({
      entityType: RelatedEntityTypes.Item,
      entityID: 0,
      filter: keyword,
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    if (itemSummary !== undefined) {
      this.entityItems =
      {
        totalCount: itemSummary.items.length,
        items: itemSummary.items
      }
    }

    // Loop Retrieved States
    itemSummary.items.map((x: any) => {


      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "itemType": x.itemType === undefined ? 'Unknown' : x.itemType,
        "inputType": x.inputType === undefined ? 'Unknown' : x.inputType,
        "optionType": x.optionType === undefined ? 'Unknown' : x.optionType,
        "selectionType": x.selectionType === undefined ? 'Unknown' : x.selectionType,
      }



      return this.item_dataExportTableData.push(dataItem);

    });

    this.item_dataExportTable = {
      fields: this.item_dataTableFields,
      "meta": this.item_dataTableMeta,
      "data": this.item_dataExportTableData,
      "totalData": this.item_dataExportTableTotalData,
    }


    if (itemSummary.items.length === 0) {
      this.entityItemSummary =
      {
        totalCount: -1,
        items: itemSummary.items
      }
    }
    else {
      this.entityItemSummary =
      {
        totalCount: itemSummary.items.length,
        items: itemSummary.items
      }
    }

  }

  @action
  async updateItemsList(updateMemberList: any) {
    this.typeItemsEdit.items.push(updateMemberList);
  }

  @action
  async deleteItemsMember(recordID: number) {
    this.typeItemsEdit.items = this.typeItemsEdit.items.filter((x: UpdateInventoryItemList) => x.id !== recordID);
  }

  @action
  async deleteItem(input: EntityDto) {
    this.entityItemSummary.items = this.entityItemSummary.items.filter((x: any) => x.id !== input.id);


    await this.getItem(input);
    this.entityDelete.items.push({
      id: input.id,
      name: '',
      title: '',
      profileImageID: 0,
      itemType: 0,
      inputType: 0,
      relatedOptions: [],
      relatedOptionsVerbose: [],
      optionType: 0,
      selectionType: 0,
    });

    this.entityDelete.relatedEntityType = RelatedEntityTypes.Item;
    this.entityDelete.itemType = itemTypes.Inventory;

    await inventoryService.delete(this.entityDelete);
    this.getAllItems();
  }

  @action
  async unDeleteItem() {
    await this.initUpdateObject(RelatedEntityTypes.Item, itemTypes.Inventory, 0, 100);
    this.entityCreate.items.push(this.entityReturn.items[0]);
    await this.createEntity();
  }


  @action
  async getItem(input: EntityDto) {

    this.entityID = input.id;
    this.entityType = RelatedEntityTypes.Item;

    //Get Core Item
    this.entityReturn = await inventoryService.get({
      entityType: RelatedEntityTypes.Item,
      entityID: input.id,
      filter: '',
      listFilter: false,
      availableFilter: false,
      entityMaxResultCount: this.entity_MaxResultCount,
      entitySkipCount: this.entity_SkipCount,
      actionMaxResultCount: this.action_MaxResultCount,
      actionSkipCount: this.action_SkipCount
    });

    this.itemOptionsCache = {
      totalCount: 0,
      items: this.entityReturn.items[0].relatedOptionsVerbose
    }

  }

  async getItemOptions() {
    //Set Options
    this.entityOptions.items = this.entityReturn.items[0].relatedOptionsVerbose;
    this.entityOptions.totalCount = this.entityOptions.items.length;
  }

  //--------------------------------------------------------------------------


  @action
  async getExtenalSummary(module: any, entityType: any, target: any = 0) {

    if (module === itemTypes.Communication) {
      if (isGranted(PermissionNames.Communication) === true) {

        let entitySummary = await communicationService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });


        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Integrations) {
      if (isGranted(PermissionNames.Integrations) === true) {
        let entitySummary = await integrationService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    } else if (module === itemTypes.Inventory) {
      if (isGranted(PermissionNames.Inventory) === true) {
        let entitySummary = await inventoryService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Payments) {
      if (isGranted(PermissionNames.Payments) === true) {
        let entitySummary = await paymentService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Persons) {
      if (isGranted(PermissionNames.People) === true) {
        let entitySummary = await peopleService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          this.peopleSummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Reports) {
      if (isGranted(PermissionNames.Reports) === true) {
        let entitySummary = await reportService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Schedules) {
      if (isGranted(PermissionNames.Scheduling) === true) {
        let entitySummary = await schedulingService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.Teams) {
      if (isGranted(PermissionNames.Teams) === true) {
        let entitySummary = await teamService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          this.templateSummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }
    else if (module === itemTypes.WorkOrders) {
      if (isGranted(PermissionNames.WorkOrder) === true) {
        let entitySummary = await workOrderService.getSummaryView({
          entityType: entityType,
          entityID: target,
          filter: '',
          listFilter: false,
          availableFilter: false,
          entityMaxResultCount: this.entity_MaxResultCount,
          entitySkipCount: this.entity_SkipCount,
          actionMaxResultCount: this.action_MaxResultCount,
          actionSkipCount: this.action_SkipCount
        });

        if (entityType === RelatedEntityTypes.Entity) {
          this.entitySummaryList = entitySummary.entities;
          return entitySummary.entities;
        }
        else if (entityType === RelatedEntityTypes.Type) {
          this.typeSummaryList = entitySummary.types;
          return entitySummary.types;
        }
        else if (entityType === RelatedEntityTypes.Template) {
          this.templateSummaryList = entitySummary.templates;
          return entitySummary.templates;
        }
        else if (entityType === RelatedEntityTypes.Fields) {
          this.fieldSummaryList = entitySummary.items;
          return entitySummary.items;
        }
        else if (entityType === RelatedEntityTypes.States) {
          this.statesSummaryList = entitySummary.states;
          return entitySummary.states;
        }
      }
    }

    return;
  }

  @action
  async getActionSummary(listType: number, moduleFilter: number = -1, entityFilter: number = -1) {

    // var actionAsk = {
    //  entityType: listType,
    //  relatedEntityType: moduleFilter,
    //  entityID: entityFilter,
    //  filter: '',
    //   moduleType: moduleFilter,
    //  listFilter: false,
    //  availableFilter: false
    //}

    this.actionSummaryReturn = {
      totalCount: 0,
      items: [],
    }

    //let result = await peopleService.getActionSummary(actionAsk);
 





    this.entityActivity_dataExportTableData = [];

    this.actionSummaryReturn.items.map((x: any) => {

      //------------------------------------------------------------------
      // Set DataView Table Data   
      //------------------------------------------------------------------
      var entity_dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "actionType": x.actionType === undefined ? 'Unknown' : x.actionType,
        "actionID": x.actionID === undefined ? 'Unknown' : x.actionID,
        "entityName": x.entityName === undefined ? 'Unknown' : x.entityName,
        "entityID": x.entityID === undefined ? 'Unknown' : x.entityID,
        "previousStateName": x.temppreviousStateNamelateID === undefined ? 'Unknown' : x.previousStateName,
        "previousStateAction": x.previousStateAction === undefined ? 'Unknown' : x.previousStateAction,
        "previousStateID": x.previousStateID === undefined ? 'Unknown' : x.previousStateID,
        "newStateName": x.newStateName === undefined ? 'Unknown' : x.newStateName,
        "newStateAction": x.newStateAction === undefined ? 'Unknown' : x.newStateAction,
        "newStateID": x.newStateID === undefined ? 'Unknown' : x.newStateID,
        "itemName": x.itemName === undefined ? 'Unknown' : x.itemName,
        "itemID": x.itemID === undefined ? 'Unknown' : x.itemID,
        "itemValue": x.itemValue === undefined ? 'Unknown' : x.itemValue,
        "notes": x.notes === undefined ? 'Unknown' : x.notes,
        "externalID": x.externalID === undefined ? 'Unknown' : x.externalID,
        "isRestricted": x.isRestricted === undefined ? 'Unknown' : x.isRestricted,
        "createdTime": x.createdTime === undefined ? 'Unknown' : x.createdTime,
        "creatorID": x.creatorID === undefined ? 'Unknown' : x.creatorID,
        "ownerID": x.ownerID === undefined ? 'Unknown' : x.ownerID,
        "relatedEntityType": x.relatedEntityType === undefined ? 'Unknown' : x.relatedEntityType,
        "relatedEntityID": x.relatedEntityID === undefined ? 'Unknown' : x.relatedEntityID,
        "linkedEntityType": x.linkedEntityType === undefined ? 'Unknown' : x.linkedEntityType,
        "linkedEntityID": x.linkedEntityID === undefined ? 'Unknown' : x.linkedEntityID,
      }

      this.entityActivity_dataExportTableData.push(entity_dataItem);

    });


    this.entityActivity_dataExportTable = {
      fields: this.entityActivity_dataTableFields,
      "meta": this.entityActivity_dataTableMeta,
      "data": this.entityActivity_dataExportTableData,
      "totalData": this.entityActivity_dataExportTableTotalData,
    }


    this.entityActivity_dataExportTable_Analytic = {
      fields: this.entityActivity_dataTableFields_Analytic, 
      "meta": this.entityActivity_dataTableMeta,
      "data": this.entityActivity_dataExportTableData,
      "totalData": this.entityActivity_dataExportTableTotalData,
      sortParams: this.entityActivity_sortParams
    }


    return;
  }


}

export default InventoryStore;
