export const DashboardItemTypes = { 

    MetaText: -12,
    VideoCard: -11,  
    WebContent: -10, 
    Calendar: -9,
    Comments: -8, 
    ProgressBar: -7, 
    Statistic: -6,
    Countdown: -5,
    Image: -4,
    ImageCarousel: -3,  
    Button: -2,
    DataGrid: -1,

    AreaChart: 0,
    BarChart: 1, 
    BoxPlot: 2, 
    BubbleGraph: 3, 
    Bullet: 4, 
    CirclePacking: 5, 
    ColumnGraph: 6, 
    DonutChart: 7, 
    FunnelChart: 8, 
    Gauge: 9, 
    HeatMap: 10, 
    Histogram: 11, 
    LineChart: 12, 
    Liquid: 13, 
    MultiLineChart: 14, 
    PieChart: 15, 
    Progress: 16, 
    RadarChart: 17, 
    RadialBarChart: 18, 
    Ring: 19, 
    RoseChart: 20, 
    SankeyPlot: 21, 
    ScatterPlot: 22, 
    StockPlot: 23, 
    SunburstPlot: 24, 
    VennPlot: 25, 
    ViolinPlot: 26, 
    WaterfallChart: 27,  
    TreeGraph: 28,  

    TinyAreaPlot: 101,
    TinyColumnPlot: 102,
    TinyLinePlot: 103,
    TinyLiquidPlot: 104,
    TinyProgress: 105,
    TinyRingProgress: 106,
    TinyStatistic: 107,
 
    ActivityView: 201,
    UserView: 202,
    EntityView: 203,
 
    MetaText_Text: 'Meta Text',
    VideoCard_Text: 'Video Card',
    WebContent_Text: 'Web Content',
    Calendar_Text: 'Calendar',
    Comments_Text: 'Comments',
    ProgressBar_Text: 'Progress Bar',
    Statistic_Text: 'Statistic',
    Countdown_Text: 'Countdown',
    Image_Text: 'Image',
    ImageCarousel_Text: 'Image Carousel',
    Button_Text: 'Button',
    DataGrid_Text: 'Data Grid',

    AreaChart_Text: 'Area Chart',
    BarChart_Text: 'Bar Chart',
    BoxPlot_Text: 'Box Plot',
    BubbleGraph_Text: 'Bubble Graph',
    Bullet_Text: 'Bullet',
    CirclePacking_Text: 'Circle Pack',
    ColumnGraph_Text: 'Column Graph',
    DonutChart_Text: 'Donut Chart',
    FunnelChart_Text: 'Funnel Chart',
    Gauge_Text: 'Gauge',
    HeatMap_Text: 'Heat Map',
    Histogram_Text: 'Histogram',
    LineChart_Text: 'Line Chart',
    Liquid_Text: 'Liquid',
    MultiLineChart_Text: 'Multiline Chart',
    PieChart_Text: 'Pie Chart',
    Progress_Text: 'Progress',
    RadarChart_Text: 'Radar Chart',
    RadialBarChart_Text: 'Radial Chart',
    Ring_Text: 'Ring',
    RoseChart_Text: 'Rose Chart',
    SankeyPlot_Text: 'Sankey',
    ScatterPlot_Text: 'Scatter Plot',
    StockPlot_Text: 'Stock Plot',
    SunburstPlot_Text: 'Sunburst Plot',
    VennPlot_Text: 'Venn Plot',
    ViolinPlot_Text: 'Violin Plot',
    WaterfallChart_Text:'Waterfall Chart',

    TinyAreaPlot_Text: 'Small Area',
    TinyColumnPlot_Text: 'Small Column',
    TinyLinePlot_Text: 'Small Line',
    TinyLiquidPlot_Text: 'Small Liquid',
    TinyProgress_Text: 'Small Progress',
    TinyRingProgress_Text: 'Small Ring',
    TinyStatistic_Text: 'Small Statistic',
 
    ActivityView_Text: 'Activity View',
    UserView_Text: 'User Veiw',
    EntityView_Text: 'Entity View',



}
