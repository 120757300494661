
import { IntegrationsSummaryReturnDto } from './dto/summaries/integrationsSummaryReturnDto'
import { IntegrationsReturnDto } from './dto/integrationsReturnDto';
import { IntegrationsCreateDto } from './dto/integrationsCreateDto';
import { IntegrationsAskDto } from './dto/integrationsAskDto';
import http from '../httpService';
import { IntegrationsEntityDto } from './dto/entities/integrationsEntityDto';
import { IntegrationsItemDto } from './dto/entities/integrationsItemDto';
import { IntegrationsOptionDto } from './dto/entities/integrationsOptionDto';
import { IntegrationsRecordDto } from './dto/entities/integrationsRecordDto';
import { IntegrationsStateDto } from './dto/entities/integrationsStateDto';
import { IntegrationsTypeDto } from './dto/entities/integrationsTypeDto';
import { EntityCommitContainer, FileUpload } from '../dto/fileActionResultDto';
import { IntegrationsTemplateDto } from './dto/entities/integrationsTemplateDto';
import { IntegrationsEntitySummaryDto } from './dto/summaries/integrationsEntitySummaryDto';
import { IntegrationsItemSummaryDto } from './dto/summaries/integrationsItemSummaryDto';
import { IntegrationsOptionSummaryDto } from './dto/summaries/integrationsOptionSummaryDto';
import { IntegrationsStateSummaryDto } from './dto/summaries/integrationsStateSummaryDto';
import { IntegrationsTemplateSummaryDto } from './dto/summaries/integrationsTemplateSummaryDto';
import { IntegrationsTypeSummaryDto } from './dto/summaries/integrationsTypeSummaryDto';
import { NoteDto } from '../dto/entities/noteDto';
import { NoteRecordSummaryDto } from '../dto/summaries/noteRecordSummaryDto';
import { NoteSummaryDto } from '../dto/summaries/noteSummaryDto';
import { NoteRecordDto } from '../dto/entities/noteRecordDto';
import { UserListSummaryDto } from '../dto/summaries/userListSummaryDto'; 
import { ValueReturnDto } from '../dto/values/valueReturnDto';
import { TemplateRecordDto } from '../dto/records/templateRecordDto';
import { PersonListSummaryDto } from '../dto/summaries/personListSummaryDto';
import { TeamListSummaryDto } from '../dto/summaries/teamListSummaryDto'; 
import { InfoHubArticleSummaryDto } from "../infoHub/dto/infoHubArticleSummaryDto";
import { CommunicationListSummaryDto } from '../dto/summaries/communicationListSummaryDto';
import { InventoryListSummaryDto } from '../dto/summaries/inventoryListSummaryDto';
import { MenuListSummaryDto } from '../dto/summaries/menuListSummaryDto';
import { PaymentsListSummaryDto } from '../dto/summaries/paymentsListSummaryDto';
import { ReportsListSummaryDto } from '../dto/summaries/reportsListSummaryDto';
import { SchedulingListSummaryDto } from '../dto/summaries/schedulingListSummaryDto';
import { WorkOrderListSummaryDto } from '../dto/summaries/workOrderListSummary';
import { UserActionRecordDto } from '../dto/records/userActionRecordDto';
import { IntegrationListSummaryDto } from '../dto/summaries/integrationListSummaryDto';

class IntegrationService {

    public async get(askDto: IntegrationsAskDto): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getResult = await http.get('api/services/app/Integrations/GetEntity', { params: askDto });
        return getResult.data.result;
    }

    public async create(askDto: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let createResult = await http.post('api/services/app/Integrations/CreateEntity', askDto);
        return createResult.data.result;
    }

    public async update(askDto: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let updateResult = await http.post('api/services/app/Integrations/UpdateEntity', askDto);
        return updateResult.data.result;
    }

    public async delete(askDto: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let deleteResult = await http.post('api/services/app/Integrations/DeleteEntity', askDto);
        return deleteResult.data;
    }

    public async getSummaryView(askDto: IntegrationsAskDto): Promise<IntegrationsSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, IntegrationsEntitySummaryDto, IntegrationsTypeSummaryDto, IntegrationsTemplateSummaryDto, IntegrationsStateSummaryDto, IntegrationsOptionSummaryDto, IntegrationsItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getSummaryViewResult = await http.get('api/services/app/Integrations/GetSummary', { params: askDto });
        return getSummaryViewResult.data.result;
    }

    //------------------------------------------------------------------

    public async uploadImage(file: FormData): Promise<FileUpload> {
        let getResult = await http.post('api/services/app/Integrations/UploadFile', file);
        return getResult.data.result;
    }

    public async getImage(id: number): Promise<FileUpload> {
        let getResult = await http.get('api/services/app/Integrations/GetFile?id=' + id);
        return getResult.data.result;
    }

    public async getFiles(id: number, type: number): Promise<FileUpload[]> {
        let getFilesResult = await http.get('api/services/app/Integrations/GetEntityFiles?id=' + id + '&type=' + type);
        return getFilesResult.data.result;
    }

    public async commitEntityFiles(input: EntityCommitContainer): Promise<boolean> {
        let getResult = await http.post('api/services/app/Integrations/CommitEntityFiles', input);
        return getResult.data.result;
    }

    //------------------------------------------------------------------

    public async getNotes(askDto: IntegrationsAskDto): Promise<IntegrationsSummaryReturnDto<PersonListSummaryDto, TeamListSummaryDto, UserListSummaryDto, IntegrationsEntitySummaryDto, IntegrationsTypeSummaryDto, IntegrationsTemplateSummaryDto, IntegrationsStateSummaryDto, IntegrationsOptionSummaryDto, IntegrationsItemSummaryDto, NoteSummaryDto, NoteRecordSummaryDto, InfoHubArticleSummaryDto, CommunicationListSummaryDto, InventoryListSummaryDto, MenuListSummaryDto, PaymentsListSummaryDto, ReportsListSummaryDto, SchedulingListSummaryDto, WorkOrderListSummaryDto, IntegrationListSummaryDto, UserActionRecordDto>> {
        let getRecordsResult = await http.get('api/services/app/Integrations/GetNotes', { params: askDto });
        return getRecordsResult.data.result;
    }

    public async addNotes(askDto: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {

        let getRecordsResult = await http.post('api/services/app/Integrations/AddNotes', askDto);
        return getRecordsResult.data.result;
    }

    public async deleteNotes(askDto: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>): Promise<IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>> {
        let getRecordsResult = await http.post('api/services/app/Integrations/DeleteNotes', askDto);
        return getRecordsResult.data.result;
    }
 
}

export default new IntegrationService();
