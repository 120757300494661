import { action, observable } from 'mobx';
import scanbotService from '../services/scanbot/scanbotService';
import type { ScanbotKeyDto } from '../services/session/dto/scanbotKeyDto';
import type { Job, ScannerJobsReturnDto } from '../services/scanbot/dto/scannerJobsReturnDto';
import type { Scanner, ScannerListReturnDto } from '../services/scanbot/dto/scannerListReturnDto';
import type { ScannerStateReturnDto, State } from '../services/scanbot/dto/scannerStateReturnDto';
import type { HistoryListRecord, ScannerHistoryListReturnDto } from '../services/scanbot/dto/scannerHistoryListReturnDto';
import type { ScannerHistoryReturnDto, HistoryRecord, JobRecord, QueueRecord } from '../services/scanbot/dto/scannerHistoryReturnDto';
import type { PossibleSettingValuesDto, ScannerSettingsReturnDto, SettingValuesDto } from '../services/scanbot/dto/scannerSettingsReturnDto';
import type { ScannerSessionCreateReturn, ScannerSessionResults, ScannerSessionStatus } from '../services/scanbot/dto/scannerSessionCreateReturn';
import type { ScanbotGetAskDto } from '../services/scanbot/dto/scanbotGetAskDto';
import type { ScannerInfo, ScannerItem, ScannerSessionReturnDto } from '../services/scanbot/dto/scanbotSessionReturnDto';
import type { ScanbotSessionCreateDto } from '../services/scanbot/dto/scanbotAskDto';
import type { ScannerDeleteDto } from '../services/scanbot/dto/scannerDeleteDto';

class ScanbotStore {
  @observable entity_MaxResultCount: number = 10000;
  @observable entity_SkipCount: number = 0;
  @observable action_MaxResultCount: number = 10000;
  @observable action_SkipCount: number = 0;

  @observable scanbot_SessionToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImM4YjlhYTA1LWJkY2EtNGJkNi1hYTUzLWJhZmVjZTJmMDExZiIsIm5hbWVfaWQiOiJ0d2FpbiIsInR5cGUiOiJiZWFyZXIiLCJpYXQiOjE3MTU3MjM2NTAsImV4cCI6MTcxNTc5NTY1MH0.1qrcEnGZCh0ScPyafye7cfe5YW7-w37DoqVzmc5BVSI';
  @observable scanbot_PrivateKey: string = ''; 
  @observable scanbot_ScannerState: string = ''; 
  @observable scanbot_ScannerStatus: string = '';  
  @observable scanbot_SessionID: string = '';  

  @observable scanbot_DetailedHistoryRecordReturn!: ScannerHistoryReturnDto<HistoryRecord,JobRecord,QueueRecord>; 
  @observable scanbot_SessionReturn!: ScannerSessionReturnDto<ScannerItem<ScannerInfo>>; 
 
  @observable scanbot_ScannerSummaryReturn!: ScannerListReturnDto<Scanner>; 
  @observable scanbot_JobSummaryReturn!: ScannerJobsReturnDto<Job>; 
  @observable scanbot_StateSummaryReturn!: ScannerStateReturnDto<State>;
  @observable scanbot_HistorySummaryReturn!: ScannerHistoryListReturnDto<HistoryListRecord>;
  @observable scanbot_SettingSummaryReturn!: ScannerSettingsReturnDto<SettingValuesDto<PossibleSettingValuesDto>>;
  @observable scanbot_SessionSummaryReturn!: ScannerSessionCreateReturn<ScannerSessionResults<ScannerSessionStatus>>;
    
  @observable scanbot_ScannerReturn!: Scanner; 
  @observable scanbot_ScannerCreate!: Scanner; 
  @observable scanbot_ScannerUpdate!: Scanner; 
  @observable scanbot_ScannerDelete!: ScannerDeleteDto; 
 
  @observable scanbot_SessionCreate!: ScanbotSessionCreateDto; 
  

  @action
  async startScan(askDto: string) {
    
    let getRecordsResult = await scanbotService.startScan({
      token: this.scanbot_SessionToken,
      scannerId: askDto 
    }); 

    return getRecordsResult;
  };

  //-------------------------------------------------------------
 

  @action
  async getScanbotAPIToken(askDto: ScanbotKeyDto) {
     
    this.scanbot_SessionToken = ''; 
    let getRecordsResult = await scanbotService.getScanbotAPIToken(askDto);
    this.scanbot_SessionToken = getRecordsResult.token; 
    return getRecordsResult.token;
  };
 
  @action
  async getPrivateKey(askDto: ScanbotGetAskDto) {
     
    this.scanbot_PrivateKey = ''; 
    let getRecordsResult = await scanbotService.getPrivateKey(askDto);
    this.scanbot_PrivateKey = getRecordsResult.token; 
    return getRecordsResult.token;
  };

  @action
  async getScannerSession(askDto: ScanbotGetAskDto) {
     
    this.scanbot_SessionReturn = {
      totalCount: 0,
      responseMessage: '',
      type:  '',
      url:  '', 
      requestTopic:  '',
      responseTopic:  '',
      scanner: {
        scannerInfoItem: {
          adfDuplexCount: 0,
          adfJamCount: 0,
          adfMultifeedCount: 0,
          adfSimplexCount: 0,
          bornDay: 0,
          bornMonth: 0,
          bornYear: 0,
          cleanCounter: 0,
          firmwareVersion: '',
          firstScanDay: 0,
          firstScanMonth: 0,
          firstScanYear: 0,
          flatbedScanCount: 0,
          frictionRollerCount: 0,
          pickupRollerCount: 0,
          powerOffTimeout: 0,
          powerSaveTimeout: 0,
          reverseRollerCount: 0,
          rollerScanCount: 0
        },
        id: '',
        name: '',
        description: '',
        manufacturer: '',
        model: '',
        serial_number: '',
        registrationToken: undefined,
        createdAt: '',
        updatedAt: '',
        accountId: '',
        clientId: '',
        loginToken: '',
        registeredByUserId: '',
        lastActive: '',
        totalScan: 0
      }
    };
 
    let getRecordsResult = await scanbotService.getScannerSession(askDto);
    this.scanbot_SessionReturn = getRecordsResult; 
    return getRecordsResult;
  };

  @action
  async getScannerState(askDto: string) { 
    this.scanbot_ScannerState = ''; 
    let getRecordsResult = await scanbotService.getScannerState({
      token: this.scanbot_SessionToken,
      itemID: askDto 
    });
    this.scanbot_ScannerState = getRecordsResult.token; 
    return getRecordsResult.token;
  };

  @action
  async getScannerStatus(askDto: string) {
     
    this.scanbot_ScannerStatus = ''; 
    let getRecordsResult = await scanbotService.getScannerStatus({
      token: this.scanbot_SessionToken,
      itemID: askDto 
    });
    
    this.scanbot_ScannerStatus = getRecordsResult.token; 
    return getRecordsResult.token;
  };

  @action
  async GetDetailedHistoryRecord(askDto: ScanbotGetAskDto) {
     
    this.scanbot_DetailedHistoryRecordReturn = {
      totalCount: 0,
      responseMessage: '',
      historyRecord: {
        id: '',
        queueId: '',
        userId: '',
        scannerId: '',
        name: '',
        description: '',
        startDate: '',
        pageCount: 0,
        createdAt: '',
        updatedAt: ''
      }, 
      jobRecord:  {
        id: '',
        accountId: '',
        description: '',
        imageURI: [],
        name: '',
        pageCount: 0,
        sessionId: '',
        createdAt: '',
        updatedAt: ''
      },
      queueRecord: {
        id: '',
        accountId: '',
        jobId: '',
        scannerId: '',
        startDate: '',
        status: '',
        createdAt: '',
        updatedAt: '',
        webSocketToken: '',
        webSocketURL: ''
      },
      urls: []
    };
 
    let getRecordsResult = await scanbotService.GetDetailedHistoryRecord(askDto);
    this.scanbot_DetailedHistoryRecordReturn = getRecordsResult; 
    return getRecordsResult;
  };
   
  //-------------------------------------------------------------
 
  @action
  async getScannerSummary() {


    this.scanbot_ScannerSummaryReturn = {
      totalCount: 0,
      responseMessage: '', 
      scanTitle: '',
      url: '',
      scanners: [] 
    }; 
 
    let getRecordsResult = await scanbotService.getScannerSummary({token: this.scanbot_SessionToken});
 
    this.scanbot_ScannerSummaryReturn = getRecordsResult; 
    return getRecordsResult;
  };
  
  @action
  async getJobSummary(askDto: string) {
     
    this.scanbot_JobSummaryReturn = { 
      totalCount: 0,
      responseMessage: '',
      jobs: [] 
    };
 
    let getRecordsResult = await scanbotService.getJobSummary({token: this.scanbot_SessionToken});
    this.scanbot_JobSummaryReturn = getRecordsResult; 
    return getRecordsResult;
  };

  @action
  async getStateSummary(askDto: string) {
     
    this.scanbot_StateSummaryReturn = {
      totalCount: 0,
      responseMessage: '',
      states: []
    };
 
    let getRecordsResult = await scanbotService.getStateSummary({token: this.scanbot_SessionToken});
    this.scanbot_StateSummaryReturn = getRecordsResult; 
    return getRecordsResult;
  };

  @action
  async getHistorySummary(askDto: string) {
     
    this.scanbot_HistorySummaryReturn = { 
      totalCount: 0,
      responseMessage: '',
      historyRecords: []
    };
 
    let getRecordsResult = await scanbotService.getHistorySummary({token: this.scanbot_SessionToken});
    this.scanbot_HistorySummaryReturn = getRecordsResult; 
    return getRecordsResult;
  };

  @action
  async GetSettingSummary(askDto: string) {
     
    this.scanbot_SettingSummaryReturn = {
      totalCount: 0,
      responseMessage: '',
      settings: [] 
    };
 
    let getRecordsResult = await scanbotService.GetSettingSummary({token: this.scanbot_SessionToken});
    this.scanbot_SettingSummaryReturn = getRecordsResult; 
    return getRecordsResult;
  };

  //-------------------------------------------------------------
  
  @action
  async createScanner() {
       

    await scanbotService.createScanner(this.scanbot_ScannerCreate);
    //this.scanbot_ScannerReturn = getRecordsResult; 
    return;
  };

  @action
  async updateScanner() {
      
    await scanbotService.updateScanner(this.scanbot_ScannerUpdate);
    //this.scanbot_ScannerReturn = getRecordsResult; 
    return;
  };
 
  @action
  async deleteScanner() {
      
    await scanbotService.deleteScanner(this.scanbot_ScannerDelete);
    //this.scanbot_ScannerReturn = getRecordsResult; 
    return;
  };

  //-------------------------------------------------------------
 
  @action
  async startScannerSession() {
    
    this.scanbot_SessionID = '';
    this.scanbot_SessionSummaryReturn = {
      totalCount: 0,
      responseMessage: '',
      commandId: '',
      kind: '',
      method: '',
      results: {
        success: false,
        session: {
          imageBlocksDrained: false,
          paperIn: false,
          revision: 0,
          sessionId: '',
          state: ''
        }
      }
    }
 
    let sessionReturn = await scanbotService.startScannerSession(this.scanbot_SessionCreate);
    this.scanbot_SessionSummaryReturn = sessionReturn; 
    return;
  };

  @action
  async endScannerSession() {
      
    
    this.scanbot_SessionID = '';
    this.scanbot_SessionSummaryReturn = {
      totalCount: 0,
      responseMessage: '',
      commandId: '',
      kind: '',
      method: '',
      results: {
        success: false,
        session: {
          imageBlocksDrained: false,
          paperIn: false,
          revision: 0,
          sessionId: '',
          state: ''
        }
      }
    }

    await scanbotService.endScannerSession(this.scanbot_SessionID);
    //this.scanbot_ScannerReturn = getRecordsResult; 
    return;
  };

 
}

export default ScanbotStore;
