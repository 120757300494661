import { action, observable } from 'mobx';
import type { CreateTenantInput } from '../services/tenant/dto/createTenantInput';
import { EntityDto } from '../services/dto/entityDto';
import { GetAllTenantOutput } from '../services/tenant/dto/getAllTenantOutput';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import type { PagedTenantResultRequestDto } from '../services/tenant/dto/PagedTenantResultRequestDto';
import TenantModel from '../models/Tenants/TenantModel';
import tenantService from '../services/tenant/tenantService';
import type { RcFile } from 'antd/lib/upload';
import { FileUpload } from '../services/dto/fileActionResultDto';
import { TenantSummaryDto } from '../services/tenant/dto/tenantSummaryDto';
import type { Data, Fields, Meta, S2DataConfig } from '@antv/s2';
import { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import type UpdateTenantInput from '../services/tenant/dto/updateTenantInput';
import { L } from '../lib/abpUtility';

class TenantStore {

  @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

  @observable tenants!: PagedResultDto<GetAllTenantOutput>;
  @observable tenantModel: TenantModel = new TenantModel();
  @observable assetCache!: PagedResultDto<FileUpload>;
  @observable profileID: number = 0;
  @observable currentState: any = 1;
  @observable editTenant!: any;
  @observable tenantSummary!: PagedResultDto<TenantSummaryDto>;

  @observable search_Mentions: any[] = [];
  @observable search_Hashtags: any[] = [];
 
  @observable dataExportTable!: S2DataConfig;
  @observable dataExportTable_Analytic!: S2DataConfig;
  @observable dataExportTableData: Data[] = [];
  @observable dataExportTableTotalData: Data[] = [];
  @observable dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'title', 'subscriberEmail', 'isActive', 'currentState']
  };
  @observable dataTableFields_Analytic: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'title', 'subscriberEmail', 'isActive', 'currentState']
  };
  @observable dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "name",
      "name": "Name"
    },
    {
      "field": "title",
      "name": "Title"
    },
    {
      "field": "subscriberEmail",
      "name": "Email"
    },
    {
      "field": "isActive",
      "name": "Active"
    },
    {
      "field": "subscriberFirstName",
      "name": "First Name"
    },
    {
      "field": "subscriberLastName",
      "name": "Last Name"
    },
    {
      "field": "subscriberPhone",
      "name": "Phone"
    },
    {
      "field": "currentState",
      "name": "State"
    },
  ];

  @observable tenantStates: any = {
    totalCount: 3,
    items:
      [
        {
          id: 0,
          name: 'inactive',
          title: L('Inactive'),
          profileImageID: 0,
          displayType: 0,
          forwardText: L('Submit for Approval'),
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 1,
          name: 'approval',
          title: L('Approval'),
          profileImageID: 0,
          displayType: 0,
          forwardText: L('Approve'),
          revertText: L('Deny'),
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: false,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 2,
          name: 'onboarding',
          title: L('Onboarding'),
          profileImageID: 0,
          displayType: 0,
          forwardText: L('Complete'),
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
        {
          id: 3,
          name: 'active',
          title: L('Active'),
          profileImageID: 0,
          displayType: 0,
          forwardText: L('Suspend'),
          revertText: '',
          restricted: false,
          allowSkipTo: true,
          allowRevert: true,
          nextActionOnly: true,
          lastActionOnly: false,
          noActions: false,
        },
      ]
  }


  @observable entityActivity_dataExportTable!: any;
  @observable entityActivity_dataExportTableData: Data[] = [];
  @observable entityActivity_dataExportTableTotalData: Data[] = [];
  @observable entityActivity_dataTableFields: Fields = {
    rows: ['id'],
    columns: [], 
    values: ['id', 'actionType', 'entityName'],
  };
  @observable entityActivity_dataTableMeta: Meta[] = [
    {
      "field": "id",
      "name": "ID"
    },
    {
      "field": "actionType",
      "name": "Type"
    },
    {
      "field": "actionID",
      "name": "Action ID"
    },
    {
      "field": "entityName",
      "name": "Entity"
    },
    {
      "field": "entityID",
      "name": "Entity ID"
    },
    {
      "field": "previousStateName",
      "name": "Previous State"
    },
    {
      "field": "previousStateAction",
      "name": "Previous Action"
    },
    {
      "field": "previousStateID",
      "name": "Previous State ID"
    },
    {
      "field": "newStateName",
      "name": "New State"
    },
    {
      "field": "newStateAction",
      "name": "New Action"
    },
    {
      "field": "newStateID",
      "name": "New State ID"
    },
    {
      "field": "itemName",
      "name": "Item"
    },
    {
      "field": "itemID",
      "name": "Item ID"
    },
    {
      "field": "itemValue",
      "name": "Item Value"
    },
    {
      "field": "notes",
      "name": "Notes"
    },
    {
      "field": "createdTime",
      "name": "Created"
    },
    {
      "field": "creatorID",
      "name": "Creator"
    },
    {
      "field": "ownerID",
      "name": "Owner"
    },
    {
      "field": "relatedEntityType",
      "name": "Entity Type"
    },
    {
      "field": "relatedEntityID",
      "name": "Related Entity ID"
    },
    {
      "field": "linkedEntityType",
      "name": "Linked Entity"
    },
    {
      "field": "linkedEntityID",
      "name": "Linked Entity ID"
    },
  ];


  @action
  async uploadImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await tenantService.uploadImage(formData);
    this.assetCache.items.push(result);
    return result;
  }


  @action
  async uploadProfileImage(file: RcFile) {

    const formData = new FormData();
    formData.append("file", file);
    let result = await tenantService.uploadImage(formData);

    this.profileID = result.assetID;
    return result;
  }

  @action
  async getProfileImage(recordID: number) {


    if (recordID === 0 || recordID === 1) {
      return '_';
    }
    else {
      let result = await tenantService.getImage(recordID);

      return result.url;
    }
  }

  public async resetAssetCache() {
    this.assetCache = {
      totalCount: 0,
      items: []
    };

    this.profileID = 0;
  }
 
  public async commitAssetCache(linkedID: number, entityType: number) {

    this.assetCache.items.map((x: FileUpload) => {

      return tenantService.updateRecord({
        id: x.recordID,
        relatedEntityType: entityType,
        relatedEntityID: linkedID,
        linkedEntityType: 15,
        LinkedEntityID: x.assetID,
      });
    })

    this.assetCache = {
      totalCount: 0,
      items: []
    };
  }


  //--------------------------------------------------------------------------

  @action
  async create(createTenantInput: CreateTenantInput) {
  

    let result = await tenantService.create(createTenantInput);
    this.commitAssetCache(result.id, 0);
  }

  @action
  async createTenant() {

    this.currentState = 0;
    this.tenantModel = {
      id: 0,
      tenancyName: '',
      name: '',
      adminEmailAddress: '',
      connectionString: '',
      isActive: false,

      currentState: 0,

      relatedImage: '',
      subscriberFirstName: '',
      subscriberLastName: '',
      subscriberEmail: '',
      subscriberPhone: '',

      ftpurl: '',
      ftpUser: '',
      ftpPassword: '',
      profileImageID: 0
    };
  }

  @action
  async update(updateTenantInput: UpdateTenantInput) {
    let result = await tenantService.update(updateTenantInput);

    this.commitAssetCache(result.id, 0);
    this.tenants.items = this.tenants.items.map((x: GetAllTenantOutput) => {
      if (x.id === updateTenantInput.id) x = result;
      return x;
    });
  }

  @action
  async delete(entityDto: EntityDto) {
    await tenantService.delete(entityDto);
    this.tenants.items = this.tenants.items.filter((x: GetAllTenantOutput) => x.id !== entityDto.id);
  }

  @action
  async get(entityDto: EntityDto) {
    let result = await tenantService.get(entityDto);
    this.tenantModel = result;
    this.editTenant = result;

    //NOTE: State is stored seperate from model in order to allow logic layer of visibilites, permissions, etc.
    this.currentState = this.editTenant.currentState;

  }

  @action
  async getAll(pagedFilterAndSortedRequest: PagedTenantResultRequestDto) {

    this.tenants =
    {
      totalCount: 0,
      items: []
    }

    let result = await tenantService.getAll(pagedFilterAndSortedRequest);
    this.tenants = result;


    // Loop Users
    result.items.map((x: GetAllTenantOutput) => {
      let resultString = x.name === undefined ? '' : x.name;

      // Get Data for Mentions
      if (resultString !== '' && this.search_Mentions.includes(resultString) === false) {
        this.search_Mentions.push(resultString);
      }

      // Get Data for Export Table
      var dataItem: Data =
      {
        "id": x.id === undefined ? 0 : x.id,
        "name": x.name === undefined ? 'Unknown' : x.name,
        "title": x.title === undefined ? 'Unknown' : x.title,
        "isActive": x.isActive === undefined ? 'Unknown' : x.isActive.toString(),
        "currentState": x.currentState === undefined ? 'Unknown' : x.currentState.toString(),
        "subscriberFirstName": x.subscriberFirstName === undefined ? 'Unknown' : x.subscriberFirstName,
        "subscriberLastName": x.subscriberLastName === undefined ? 'Unknown' : x.subscriberLastName,
        "subscriberEmail": x.subscriberEmail === undefined ? 'Unknown' : x.subscriberEmail,
        "subscriberPhone": x.subscriberPhone === undefined ? 'Unknown' : x.subscriberPhone,


      }

      return this.dataExportTableData.push(dataItem);

    });

    this.dataExportTable = {
      fields: this.dataTableFields,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    }

    this.dataExportTable_Analytic = {
      fields: this.dataTableFields_Analytic,
      "meta": this.dataTableMeta,
      "data": this.dataExportTableData,
      "totalData": this.dataExportTableTotalData,
    }
  }
}

export default TenantStore;
