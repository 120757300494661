import { action, observable } from 'mobx';
import type { AreaChart_Configuration, AreaChart_DataModel, AxisModel } from '../components/DataModels/areaChart_Configuration'
import type { BarChart_Configuration, BarChart_DataModel } from '../components/DataModels/barChart_Configuration';
import type { BoxPlot_Configuration, BoxPlot_DataModel, BoxStyleModel } from '../components/DataModels/boxPlot_Configuration';
import type { Bullet_Configuration, Bullet_DataModel } from '../components/DataModels/bullet_Configuration';
import type { CirclePacking_Configuration, CirclePacking_DataModel, CirclePacking_DataModelChildren } from '../components/DataModels/circlePacking_Configuration';
import type { ColumnGraph_Configuration, ColumnGraph_DataModel } from '../components/DataModels/columnGraph_Configuration';
import type { DonutChart_Configuration, DonutChart_DataModel } from '../components/DataModels/donutChart_Configuration';
import type { FunnelChart_Configuration, FunnelChart_DataModel } from '../components/DataModels/funnelChart_Configuration';
import type { Gauge_Configuration } from '../components/DataModels/gauge_Configuration';
import type { HeatMap_Configuration, HeatMap_DataModel } from '../components/DataModels/heatMap_Configuration';
import type { Histogram_Configuration, Histogram_DataModel } from '../components/DataModels/histogram_Configuration';
import type { LineChart_Configuration, LineChart_DataModel } from '../components/DataModels/lineChart_Configuration';
import type { Liquid_Configuration } from '../components/DataModels/liquid_Configuration';
import type { MultiLineChart_Configuration, MultiLineChart_DataModel } from '../components/DataModels/multiLineChart_Configuration';
import type { PieChart_Configuration, PieChart_DataModel } from '../components/DataModels/pieChart_Configuration';
import type { Progress_Configuration } from '../components/DataModels/progress_Configuration';
import type { RadarChart_Configuration } from '../components/DataModels/radarChart_Configuration';
import type { RadialBarChart_Configuration, RadialBarChart_DataModel } from '../components/DataModels/radialBarChart_Configuration';
import type { Ring_Configuration } from '../components/DataModels/ring_Configuration';
import type { RoseChart_Configuration, RoseChart_DataModel } from '../components/DataModels/roseChart_Configuration';
import type { SankeyPlot_Configuration, SankeyPlot_DataModel } from '../components/DataModels/sankeyPlot_Configuration';
import type { ScatterPlot_Configuration, ScatterPlot_DataModel } from '../components/DataModels/scatterPlot_Configuration';
import type { StockPlot_Configuration, StockPlot_DataModel } from '../components/DataModels/stockPlot_Configuration';
import type { SunburstPlot_Configuration, SunburstPlot_DataModel } from '../components/DataModels/sunburstPlot_Configuration';
import type { VennPlot_Configuration, VennPlot_DataModel } from '../components/DataModels/vennPlot_Configuration';
import type { ViolinPlot_Configuration, ViolinPlot_DataModel } from '../components/DataModels/violinPlot_Configuration';
import type { WaterfallChart_Configuration, WaterfallChart_DataModel } from '../components/DataModels/waterfallChart_Configuration';
import { DashboardItemTypes } from '../components/EntityConfigs/dashboardItemTypes';
import dashboardService from '../services/dashboard/dashboardService';
import type { DashbordAskDto } from '../services/dashboard/dto/dashboardAskDto';
import type { DashboardLayoutDto, DashboardRowDto, DashboardColumnDto } from '../services/dashboard/dto/dashboardLayoutDto';
import type { PagedResultDto } from '../services/dto/pagedResultDto';
import { UploadFile } from 'antd';
import { FormGridDto } from '../components/EntityConfigs/formGridDto';
import type { CommunicationAskDto } from '../services/communication/dto/communicationAskDto';
import type { CommunicationCreateDto } from '../services/communication/dto/communicationCreateDto';
import type { CommunicationReturnDto } from '../services/communication/dto/communicationReturnDto';
import type { CommunicationEntityDto } from '../services/communication/dto/entities/communicationEntityDto';
import type { CommunicationItemDto } from '../services/communication/dto/entities/communicationItemDto';
import type { CommunicationOptionDto } from '../services/communication/dto/entities/communicationOptionDto';
import type { CommunicationRecordDto } from '../services/communication/dto/entities/communicationRecordDto';
import type { CommunicationStateDto } from '../services/communication/dto/entities/communicationStateDto';
import type { CommunicationTemplateDto } from '../services/communication/dto/entities/communicationTemplateDto';
import type { CommunicationTypeDto } from '../services/communication/dto/entities/communicationTypeDto';
import type { NoteDto } from '../services/dto/entities/noteDto';
import type { NoteRecordDto } from '../services/dto/entities/noteRecordDto';
import type { NotificationDto } from '../services/dto/entities/notificationDto';
import type { EntityCommitContainer } from '../services/dto/fileActionResultDto';
import type { TemplateRecordDto } from '../services/dto/records/templateRecordDto';
import type { UserActionRecordDto } from '../services/dto/records/userActionRecordDto';
import type { CommunicationListSummaryDto } from '../services/dto/summaries/communicationListSummaryDto';
import type { InventoryListSummaryDto } from '../services/dto/summaries/inventoryListSummaryDto';
import type { MenuListSummaryDto } from '../services/dto/summaries/menuListSummaryDto';
import type { NoteSummaryDto } from '../services/dto/summaries/noteSummaryDto';
import type { PaymentsListSummaryDto } from '../services/dto/summaries/paymentsListSummaryDto';
import type { PersonListSummaryDto } from '../services/dto/summaries/personListSummaryDto';
import type { ReportsListSummaryDto } from '../services/dto/summaries/reportsListSummaryDto';
import type { SchedulingListSummaryDto } from '../services/dto/summaries/schedulingListSummaryDto';
import type { TeamListSummaryDto } from '../services/dto/summaries/teamListSummaryDto';
import type { UserListSummaryDto } from '../services/dto/summaries/userListSummaryDto';
import type { WorkOrderListSummaryDto } from '../services/dto/summaries/workOrderListSummary';
import type { ValueReturnDto } from '../services/dto/values/valueReturnDto';
import type { InfoHubArticleDto } from '../services/infoHub/dto/infoHubArticleDto';
import type { InfoHubArticleRecordDto } from '../services/infoHub/dto/infoHubArticleRecordDto';
import type { InfoHubArticleSectionDto } from '../services/infoHub/dto/infoHubArticleSectionDto';
import type { InfoHubAskDto } from '../services/infoHub/dto/infoHubAskDto';
import type { InfoHubCreateDto } from '../services/infoHub/dto/infoHubCreateDto';
import type { InfoHubReturnDto } from '../services/infoHub/dto/infoHubReturnDto';
import type { IntegrationsEntityDto } from '../services/integrations/dto/entities/integrationsEntityDto';
import type { IntegrationsItemDto } from '../services/integrations/dto/entities/integrationsItemDto';
import type { IntegrationsOptionDto } from '../services/integrations/dto/entities/integrationsOptionDto';
import type { IntegrationsRecordDto } from '../services/integrations/dto/entities/integrationsRecordDto';
import type { IntegrationsStateDto } from '../services/integrations/dto/entities/integrationsStateDto';
import type { IntegrationsTemplateDto } from '../services/integrations/dto/entities/integrationsTemplateDto';
import type { IntegrationsTypeDto } from '../services/integrations/dto/entities/integrationsTypeDto';
import type { IntegrationsAskDto } from '../services/integrations/dto/integrationsAskDto';
import type { IntegrationsCreateDto } from '../services/integrations/dto/integrationsCreateDto';
import type { IntegrationsReturnDto } from '../services/integrations/dto/integrationsReturnDto';
import type { InventoryEntityDto } from '../services/inventory/dto/entities/inventoryEntityDto';
import type { InventoryItemDto } from '../services/inventory/dto/entities/inventoryItemDto';
import type { InventoryOptionDto } from '../services/inventory/dto/entities/inventoryOptionDto';
import type { InventoryRecordDto } from '../services/inventory/dto/entities/inventoryRecordDto';
import type { InventoryStateDto } from '../services/inventory/dto/entities/inventoryStateDto';
import type { InventoryTemplateDto } from '../services/inventory/dto/entities/inventoryTemplateDto';
import type { InventoryTypeDto } from '../services/inventory/dto/entities/inventoryTypeDto';
import type { InventoryAskDto } from '../services/inventory/dto/inventoryAskDto';
import type { InventoryCreateDto } from '../services/inventory/dto/inventoryCreateDto';
import type { InventoryReturnDto } from '../services/inventory/dto/inventoryReturnDto';
import type { MenuEntityDto } from '../services/menu/dto/entities/menuEntityDto';
import type { MenuItemDto } from '../services/menu/dto/entities/menuItemDto';
import type { MenuOptionDto } from '../services/menu/dto/entities/menuOptionDto';
import type { MenuRecordDto } from '../services/menu/dto/entities/menuRecordDto';
import type { MenuStateDto } from '../services/menu/dto/entities/menuStateDto';
import type { MenuTemplateDto } from '../services/menu/dto/entities/menuTemplateDto';
import type { MenuTypeDto } from '../services/menu/dto/entities/menuTypeDto';
import type { MenuAskDto } from '../services/menu/dto/menuAskDto';
import type { MenuCreateDto } from '../services/menu/dto/menuCreateDto';
import type { MenuReturnDto } from '../services/menu/dto/menuReturnDto';
import type { PaymentEntityDto } from '../services/payments/dto/entities/paymentEntityDto';
import type { PaymentItemDto } from '../services/payments/dto/entities/paymentItemDto';
import type { PaymentOptionDto } from '../services/payments/dto/entities/paymentOptionDto';
import type { PaymentRecordDto } from '../services/payments/dto/entities/paymentRecordDto';
import type { PaymentStateDto } from '../services/payments/dto/entities/paymentStateDto';
import type { PaymentTemplateDto } from '../services/payments/dto/entities/paymentTemplateDto';
import type { PaymentTypeDto } from '../services/payments/dto/entities/paymentTypeDto';
import type { PaymentAskDto } from '../services/payments/dto/paymentAskDto';
import type { PaymentCreateDto } from '../services/payments/dto/paymentCreateDto';
import type { PaymentReturnDto } from '../services/payments/dto/paymentReturnDto';
import type { PeopleEntityDto } from '../services/people/dto/entities/peopleEntityDto';
import type { PeopleItemDto } from '../services/people/dto/entities/peopleItemDto';
import type { PeopleOptionDto } from '../services/people/dto/entities/peopleOptionDto';
import type { PeopleRecordDto } from '../services/people/dto/entities/peopleRecordDto';
import type { PeopleStateDto } from '../services/people/dto/entities/peopleStateDto';
import type { PeopleTemplateDto } from '../services/people/dto/entities/peopleTemplateDto';
import type { PeopleTypeDto } from '../services/people/dto/entities/peopleTypeDto';
import type { PeopleAskDto } from '../services/people/dto/peopleAskDto';
import type { PeopleCreateDto } from '../services/people/dto/peopleCreateDto';
import type { PeopleReturnDto } from '../services/people/dto/peopleReturnDto';
import type { ReportEntityDto } from '../services/report/dto/entities/reportEntityDto';
import type { ReportItemDto } from '../services/report/dto/entities/reportItemDto';
import type { ReportOptionDto } from '../services/report/dto/entities/reportOptionDto';
import type { ReportRecordDto } from '../services/report/dto/entities/reportRecordDto';
import type { ReportStateDto } from '../services/report/dto/entities/reportStateDto';
import type { ReportTemplateDto } from '../services/report/dto/entities/reportTemplateDto';
import type { ReportTypeDto } from '../services/report/dto/entities/reportTypeDto';
import type { ReportAskDto } from '../services/report/dto/reportAskDto';
import type { ReportCreateDto } from '../services/report/dto/reportCreateDto';
import type { ReportReturnDto } from '../services/report/dto/reportReturnDto';
import type { GetAllRoleOutput } from '../services/role/dto/getAllRoleOutput';
import type { SchedulingEntityDto } from '../services/scheduling/dto/entities/schedulingEntityDto';
import type { SchedulingItemDto } from '../services/scheduling/dto/entities/schedulingItemDto';
import type { SchedulingOptionDto } from '../services/scheduling/dto/entities/schedulingOptionDto';
import type { SchedulingRecordDto } from '../services/scheduling/dto/entities/schedulingRecordDto';
import type { SchedulingStateDto } from '../services/scheduling/dto/entities/schedulingStateDto';
import type { SchedulingTemplateDto } from '../services/scheduling/dto/entities/schedulingTemplateDto';
import type { SchedulingTypeDto } from '../services/scheduling/dto/entities/schedulingTypeDto';
import type { SchedulingAskDto } from '../services/scheduling/dto/schedulingAskDto';
import type { SchedulingCreateDto } from '../services/scheduling/dto/schedulingCreateDto';
import type { SchedulingReturnDto } from '../services/scheduling/dto/schedulingReturnDto';
import type { TeamEntityDto } from '../services/team/dto/entities/teamEntityDto';
import type { TeamItemDto } from '../services/team/dto/entities/teamItemDto';
import type { TeamOptionDto } from '../services/team/dto/entities/teamOptionDto';
import type { TeamRecordDto } from '../services/team/dto/entities/teamRecordDto';
import type { TeamStateDto } from '../services/team/dto/entities/teamStateDto';
import type { TeamTemplateDto } from '../services/team/dto/entities/teamTemplateDto';
import type { TeamTypeDto } from '../services/team/dto/entities/teamTypeDto';
import type { TeamAskDto } from '../services/team/dto/teamAskDto';
import type { TeamCreateDto } from '../services/team/dto/teamCreateDto';
import type { TeamReturnDto } from '../services/team/dto/teamReturnDto';
import type { WorkOrderEntityDto } from '../services/workOrder/dto/entities/workOrderEntityDto';
import type { WorkOrderItemDto } from '../services/workOrder/dto/entities/workOrderItemDto';
import type { WorkOrderOptionDto } from '../services/workOrder/dto/entities/workOrderOptionDto';
import type { WorkOrderRecordDto } from '../services/workOrder/dto/entities/workOrderRecordDto';
import type { WorkOrderStateDto } from '../services/workOrder/dto/entities/workOrderStateDto';
import type { WorkOrderTemplateDto } from '../services/workOrder/dto/entities/workOrderTemplateDto';
import type { WorkOrderTypeDto } from '../services/workOrder/dto/entities/workOrderTypeDto';
import type { WorkOrderAskDto } from '../services/workOrder/dto/workOrderAskDto';
import type { WorkOrderCreateDto } from '../services/workOrder/dto/workOrderCreateDto';
import type { WorkOrderReturnDto } from '../services/workOrder/dto/workOrderReturnDto';
import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import { RelatedEntityTypes } from '../components/EntityConfigs/relatedEntityTypes';
import communicationService from '../services/communication/communicationService';
import inventoryService from '../services/inventory/inventoryService';
import menuService from '../services/menu/menuService';
import peopleService from '../services/people/peopleService';
import schedulingService from '../services/scheduling/schedulingService';
import paymentService from '../services/payments/paymentService';
import reportService from '../services/report/reportService';
import integrationService from '../services/integrations/integrationService';
import teamService from '../services/team/teamService';
import workOrderService from '../services/workOrder/workOrderService';
import { EntityDto } from '../services/dto/entityDto';
import { StateTypes } from '../components/EntityConfigs/stateTypes';
import { DataConsts } from '../components/EntityConfigs/dataConsts';
import { itemTypes } from '../components/EntityConfigs/itemTypes';
import type { IntegrationListSummaryDto } from '../services/dto/summaries/integrationListSummaryDto';
import userService from '../services/user/userService';
import axios from 'axios';


class DashboardStore {
    multiEntityList: undefined;


    @observable serverPath = 'https://app.swilab.com/';

    @observable user_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;
    @observable public_Dashboard!: DashboardLayoutDto<DashboardRowDto<DashboardColumnDto>>;

    @observable entity_MaxResultCount: number = 10000;
    @observable entity_SkipCount: number = 0;
    @observable action_MaxResultCount: number = 10000;
    @observable action_SkipCount: number = 0;

    @observable actionSummaryReturn!: PagedResultDto<UserActionRecordDto>;

    @observable entityAsk!: DashbordAskDto;
    //--------------------------------------------------------------------------

    @observable entitySummary!: PagedResultDto<any>;
    @observable entities!: PagedResultDto<any>;
    @observable entitiesList!: PagedResultDto<any>;
    @observable entitySummaryCondensed!: PagedResultDto<any>;

    @observable communication_entityAsk!: CommunicationAskDto;
    @observable communication_entityCreate!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityDelete!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityEdit!: CommunicationCreateDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable communication_entityReturn!: CommunicationReturnDto<CommunicationEntityDto, CommunicationItemDto, CommunicationOptionDto, CommunicationRecordDto, CommunicationStateDto, CommunicationTypeDto, CommunicationTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable communication_entitySummary!: PagedResultDto<any>;
    @observable communication_entities!: PagedResultDto<any>;
    @observable communication_entitiesList!: PagedResultDto<any>;
    @observable communication_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myCommunication_entitySummary!: PagedResultDto<any>;
    @observable relatedCommunication_entitySummary!: PagedResultDto<any>;

    @observable infoHub_articleAsk!: InfoHubAskDto;
    @observable infoHub_editArticle!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleCreate!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleDelete!: InfoHubCreateDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;
    @observable infoHub_articleReturn!: InfoHubReturnDto<InfoHubArticleDto, InfoHubArticleRecordDto, NoteDto, NoteRecordDto, InfoHubArticleSectionDto>;

    @observable articles!: PagedResultDto<InfoHubArticleDto>;
    @observable articleSummary!: PagedResultDto<any>;
    @observable articleList!: PagedResultDto<any>;
    @observable articleSummaryCondensed!: PagedResultDto<any>;
    @observable myArticles!: PagedResultDto<InfoHubArticleDto>;
    @observable relatedArticles!: PagedResultDto<InfoHubArticleDto>;

    @observable integrations_entityAsk!: IntegrationsAskDto;
    @observable integrations_entityCreate!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable integrations_entityDelete!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable integrations_entityReturn!: IntegrationsReturnDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;
    @observable integrations_entityEdit!: IntegrationsCreateDto<IntegrationsEntityDto, IntegrationsItemDto, IntegrationsOptionDto, IntegrationsRecordDto, IntegrationsStateDto, IntegrationsTypeDto, IntegrationsTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;

    @observable integrations_entitySummary!: PagedResultDto<any>;
    @observable integrations_entities!: PagedResultDto<any>;
    @observable integrations_entitiesList!: PagedResultDto<any>;
    @observable integrations_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myIntegrations_entitySummary!: PagedResultDto<any>;
    @observable relatedIntegrations_entitySummary!: PagedResultDto<any>;

    @observable inventory_entityAsk!: InventoryAskDto;
    @observable inventory_entityCreate!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityEdit!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityDelete!: InventoryCreateDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable inventory_entityReturn!: InventoryReturnDto<InventoryEntityDto, InventoryItemDto, InventoryOptionDto, InventoryRecordDto, InventoryStateDto, InventoryTypeDto, InventoryTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable inventory_entitySummary!: PagedResultDto<any>;
    @observable inventory_entities!: PagedResultDto<any>;
    @observable inventory_entitiesList!: PagedResultDto<any>;
    @observable inventory_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myInventory_entitySummary!: PagedResultDto<any>;
    @observable relatedInventory_entitySummary!: PagedResultDto<any>;

    @observable menu_entityAsk!: MenuAskDto;
    @observable menu_entityCreate!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityEdit!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityDelete!: MenuCreateDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable menu_entityReturn!: MenuReturnDto<MenuEntityDto, MenuItemDto, MenuOptionDto, MenuRecordDto, MenuStateDto, MenuTypeDto, MenuTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable menu_entitySummary!: PagedResultDto<any>;
    @observable menu_entities!: PagedResultDto<any>;
    @observable menu_entitiesList!: PagedResultDto<any>;
    @observable menu_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myMenu_entitySummary!: PagedResultDto<any>;
    @observable relatedMenu_entitySummary!: PagedResultDto<any>;

    @observable payments_entityAsk!: PaymentAskDto;
    @observable payments_entityCreate!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityEdit!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityDelete!: PaymentCreateDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable payments_entityReturn!: PaymentReturnDto<PaymentEntityDto, PaymentItemDto, PaymentOptionDto, PaymentRecordDto, PaymentStateDto, PaymentTypeDto, PaymentTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable payments_entitySummary!: PagedResultDto<any>;
    @observable payments_entities!: PagedResultDto<any>;
    @observable payments_entitiesList!: PagedResultDto<any>;
    @observable payments_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myPayments_entitySummary!: PagedResultDto<any>;
    @observable relatedPayments_entitySummary!: PagedResultDto<any>;

    @observable people_entityAsk!: PeopleAskDto;
    @observable people_entityCreate!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityEdit!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityDelete!: PeopleCreateDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable people_entityReturn!: PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable people_entitySummary!: PagedResultDto<any>;
    @observable people_entities!: PagedResultDto<any>;
    @observable people_entitiesList!: PagedResultDto<any>;
    @observable people_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myPersonas_entitySummary!: PagedResultDto<any>;
    @observable relatedPersonas_entitySummary!: PagedResultDto<any>;
    @observable myPersonas_entityReturn!: PeopleReturnDto<PeopleEntityDto, PeopleItemDto, PeopleOptionDto, PeopleRecordDto, PeopleStateDto, PeopleTypeDto, PeopleTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;
    @observable myPersonas_HTMLBody!: string;

    @observable reports_entityAsk!: ReportAskDto;
    @observable reports_entityCreate!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityEdit!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityDelete!: ReportCreateDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable reports_entityReturn!: ReportReturnDto<ReportEntityDto, ReportItemDto, ReportOptionDto, ReportRecordDto, ReportStateDto, ReportTypeDto, ReportTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable reports_entitySummary!: PagedResultDto<any>;
    @observable reports_entities!: PagedResultDto<any>;
    @observable reports_entitiesList!: PagedResultDto<any>;
    @observable reports_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myReports_entitySummary!: PagedResultDto<any>;
    @observable relatedReports_entitySummary!: PagedResultDto<any>;

    @observable scheduling_entityAsk!: SchedulingAskDto;
    @observable scheduling_entityCreate!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityEdit!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityDelete!: SchedulingCreateDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable scheduling_entityReturn!: SchedulingReturnDto<SchedulingEntityDto, SchedulingItemDto, SchedulingOptionDto, SchedulingRecordDto, SchedulingStateDto, SchedulingTypeDto, SchedulingTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable scheduling_entitySummary!: PagedResultDto<any>;
    @observable scheduling_entities!: PagedResultDto<any>;
    @observable scheduling_entitiesList!: PagedResultDto<any>;
    @observable scheduling_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myScheduling_entitySummary!: PagedResultDto<any>;
    @observable relatedScheduling_entitySummary!: PagedResultDto<any>;

    @observable teams_entityAsk!: TeamAskDto;
    @observable teams_entityCreate!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityEdit!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityDelete!: TeamCreateDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable teams_entityReturn!: TeamReturnDto<TeamEntityDto, TeamItemDto, TeamOptionDto, TeamRecordDto, TeamStateDto, TeamTypeDto, TeamTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable teams_entitySummary!: PagedResultDto<any>;
    @observable teams_entities!: PagedResultDto<any>;
    @observable teams_entitiesList!: PagedResultDto<any>;
    @observable teams_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myTeams_entitySummary!: PagedResultDto<any>;
    @observable relatedTeams_entitySummary!: PagedResultDto<any>;

    @observable workOrders_entityAsk!: WorkOrderAskDto;
    @observable workOrders_entityCreate!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityEdit!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityDelete!: WorkOrderCreateDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, TemplateRecordDto>;
    @observable workOrders_entityReturn!: WorkOrderReturnDto<WorkOrderEntityDto, WorkOrderItemDto, WorkOrderOptionDto, WorkOrderRecordDto, WorkOrderStateDto, WorkOrderTypeDto, WorkOrderTemplateDto, NoteDto, NoteRecordDto, ValueReturnDto, TemplateRecordDto>;

    @observable workOrders_entitySummary!: PagedResultDto<any>;
    @observable workOrders_entities!: PagedResultDto<any>;
    @observable workOrders_entitiesList!: PagedResultDto<any>;
    @observable workOrders_entitySummaryCondensed!: PagedResultDto<any>;
    @observable myWorkOrders_entitySummary!: PagedResultDto<any>;
    @observable relatedWorkOrders_entitySummary!: PagedResultDto<any>;

    @observable typeItemsEdit!: PagedResultDto<any>;
    //--------------------------------------------------------------------------

    @observable areaChart_Configuration!: AreaChart_Configuration<AreaChart_DataModel, AxisModel>;
    @observable waterfallChart_Configuration!: WaterfallChart_Configuration<WaterfallChart_DataModel>;
    @observable violinPlot_Configuration!: ViolinPlot_Configuration<ViolinPlot_DataModel>;
    @observable vennPlot_Configuration!: VennPlot_Configuration<VennPlot_DataModel>;
    @observable sunburstPlot_Configuration!: SunburstPlot_Configuration<SunburstPlot_DataModel>;
    @observable stockPlot_Configuration!: StockPlot_Configuration<StockPlot_DataModel>;
    @observable scatterPlot_Configuration!: ScatterPlot_Configuration<ScatterPlot_DataModel>;
    @observable sankeyPlot_Configuration!: SankeyPlot_Configuration<SankeyPlot_DataModel>;
    @observable roseChart_Configuration!: RoseChart_Configuration<RoseChart_DataModel>;
    @observable ring_Configuration!: Ring_Configuration;
    @observable radialBarChart_Configuration!: RadialBarChart_Configuration<RadialBarChart_DataModel>;
    @observable radarChart_Configuration!: RadarChart_Configuration;
    @observable progress_Configuration!: Progress_Configuration;
    @observable pieChart_Configuration!: PieChart_Configuration<PieChart_DataModel>;
    @observable multiLineChart_Configuration!: MultiLineChart_Configuration<MultiLineChart_DataModel>;
    @observable liquid_Configuration!: Liquid_Configuration;
    @observable lineChart_Configuration!: LineChart_Configuration<LineChart_DataModel>;
    @observable histogram_Configuration!: Histogram_Configuration<Histogram_DataModel>;
    @observable heatMap_Configuration!: HeatMap_Configuration<HeatMap_DataModel>;
    @observable gauge_Configuration!: Gauge_Configuration;
    @observable funnelChart_Configuration!: FunnelChart_Configuration<FunnelChart_DataModel>;
    @observable donutChart_Configuration!: DonutChart_Configuration<DonutChart_DataModel>;
    @observable columnGraph_Configuration!: ColumnGraph_Configuration<ColumnGraph_DataModel>;
    @observable circlePacking_Configuration!: CirclePacking_Configuration<CirclePacking_DataModel<CirclePacking_DataModelChildren>>;
    @observable bullet_Configuration!: Bullet_Configuration<Bullet_DataModel>;
    @observable barChart_Configuration!: BarChart_Configuration<BarChart_DataModel>;
    @observable boxPlot_Configuration!: BoxPlot_Configuration<BoxPlot_DataModel, BoxStyleModel>;

    //-------------------------------------------------------------------------- 

    @observable communication_multiEntityList!: PagedResultDto<any>;

    @observable communication_entityOptionSummary!: PagedResultDto<any>;
    @observable communication_entityOptions!: PagedResultDto<any>;
    @observable communication_optionsList!: PagedResultDto<any>;

    @observable communication_entityStateSummary!: PagedResultDto<any>;
    @observable communication_entityStates!: PagedResultDto<any>;
    @observable communication_statesList!: PagedResultDto<any>;

    @observable communication_entityItemSummary!: PagedResultDto<any>;
    @observable communication_entityItems!: PagedResultDto<any>;
    @observable communication_itemsList!: PagedResultDto<any>;
    @observable communication_itemOptionsCache!: PagedResultDto<any>;

    @observable communication_entityTypeSummary!: PagedResultDto<any>;
    @observable communication_entityTypes!: PagedResultDto<any>;
    @observable communication_typesList!: PagedResultDto<any>;
    @observable communication_typeItemsCache!: PagedResultDto<any>;
    @observable communication_typeStatesCache!: PagedResultDto<any>;
    @observable communication_typeItemsEdit!: PagedResultDto<any>;
    @observable communication_typeStatesEdit!: PagedResultDto<any>;

    @observable communication_entityTemplateSummary!: PagedResultDto<any>;
    @observable communication_entityTemplates!: PagedResultDto<any>;
    @observable communication_templatesList!: PagedResultDto<any>;
    @observable communication_templateItems!: PagedResultDto<any>;
    @observable communication_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable communication_templateRecordCache!: Array<TemplateRecordDto>;

    @observable communication_defaultValues!: FormGridDto[];
    @observable communication_valuesCache!: FormGridDto[];

    //-------------------------------------------------------------------------- 

    @observable integrations_multiEntityList!: PagedResultDto<any>;

    @observable integrations_entityOptionSummary!: PagedResultDto<any>;
    @observable integrations_entityOptions!: PagedResultDto<any>;
    @observable integrations_optionsList!: PagedResultDto<any>;

    @observable integrations_entityStateSummary!: PagedResultDto<any>;
    @observable integrations_entityStates!: PagedResultDto<any>;
    @observable integrations_statesList!: PagedResultDto<any>;

    @observable integrations_entityItemSummary!: PagedResultDto<any>;
    @observable integrations_entityItems!: PagedResultDto<any>;
    @observable integrations_itemsList!: PagedResultDto<any>;
    @observable integrations_itemOptionsCache!: PagedResultDto<any>;

    @observable integrations_entityTypeSummary!: PagedResultDto<any>;
    @observable integrations_entityTypes!: PagedResultDto<any>;
    @observable integrations_typesList!: PagedResultDto<any>;
    @observable integrations_typeItemsCache!: PagedResultDto<any>;
    @observable integrations_typeStatesCache!: PagedResultDto<any>;
    @observable integrations_typeItemsEdit!: PagedResultDto<any>;
    @observable integrations_typeStatesEdit!: PagedResultDto<any>;

    @observable integrations_entityTemplateSummary!: PagedResultDto<any>;
    @observable integrations_entityTemplates!: PagedResultDto<any>;
    @observable integrations_templatesList!: PagedResultDto<any>;
    @observable integrations_templateItems!: PagedResultDto<any>;
    @observable integrations_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable integrations_templateRecordCache!: Array<TemplateRecordDto>;

    @observable integrations_defaultValues!: FormGridDto[];
    @observable integrations_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------


    @observable inventory_multiEntityList!: PagedResultDto<any>;

    @observable inventory_entityOptionSummary!: PagedResultDto<any>;
    @observable inventory_entityOptions!: PagedResultDto<any>;
    @observable inventory_optionsList!: PagedResultDto<any>;

    @observable inventory_entityStateSummary!: PagedResultDto<any>;
    @observable inventory_entityStates!: PagedResultDto<any>;
    @observable inventory_statesList!: PagedResultDto<any>;

    @observable inventory_entityItemSummary!: PagedResultDto<any>;
    @observable inventory_entityItems!: PagedResultDto<any>;
    @observable inventory_itemsList!: PagedResultDto<any>;
    @observable inventory_itemOptionsCache!: PagedResultDto<any>;

    @observable inventory_entityTypeSummary!: PagedResultDto<any>;
    @observable inventory_entityTypes!: PagedResultDto<any>;
    @observable inventory_typesList!: PagedResultDto<any>;
    @observable inventory_typeItemsCache!: PagedResultDto<any>;
    @observable inventory_typeStatesCache!: PagedResultDto<any>;
    @observable inventory_typeItemsEdit!: PagedResultDto<any>;
    @observable inventory_typeStatesEdit!: PagedResultDto<any>;

    @observable inventory_entityTemplateSummary!: PagedResultDto<any>;
    @observable inventory_entityTemplates!: PagedResultDto<any>;
    @observable inventory_templatesList!: PagedResultDto<any>;
    @observable inventory_templateItems!: PagedResultDto<any>;
    @observable inventory_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable templateRecordCache!: Array<TemplateRecordDto>;

    @observable inventory_defaultValues!: FormGridDto[];
    @observable inventory_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable menu_multiEntityList!: PagedResultDto<any>;

    @observable menu_entityOptionSummary!: PagedResultDto<any>;
    @observable menu_entityOptions!: PagedResultDto<any>;
    @observable menu_optionsList!: PagedResultDto<any>;

    @observable menu_entityStateSummary!: PagedResultDto<any>;
    @observable menu_entityStates!: PagedResultDto<any>;
    @observable menu_statesList!: PagedResultDto<any>;

    @observable menu_entityItemSummary!: PagedResultDto<any>;
    @observable menu_entityItems!: PagedResultDto<any>;
    @observable menu_itemsList!: PagedResultDto<any>;
    @observable menu_itemOptionsCache!: PagedResultDto<any>;

    @observable menu_entityTypeSummary!: PagedResultDto<any>;
    @observable menu_entityTypes!: PagedResultDto<any>;
    @observable menu_typesList!: PagedResultDto<any>;
    @observable menu_typeItemsCache!: PagedResultDto<any>;
    @observable menu_typeStatesCache!: PagedResultDto<any>;
    @observable menu_typeItemsEdit!: PagedResultDto<any>;
    @observable menu_typeStatesEdit!: PagedResultDto<any>;

    @observable menu_entityTemplateSummary!: PagedResultDto<any>;
    @observable menu_entityTemplates!: PagedResultDto<any>;
    @observable menu_templatesList!: PagedResultDto<any>;
    @observable menu_templateItems!: PagedResultDto<any>;
    @observable menu_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable menu_templateRecordCache!: Array<TemplateRecordDto>;

    @observable menu_defaultValues!: FormGridDto[];
    @observable menu_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable payments_multiEntityList!: PagedResultDto<any>;

    @observable payments_entityOptionSummary!: PagedResultDto<any>;
    @observable payments_entityOptions!: PagedResultDto<any>;
    @observable payments_optionsList!: PagedResultDto<any>;

    @observable payments_entityStateSummary!: PagedResultDto<any>;
    @observable payments_entityStates!: PagedResultDto<any>;
    @observable payments_statesList!: PagedResultDto<any>;

    @observable payments_entityItemSummary!: PagedResultDto<any>;
    @observable payments_entityItems!: PagedResultDto<any>;
    @observable payments_itemsList!: PagedResultDto<any>;
    @observable payments_itemOptionsCache!: PagedResultDto<any>;

    @observable payments_entityTypeSummary!: PagedResultDto<any>;
    @observable payments_entityTypes!: PagedResultDto<any>;
    @observable payments_typesList!: PagedResultDto<any>;
    @observable payments_typeItemsCache!: PagedResultDto<any>;
    @observable payments_typeStatesCache!: PagedResultDto<any>;
    @observable payments_typeItemsEdit!: PagedResultDto<any>;
    @observable payments_typeStatesEdit!: PagedResultDto<any>;

    @observable payments_entityTemplateSummary!: PagedResultDto<any>;
    @observable payments_entityTemplates!: PagedResultDto<any>;
    @observable payments_templatesList!: PagedResultDto<any>;
    @observable payments_templateItems!: PagedResultDto<any>;
    @observable payments_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable payments_templateRecordCache!: Array<TemplateRecordDto>;

    @observable payments_defaultValues!: FormGridDto[];
    @observable payments_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable people_multiEntityList!: PagedResultDto<any>;

    @observable people_entityOptionSummary!: PagedResultDto<any>;
    @observable people_entityOptions!: PagedResultDto<any>;
    @observable people_optionsList!: PagedResultDto<any>;

    @observable people_entityStateSummary!: PagedResultDto<any>;
    @observable people_entityStates!: PagedResultDto<any>;
    @observable people_statesList!: PagedResultDto<any>;

    @observable people_entityItemSummary!: PagedResultDto<any>;
    @observable people_entityItems!: PagedResultDto<any>;
    @observable people_itemsList!: PagedResultDto<any>;
    @observable people_itemOptionsCache!: PagedResultDto<any>;

    @observable people_entityTypeSummary!: PagedResultDto<any>;
    @observable people_entityTypes!: PagedResultDto<any>;
    @observable people_typesList!: PagedResultDto<any>;
    @observable people_typeItemsCache!: PagedResultDto<any>;
    @observable people_typeStatesCache!: PagedResultDto<any>;
    @observable people_typeItemsEdit!: PagedResultDto<any>;
    @observable people_typeStatesEdit!: PagedResultDto<any>;

    @observable people_entityTemplateSummary!: PagedResultDto<any>;
    @observable people_entityTemplates!: PagedResultDto<any>;
    @observable people_templatesList!: PagedResultDto<any>;
    @observable people_templateItems!: PagedResultDto<any>;
    @observable people_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable people_templateRecordCache!: Array<TemplateRecordDto>;

    @observable people_defaultValues!: FormGridDto[];
    @observable people_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable reports_multiEntityList!: PagedResultDto<any>;

    @observable reports_entityOptionSummary!: PagedResultDto<any>;
    @observable reports_entityOptions!: PagedResultDto<any>;
    @observable reports_optionsList!: PagedResultDto<any>;

    @observable reports_entityStateSummary!: PagedResultDto<any>;
    @observable reports_entityStates!: PagedResultDto<any>;
    @observable reports_statesList!: PagedResultDto<any>;

    @observable reports_entityItemSummary!: PagedResultDto<any>;
    @observable reports_entityItems!: PagedResultDto<any>;
    @observable reports_itemsList!: PagedResultDto<any>;
    @observable reports_itemOptionsCache!: PagedResultDto<any>;

    @observable reports_entityTypeSummary!: PagedResultDto<any>;
    @observable reports_entityTypes!: PagedResultDto<any>;
    @observable reports_typesList!: PagedResultDto<any>;
    @observable reports_typeItemsCache!: PagedResultDto<any>;
    @observable reports_typeStatesCache!: PagedResultDto<any>;
    @observable reports_typeItemsEdit!: PagedResultDto<any>;
    @observable reports_typeStatesEdit!: PagedResultDto<any>;

    @observable reports_entityTemplateSummary!: PagedResultDto<any>;
    @observable reports_entityTemplates!: PagedResultDto<any>;
    @observable reports_templatesList!: PagedResultDto<any>;
    @observable reports_templateItems!: PagedResultDto<any>;
    @observable reports_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable reports_templateRecordCache!: Array<TemplateRecordDto>;

    @observable reports_defaultValues!: FormGridDto[];
    @observable reports_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable scheduling_multiEntityList!: PagedResultDto<any>;

    @observable scheduling_entityOptionSummary!: PagedResultDto<any>;
    @observable scheduling_entityOptions!: PagedResultDto<any>;
    @observable scheduling_optionsList!: PagedResultDto<any>;

    @observable scheduling_entityStateSummary!: PagedResultDto<any>;
    @observable scheduling_entityStates!: PagedResultDto<any>;
    @observable scheduling_statesList!: PagedResultDto<any>;

    @observable scheduling_entityItemSummary!: PagedResultDto<any>;
    @observable scheduling_entityItems!: PagedResultDto<any>;
    @observable scheduling_itemsList!: PagedResultDto<any>;
    @observable scheduling_itemOptionsCache!: PagedResultDto<any>;

    @observable scheduling_entityTypeSummary!: PagedResultDto<any>;
    @observable scheduling_entityTypes!: PagedResultDto<any>;
    @observable scheduling_typesList!: PagedResultDto<any>;
    @observable scheduling_typeItemsCache!: PagedResultDto<any>;
    @observable scheduling_typeStatesCache!: PagedResultDto<any>;
    @observable scheduling_typeItemsEdit!: PagedResultDto<any>;
    @observable scheduling_typeStatesEdit!: PagedResultDto<any>;

    @observable scheduling_entityTemplateSummary!: PagedResultDto<any>;
    @observable scheduling_entityTemplates!: PagedResultDto<any>;
    @observable scheduling_templatesList!: PagedResultDto<any>;
    @observable scheduling_templateItems!: PagedResultDto<any>;
    @observable scheduling_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable scheduling_templateRecordCache!: Array<TemplateRecordDto>;

    @observable scheduling_defaultValues!: FormGridDto[];
    @observable scheduling_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable teams_multiEntityList!: PagedResultDto<any>;

    @observable teams_entityOptionSummary!: PagedResultDto<any>;
    @observable teams_entityOptions!: PagedResultDto<any>;
    @observable teams_optionsList!: PagedResultDto<any>;

    @observable teams_entityStateSummary!: PagedResultDto<any>;
    @observable teams_entityStates!: PagedResultDto<any>;
    @observable teams_statesList!: PagedResultDto<any>;

    @observable teams_entityItemSummary!: PagedResultDto<any>;
    @observable teams_entityItems!: PagedResultDto<any>;
    @observable teams_itemsList!: PagedResultDto<any>;
    @observable teams_itemOptionsCache!: PagedResultDto<any>;

    @observable teams_entityTypeSummary!: PagedResultDto<any>;
    @observable teams_entityTypes!: PagedResultDto<any>;
    @observable teams_typesList!: PagedResultDto<any>;
    @observable teams_typeItemsCache!: PagedResultDto<any>;
    @observable teams_typeStatesCache!: PagedResultDto<any>;
    @observable teams_typeItemsEdit!: PagedResultDto<any>;
    @observable teams_typeStatesEdit!: PagedResultDto<any>;

    @observable teams_entityTemplateSummary!: PagedResultDto<any>;
    @observable teams_entityTemplates!: PagedResultDto<any>;
    @observable teams_templatesList!: PagedResultDto<any>;
    @observable teams_templateItems!: PagedResultDto<any>;
    @observable teams_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable teams_templateRecordCache!: Array<TemplateRecordDto>;

    @observable teams_defaultValues!: FormGridDto[];
    @observable teams_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    @observable workOrders_multiEntityList!: PagedResultDto<any>;

    @observable workOrders_entityOptionSummary!: PagedResultDto<any>;
    @observable workOrders_entityOptions!: PagedResultDto<any>;
    @observable workOrders_optionsList!: PagedResultDto<any>;

    @observable workOrders_entityStateSummary!: PagedResultDto<any>;
    @observable workOrders_entityStates!: PagedResultDto<any>;
    @observable workOrders_statesList!: PagedResultDto<any>;

    @observable workOrders_entityItemSummary!: PagedResultDto<any>;
    @observable workOrders_entityItems!: PagedResultDto<any>;
    @observable workOrders_itemsList!: PagedResultDto<any>;
    @observable workOrders_itemOptionsCache!: PagedResultDto<any>;

    @observable workOrders_entityTypeSummary!: PagedResultDto<any>;
    @observable workOrders_entityTypes!: PagedResultDto<any>;
    @observable workOrders_typesList!: PagedResultDto<any>;
    @observable workOrders_typeItemsCache!: PagedResultDto<any>;
    @observable workOrders_typeStatesCache!: PagedResultDto<any>;
    @observable workOrders_typeItemsEdit!: PagedResultDto<any>;
    @observable workOrders_typeStatesEdit!: PagedResultDto<any>;

    @observable workOrders_entityTemplateSummary!: PagedResultDto<any>;
    @observable workOrders_entityTemplates!: PagedResultDto<any>;
    @observable workOrders_templatesList!: PagedResultDto<any>;
    @observable workOrders_templateItems!: PagedResultDto<any>;
    @observable workOrders_defaultTemplateRecords!: Array<TemplateRecordDto>;
    @observable workOrders_templateRecordCache!: Array<TemplateRecordDto>;

    @observable workOrders_defaultValues!: FormGridDto[];
    @observable workOrders_valuesCache!: FormGridDto[];

    //--------------------------------------------------------------------------

    //Stored as a group, filtered on retrieval, maybe via State within EntityTabView
    @observable cards_CommentData!: Array<any>;
    @observable cards_BodyData!: Array<any>;
    @observable cards_HeaderData!: Array<any>;
    @observable cards_TagData!: Array<any>;

    @observable cards_BodyConfig!: Array<any>;
    @observable cards_HeaderConfig!: Array<any>;
    @observable cards_TagConfig!: Array<any>;

    //--------------------------------------------------------------------------

    @observable defaultEntityImages!: Array<UploadFile>;
    @observable defaultEntityFiles!: Array<UploadFile>;
    @observable fileCache!: EntityCommitContainer;

    //--------------------------------------------------------------------------

    @observable entitySummaryList!: Array<any>;
    @observable templateSummaryList!: Array<any>;
    @observable typeSummaryList!: Array<any>;
    @observable fieldSummaryList!: Array<any>;
    @observable statesSummaryList!: Array<any>;
    @observable peopleSummaryList!: Array<any>;
    @observable teamSummaryList!: Array<any>;
    @observable communicationSummaryList!: Array<any>;

    //--------------------------------------------------------------------------

    @observable profileID: number = 0;
    @observable entityID: number = 0;
    @observable entityType: number = 0;
    @observable currentState: number = 0;
    @observable defaultEntityNotes!: Array<NoteDto>;
    @observable defaultEntityNoteRecords!: Array<NoteRecordDto>;
    @observable currentNotifications: NotificationDto[] = [];
    @observable entityNotesCache!: Array<NoteDto>;
    @observable entityNoteRecordsCache!: Array<NoteRecordDto>;
    @observable entityNotesSummary!: PagedResultDto<NoteSummaryDto>;

    @observable users!: PagedResultDto<UserListSummaryDto>;
    @observable defaultUserList!: Array<UserListSummaryDto>;
    @observable userListCache!: Array<UserListSummaryDto>;
    @observable userListSummary!: PagedResultDto<UserListSummaryDto>;

    @observable users_Security!: PagedResultDto<UserListSummaryDto>;
    @observable defaultUserList_Security!: Array<UserListSummaryDto>;
    @observable userListCache_Security!: Array<UserListSummaryDto>;
    @observable userListSummary_Security!: PagedResultDto<UserListSummaryDto>;

    @observable docs!: PagedResultDto<any>;
    @observable defaultDocsList!: Array<any>;
    @observable docListCache!: Array<any>;
    @observable docListSummary!: PagedResultDto<any>;

    @observable persons!: PagedResultDto<PersonListSummaryDto>;
    @observable defaultPersonList!: Array<PersonListSummaryDto>;
    @observable personListCache!: Array<PersonListSummaryDto>;
    @observable personListSummary!: PagedResultDto<PersonListSummaryDto>;

    @observable persons_Security!: PagedResultDto<PersonListSummaryDto>;
    @observable defaultPersonList_Security!: Array<PersonListSummaryDto>;
    @observable personListCache_Security!: Array<PersonListSummaryDto>;
    @observable personListSummary_Security!: PagedResultDto<PersonListSummaryDto>;

    @observable teams!: PagedResultDto<TeamListSummaryDto>;
    @observable defaultTeamList!: Array<TeamListSummaryDto>;
    @observable teamListCache!: Array<TeamListSummaryDto>;
    @observable teamListSummary!: PagedResultDto<TeamListSummaryDto>;

    @observable teams_Security!: PagedResultDto<TeamListSummaryDto>;
    @observable defaultTeamList_Security!: Array<TeamListSummaryDto>;
    @observable teamListCache_Security!: Array<TeamListSummaryDto>;
    @observable teamListSummary_Security!: PagedResultDto<TeamListSummaryDto>;

    @observable communication!: PagedResultDto<CommunicationListSummaryDto>;
    @observable defaultCommunicationList!: Array<CommunicationListSummaryDto>;
    @observable communicationListCache!: Array<CommunicationListSummaryDto>;
    @observable communicationListSummary!: PagedResultDto<CommunicationListSummaryDto>;

    @observable inventory!: PagedResultDto<InventoryListSummaryDto>;
    @observable defaultInventoryList!: Array<InventoryListSummaryDto>;
    @observable inventoryListCache!: Array<InventoryListSummaryDto>;
    @observable inventoryListSummary!: PagedResultDto<InventoryListSummaryDto>;

    @observable menus!: PagedResultDto<MenuListSummaryDto>;
    @observable defaultMenuList!: Array<MenuListSummaryDto>;
    @observable menuListCache!: Array<MenuListSummaryDto>;
    @observable menuListSummary!: PagedResultDto<MenuListSummaryDto>;

    @observable payments!: PagedResultDto<PaymentsListSummaryDto>;
    @observable defaultPaymentsList!: Array<PaymentsListSummaryDto>;
    @observable paymentsListCache!: Array<PaymentsListSummaryDto>;
    @observable paymentsListSummary!: PagedResultDto<PaymentsListSummaryDto>;

    @observable integrations!: PagedResultDto<IntegrationListSummaryDto>;
    @observable defaultIntegrationsList!: Array<IntegrationListSummaryDto>;
    @observable integrationsListCache!: Array<IntegrationListSummaryDto>;
    @observable integrationsListSummary!: PagedResultDto<IntegrationListSummaryDto>;

    @observable reports!: PagedResultDto<ReportsListSummaryDto>;
    @observable defaultReportsList!: Array<ReportsListSummaryDto>;
    @observable reportsListCache!: Array<ReportsListSummaryDto>;
    @observable reportsListSummary!: PagedResultDto<ReportsListSummaryDto>;

    @observable schedules!: PagedResultDto<SchedulingListSummaryDto>;
    @observable defaultSchedulesList!: Array<SchedulingListSummaryDto>;
    @observable schedulesListCache!: Array<SchedulingListSummaryDto>;
    @observable schedulesListSummary!: PagedResultDto<SchedulingListSummaryDto>;

    @observable workOrders!: PagedResultDto<WorkOrderListSummaryDto>;
    @observable defaultWorkOrdersList!: Array<WorkOrderListSummaryDto>;
    @observable workOrdersListCache!: Array<WorkOrderListSummaryDto>;
    @observable workOrdersListSummary!: PagedResultDto<WorkOrderListSummaryDto>;

    @observable rolesSummary!: PagedResultDto<GetAllRoleOutput>;
    @observable usersearch_Mentions: any[] = [];
    @observable usersearch_Hashtags: any[] = [];
    @observable personsearch_Mentions: any[] = [];
    @observable personrsearch_Hashtags: any[] = [];
    @observable docsearch_Mentions: any[] = [];
    @observable docsearch_Hashtags: any[] = [];
    @observable teamsearch_Mentions: any[] = [];
    @observable teamsearch_Hashtags: any[] = [];
    @observable communicationsearch_Mentions: any[] = [];
    @observable communicationsearch_Hashtags: any[] = [];
    @observable inventorysearch_Mentions: any[] = [];
    @observable inventorysearch_Hashtags: any[] = [];
    @observable menusearch_Mentions: any[] = [];
    @observable menusearch_Hashtags: any[] = [];
    @observable paymentsearch_Mentions: any[] = [];
    @observable paymentearch_Hashtags: any[] = [];
    @observable reportsearch_Mentions: any[] = [];
    @observable reportsearch_Hashtags: any[] = [];
    @observable schedulingsearch_Mentions: any[] = [];
    @observable schedulingsearch_Hashtags: any[] = [];
    @observable workordersearch_Mentions: any[] = [];
    @observable workordersearch_Hashtags: any[] = [];
    @observable itemsearch_Mentions: any[] = [];
    @observable optionsearch_Mentions: any[] = [];
    @observable statesearch_Mentions: any[] = [];
    @observable templatesearch_Mentions: any[] = [];
    @observable typesearch_Mentions: any[] = [];
    @observable itemsearch_Hashtags: any[] = [];
    @observable optionsearch_Hashtags: any[] = [];
    @observable statesearch_Hashtags: any[] = [];
    @observable templatesearch_Hashtags: any[] = [];
    @observable typesearch_Hashtags: any[] = [];

    @observable communication_entitysearch_Hashtags: any[] = [];
    @observable communication_entitysearch_Mentions: any[] = [];
    @observable integrations_entitysearch_Hashtags: any[] = [];
    @observable integrations_entitysearch_Mentions: any[] = [];
    @observable inventory_entitysearch_Hashtags: any[] = [];
    @observable inventory_entitysearch_Mentions: any[] = [];
    @observable menu_entitysearch_Hashtags: any[] = [];
    @observable menu_entitysearch_Mentions: any[] = [];
    @observable payments_entitysearch_Hashtags: any[] = [];
    @observable payments_entitysearch_Mentions: any[] = [];
    @observable people_entitysearch_Hashtags: any[] = [];
    @observable people_entitysearch_Mentions: any[] = [];
    @observable reports_entitysearch_Hashtags: any[] = [];
    @observable reports_entitysearch_Mentions: any[] = [];
    @observable scheduling_entitysearch_Hashtags: any[] = [];
    @observable scheduling_entitysearch_Mentions: any[] = [];
    @observable teams_entitysearch_Hashtags: any[] = [];
    @observable teams_entitysearch_Mentions: any[] = [];
    @observable workOrders_entitysearch_Hashtags: any[] = [];
    @observable workOrders_entitysearch_Mentions: any[] = [];

    //--------------------------------------------------------------------------

    @action
    async menu_createEntity() {

        let result = await menuService.create(this.menu_entityCreate);
        this.menu_entityReturn = result;
   
        //let entityRecords = this.menu_entityReturn.records;
        this.menu_entityCreate.records = [];
        this.menu_entityDelete.records = [];

        //this.syncUsers(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncCommunication(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncDocs(x.id, entityRecords, itemTypes.Menu);
        //this.syncInventory(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncMenus(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncPayments(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncPeople(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncReports(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncSchedules(xthis.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncTeams(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncWorkOrders(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncMenus(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
   
   
   
   
    }

    @action
    async menu_updateEntity() {

        let result = await menuService.update(this.menu_entityCreate);
        this.menu_entityReturn = result;
 
        //let entityRecords = this.menu_entityReturn.records;
        this.menu_entityCreate.records = [];
        this.menu_entityDelete.records = [];

        //this.syncUsers(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncCommunication(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncDocs(x.id, entityRecords, itemTypes.Menu);
        //this.syncInventory(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncMenus(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncPayments(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncPeople(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncReports(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncSchedules(xthis.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncTeams(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncWorkOrders(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
        //this.syncMenus(this.menu_entityReturn.entities[0].id, entityRecords, itemTypes.Menu);
    
    }

    @action
    async menu_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.menu_entityReturn = await menuService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.menu_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async menu_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.menu_entityReturn = await menuService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

    }

    @action
    async menu_updateItemsList(updateMemberList: any) {
        this.menu_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async menu_updateStatesList(updateMemberList: any) {
        this.menu_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async communication_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.communication_entityReturn = await communicationService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.communication_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });

    }

    @action
    async communication_createEntity() {

        let result = await communicationService.create(this.communication_entityCreate);
        this.communication_entityReturn = result;


        //let entityRecords = this.communication_entityReturn.records;
        this.communication_entityCreate.records = [];
        this.communication_entityDelete.records = [];

        //this.syncUsers(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncCommunication(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncDocs(x.id, entityRecords, itemTypes.Communication);
        //this.syncInventory(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncMenus(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncPayments(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncPeople(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncReports(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncSchedules(xthis.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncTeams(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncWorkOrders(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncMenus(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
   


    }

    @action
    async communication_updateEntity() {

        let result = await communicationService.update(this.communication_entityCreate);
        this.communication_entityReturn = result;
         
        //let entityRecords = this.communication_entityReturn.records;
        this.communication_entityCreate.records = [];
        this.communication_entityDelete.records = [];

        //this.syncUsers(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncCommunication(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncDocs(x.id, entityRecords, itemTypes.Communication);
        //this.syncInventory(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncMenus(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncPayments(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncPeople(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncReports(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncSchedules(xthis.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncTeams(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.syncWorkOrders(this.communication_entityReturn.entities[0].id, entityRecords, itemTypes.Communication);
        //this.sy
    }

    @action
    async communication_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.communication_entityReturn = await communicationService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async communication_updateItemsList(updateMemberList: any) {
        this.communication_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async communication_updateStatesList(updateMemberList: any) {
        this.communication_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async people_createEntity() {

        let result = await peopleService.create(this.people_entityCreate);
        this.people_entityReturn = result;

        //let entityRecords = this.people_entityReturn.records;
        this.people_entityCreate.records = [];
        this.people_entityDelete.records = [];

        //this.syncUsers(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncCommunication(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncDocs(x.id, entityRecords, itemTypes.Persons);
        //this.syncInventory(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncMenus(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncPayments(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncPeople(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncReports(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncSchedules(xthis.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncTeams(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncWorkOrders(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncMenus(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
   


    }

    @action
    async people_updateEntity() {

        let result = await peopleService.update(this.people_entityCreate);
        this.people_entityReturn = result;
        
        //let entityRecords = this.people_entityReturn.records;
        this.people_entityCreate.records = [];
        this.people_entityDelete.records = [];

        //this.syncUsers(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncCommunication(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncDocs(x.id, entityRecords, itemTypes.Persons);
        //this.syncInventory(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncMenus(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncPayments(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncPeople(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncReports(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncSchedules(xthis.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncTeams(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncWorkOrders(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
        //this.syncMenus(this.people_entityReturn.entities[0].id, entityRecords, itemTypes.Persons);
   
    }

    @action
    async people_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();


        this.people_entityReturn = await peopleService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.people_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async people_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();


        this.people_entityReturn = await peopleService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

    }

    @action
    async people_updateItemsList(updateMemberList: any) {
        this.people_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async people_updateStatesList(updateMemberList: any) {
        this.people_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async teams_createEntity() {

        let result = await teamService.create(this.teams_entityCreate);
        this.teams_entityReturn = result;
 
        //let entityRecords = this.teams_entityReturn.records;
        this.teams_entityCreate.records = [];
        this.teams_entityDelete.records = [];

        //this.syncUsers(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncCommunication(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncDocs(x.id, entityRecords, itemTypes.Teams);
        //this.syncInventory(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncMenus(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncPayments(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncPeople(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncReports(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncSchedules(xthis.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncTeams(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncWorkOrders(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncMenus(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
   
    }

    @action
    async teams_updateEntity() {

        let result = await teamService.update(this.teams_entityCreate);
        this.teams_entityReturn = result;
        
        //let entityRecords = this.teams_entityReturn.records;
        this.teams_entityCreate.records = [];
        this.teams_entityDelete.records = [];

        //this.syncUsers(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncCommunication(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncDocs(x.id, entityRecords, itemTypes.Teams);
        //this.syncInventory(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncMenus(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncPayments(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncPeople(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncReports(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncSchedules(xthis.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncTeams(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncWorkOrders(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
        //this.syncMenus(this.teams_entityReturn.entities[0].id, entityRecords, itemTypes.Teams);
   
    }


    @action
    async teams_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.teams_entityReturn = await teamService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.teams_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async teams_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.teams_entityReturn = await teamService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async teams_updateItemsList(updateMemberList: any) {
        this.teams_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async teams_updateStatesList(updateMemberList: any) {
        this.teams_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async integrations_createEntity() {

        let result = await integrationService.create(this.integrations_entityCreate);
        this.integrations_entityReturn = result;
        
        //let entityRecords = this.integrations_entityReturn.records;
        this.integrations_entityCreate.records = [];
        this.integrations_entityDelete.records = [];

        //this.syncUsers(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncCommunication(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncDocs(x.id, entityRecords, itemTypes.Integrations);
        //this.syncInventory(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncMenus(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncPayments(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncPeople(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncReports(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncSchedules(xthis.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncTeams(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncWorkOrders(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncMenus(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
   
    }

    @action
    async integrations_updateEntity() {

        let result = await integrationService.update(this.integrations_entityCreate);
        this.integrations_entityReturn = result;
        
        //let entityRecords = this.integrations_entityReturn.records;
        this.integrations_entityCreate.records = [];
        this.integrations_entityDelete.records = [];

        //this.syncUsers(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncCommunication(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncDocs(x.id, entityRecords, itemTypes.Integrations);
        //this.syncInventory(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncMenus(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncPayments(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncPeople(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncReports(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncSchedules(xthis.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncTeams(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncWorkOrders(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
        //this.syncMenus(this.integrations_entityReturn.entities[0].id, entityRecords, itemTypes.Integrations);
   
    }

    @action
    async integrations_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.integrations_entityReturn = await integrationService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.integrations_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async integrations_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.integrations_entityReturn = await integrationService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async integrations_updateItemsList(updateMemberList: any) {
        this.integrations_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async integrations_updateStatesList(updateMemberList: any) {
        this.integrations_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async inventory_createEntity() {

        let result = await inventoryService.create(this.inventory_entityCreate);
        this.inventory_entityReturn = result;
        
        //let entityRecords = this.inventory_entityReturn.records;
        this.inventory_entityCreate.records = [];
        this.inventory_entityDelete.records = [];

        //this.syncUsers(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncCommunication(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncDocs(x.id, entityRecords, itemTypes.Inventory);
        //this.syncInventory(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncMenus(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncPayments(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncPeople(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncReports(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncSchedules(xthis.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncTeams(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncWorkOrders(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncMenus(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
   
    }

    @action
    async inventory_updateEntity() {

        let result = await inventoryService.update(this.inventory_entityCreate);
        this.inventory_entityReturn = result;
        
        //let entityRecords = this.inventory_entityReturn.records;
        this.inventory_entityCreate.records = [];
        this.inventory_entityDelete.records = [];

        //this.syncUsers(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncCommunication(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncDocs(x.id, entityRecords, itemTypes.Inventory);
        //this.syncInventory(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncMenus(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncPayments(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncPeople(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncReports(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncSchedules(xthis.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncTeams(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncWorkOrders(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
        //this.syncMenus(this.inventory_entityReturn.entities[0].id, entityRecords, itemTypes.Inventory);
    }

    @action
    async inventory_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.inventory_entityReturn = await inventoryService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.inventory_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async inventory_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.inventory_entityReturn = await inventoryService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async inventory_updateItemsList(updateMemberList: any) {
        this.inventory_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async inventory_updateStatesList(updateMemberList: any) {
        this.inventory_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async payments_createEntity() {

        let result = await paymentService.create(this.payments_entityCreate);
        this.payments_entityReturn = result;
        
        //let entityRecords = this.payments_entityReturn.records;
        this.payments_entityCreate.records = [];
        this.payments_entityDelete.records = [];

        //this.syncUsers(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncCommunication(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncDocs(x.id, entityRecords, itemTypes.Payments);
        //this.syncInventory(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncMenus(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncPayments(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncPeople(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncReports(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncSchedules(xthis.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncTeams(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncWorkOrders(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncMenus(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
   
    }

    @action
    async payments_updateEntity() {

        let result = await paymentService.update(this.payments_entityCreate);
        this.payments_entityReturn = result;
        
        //let entityRecords = this.payments_entityReturn.records;
        this.payments_entityCreate.records = [];
        this.payments_entityDelete.records = [];

        //this.syncUsers(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncCommunication(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncDocs(x.id, entityRecords, itemTypes.Payments);
        //this.syncInventory(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncMenus(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncPayments(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncPeople(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncReports(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncSchedules(xthis.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncTeams(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncWorkOrders(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
        //this.syncMenus(this.payments_entityReturn.entities[0].id, entityRecords, itemTypes.Payments);
    }

    @action
    async payments_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.payments_entityReturn = await paymentService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.payments_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });
    }

    @action
    async payments_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.payments_entityReturn = await paymentService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async payments_updateItemsList(updateMemberList: any) {
        this.payments_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async payments_updateStatesList(updateMemberList: any) {
        this.payments_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async report_createEntity() {

        let result = await reportService.create(this.reports_entityCreate);
        this.reports_entityReturn = result;
        
        //let entityRecords = this.reports_entityReturn.records;
        this.reports_entityCreate.records = [];
        this.reports_entityDelete.records = [];

        //this.syncUsers(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncCommunication(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncDocs(x.id, entityRecords, itemTypes.Reports);
        //this.syncInventory(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncMenus(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncPayments(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncPeople(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncReports(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncSchedules(xthis.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncTeams(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncWorkOrders(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncMenus(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
   
    }

    @action
    async report_updateEntity() {

        let result = await reportService.update(this.reports_entityCreate);
        this.reports_entityReturn = result;
        
        //let entityRecords = this.reports_entityReturn.records;
        this.reports_entityCreate.records = [];
        this.reports_entityDelete.records = [];

        //this.syncUsers(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncCommunication(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncDocs(x.id, entityRecords, itemTypes.Reports);
        //this.syncInventory(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncMenus(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncPayments(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncPeople(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncReports(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncSchedules(xthis.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncTeams(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncWorkOrders(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
        //this.syncMenus(this.reports_entityReturn.entities[0].id, entityRecords, itemTypes.Reports);
    }

    @action
    async report_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();


        this.reports_entityReturn = await reportService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });



    }

    @action
    async report_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();


        this.reports_entityReturn = await reportService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

    }

    @action
    async report_updateItemsList(updateMemberList: any) {
        this.reports_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async report_updateStatesList(updateMemberList: any) {
        this.reports_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async scheduling_createEntity() {

        let result = await schedulingService.create(this.scheduling_entityCreate);
        this.scheduling_entityReturn = result;
       
        let entityRecords = this.scheduling_entityReturn.records;
        this.scheduling_entityCreate.records = [];
        this.scheduling_entityDelete.records = [];

        //this.syncUsers(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        this.syncCommunication(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncDocs(x.id, entityRecords, itemTypes.Schedules);
        //this.syncInventory(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncMenus(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncPayments(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncPeople(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncReports(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncSchedules(xthis.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncTeams(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncWorkOrders(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncMenus(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
    }

    @action
    async scheduling_updateEntity() {

        let result = await schedulingService.update(this.scheduling_entityCreate);
        this.scheduling_entityReturn = result;
        
        let entityRecords = this.scheduling_entityReturn.records;
        this.scheduling_entityCreate.records = [];
        this.scheduling_entityDelete.records = [];

        //this.syncUsers(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        this.syncCommunication(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncDocs(x.id, entityRecords, itemTypes.Schedules);
        //this.syncInventory(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncMenus(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncPayments(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncPeople(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncReports(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncSchedules(xthis.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncTeams(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncWorkOrders(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
        //this.syncMenus(this.scheduling_entityReturn.entities[0].id, entityRecords, itemTypes.Schedules);
    }

    @action
    async scheduling_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.scheduling_entityReturn = await schedulingService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
        console.warn(JSON.stringify(this.scheduling_entityReturn))

        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.scheduling_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });

    }

    @action
    async scheduling_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.scheduling_entityReturn = await schedulingService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async scheduling_updateItemsList(updateMemberList: any) {
        this.scheduling_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async scheduling_updateStatesList(updateMemberList: any) {
        this.scheduling_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async workOrder_createEntity() {

        let result = await workOrderService.create(this.workOrders_entityCreate);
        this.workOrders_entityReturn = result;

        //let entityRecords = this.workOrders_entityReturn.records;
        this.workOrders_entityCreate.records = [];
        this.workOrders_entityDelete.records = [];

        //this.syncUsers(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncCommunication(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncDocs(x.id, entityRecords, itemTypes.WorkOrders);
        //this.syncInventory(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncMenus(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncPayments(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncPeople(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncReports(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncSchedules(xthis.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncTeams(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncWorkOrders(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncMenus(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
   
    }

    @action
    async workOrder_updateEntity() {

        let result = await workOrderService.update(this.workOrders_entityCreate);
        this.workOrders_entityReturn = result;

        //let entityRecords = this.workOrders_entityReturn.records;
        this.workOrders_entityCreate.records = [];
        this.workOrders_entityDelete.records = [];

        //this.syncUsers(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncCommunication(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncDocs(x.id, entityRecords, itemTypes.WorkOrders);
        //this.syncInventory(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncMenus(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncPayments(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncPeople(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncReports(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncSchedules(xthis.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncTeams(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncWorkOrders(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
        //this.syncMenus(this.workOrders_entityReturn.entities[0].id, entityRecords, itemTypes.WorkOrders);
    }

    @action
    async workOrder_getEntity(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Entity;
        //this.getFiles();

        this.workOrders_entityReturn = await workOrderService.get({
            entityType: RelatedEntityTypes.Entity,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });
        this.defaultUserList = [];
        this.userListCache = [];

        this.users.items.map((x: any) => {

            this.workOrders_entityReturn.records.map((y: any) => {
                if (y.linkedEntityType === RelatedEntityTypes.User && y.linkedEntityID === x.userID && y.relatedEntityType === RelatedEntityTypes.Record) {
                    this.userListSummary.items.push(x);
                    this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
                    this.defaultUserList.push(x);
                    this.userListCache.push(x);
                }

            });

        });

    }

    @action
    async workOrder_getEntityTemplate(askDto: EntityDto) {
        this.entityID = askDto.id;
        this.entityType = RelatedEntityTypes.Template;
        //this.getFiles();

        this.workOrders_entityReturn = await workOrderService.get({
            entityType: RelatedEntityTypes.Template,
            entityID: askDto.id,
            filter: '',
            listFilter: false,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


    }

    @action
    async workOrder_updateItemsList(updateMemberList: any) {
        this.workOrders_typeItemsEdit.items.push(updateMemberList);
    }

    @action
    async workOrder_updateStatesList(updateMemberList: any) {
        this.workOrders_typeStatesEdit.items.push(updateMemberList);
    }

    //--------------------------------------------------------------------------

    @action
    async initReportConfiguration(type: number) {

        if (type === DashboardItemTypes.AreaChart) {
            this.areaChart_Configuration =
            {
                data: [],
                xField: '',
                yField: '',
                xAxis: {
                    range: [0, 0],
                    scale: 0
                },
                fill: '',
            };
        }
        else if (type === DashboardItemTypes.BarChart) {
            this.barChart_Configuration = {
                data: [],
                xField: '',
                yField: '',
                seriesField: '',
            };
        }
        else if (type === DashboardItemTypes.BoxPlot) {
            this.boxPlot_Configuration = {
                data: [],
                width: 0,
                height: 0,
                xField: '0',
                yField: ['low', 'q1', 'median', 'q3', 'high'],
                boxStyle: {
                    stroke: '',
                    fill: '',
                    fillOpacity: 0
                }
            };
        }
        else if (type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (type === DashboardItemTypes.Bullet) {
            this.bullet_Configuration = {
                data: [],
                measureField: 'measures',
                rangeField: 'ranges',
                targetField: 'target',
                xField: 'title',
                color: {
                    range: '#f0efff',
                    measure: '#5B8FF9',
                    target: '#3D76DD',
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                legend: {}
            };
        }
        else if (type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (type === DashboardItemTypes.CirclePacking) {
            this.circlePacking_Configuration = {
                autoFit: true,
                data: {
                    name: '',
                    children: []
                },
            };
        }
        else if (type === DashboardItemTypes.ColumnGraph) {
            this.columnGraph_Configuration = {
                data: [],
                xField: '',
                yField: '',
                label: {
                    position: 'middle',
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                },
                meta: {
                    type: {
                        alias: '',
                    },
                    sales: {
                        alias: '',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (type === DashboardItemTypes.DonutChart) {
            this.donutChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 1,
                innerRadius: 0.6,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{value}',
                    style: {
                        textAlign: 'center',
                        fontSize: '1em'
                    },
                },
                interactions: [
                    {
                        type: 'element-selected',
                    },
                    {
                        type: 'element-active',
                    },
                ],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '1em'
                        },
                        content: 'Plot',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.FunnelChart) {
            this.funnelChart_Configuration = {
                data: [],
                xField: 'stage',
                yField: 'number',
            };
        }
        else if (type === DashboardItemTypes.Gauge) {
            this.gauge_Configuration = {

                percent: 0.75,
                range: {
                    color: '#30BF78',
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                axis: {
                    label: {

                    },
                    subTickLine: {
                        count: 3,
                    },
                },
                statistic: {
                    content: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.HeatMap) {
            this.heatMap_Configuration = {
                data: [],
                xField: 'name',
                yField: 'country',
                colorField: 'value',
                shape: 'circle',
                sizeRatio: 0.5,
                color: ['#0d5fbb', '#7eadfc', '#0d5fbb', '#7eadfc'],
                label: {
                    style: {
                        fill: '#fff',
                        shadowBlur: 2,
                        shadowColor: 'rgba(0, 0, 0, .45)',
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.Histogram) {
            this.histogram_Configuration = {
                data: [],
                binField: 'value',
                binWidth: 2,
            }
                ;
        }
        else if (type === DashboardItemTypes.LineChart) {
            this.lineChart_Configuration = {
                data: [],
                padding: 'auto',
                xField: 'Date',
                yField: 'scales',
                annotations: [
                    {
                        type: 'regionFilter',
                        start: ['min', 'median'],
                        end: ['max', '0'],
                        color: '#F4664A',
                    },
                    {
                        type: 'text',
                        position: ['min', 'median'],
                        content: '中位数',
                        offsetY: -4,
                        style: {
                            textBaseline: 'bottom',
                        },
                    },
                    {
                        type: 'line',
                        start: ['min', 'median'],
                        end: ['max', 'median'],
                        style: {
                            stroke: '#F4664A',
                            lineDash: [2, 2],
                        },
                    },
                ],
            }
        }
        else if (type === DashboardItemTypes.Liquid) {
            this.liquid_Configuration = {
                percent: 0.25,
                outline: {
                    border: 4,
                    distance: 8,
                },
                wave: {
                    length: 128,
                },
            };
        }
        else if (type === DashboardItemTypes.MultiLineChart) {
            this.multiLineChart_Configuration = {
                data: [],
                xField: 'year',
                yField: 'value',
                seriesField: 'category',
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    label: {

                    },
                },
            };
        }
        else if (type === DashboardItemTypes.PieChart) {
            this.pieChart_Configuration = {
                appendPadding: 10,
                data: [],
                angleField: 'value',
                colorField: 'type',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [{ type: 'element-active' }],
            };
        }
        else if (type === DashboardItemTypes.Progress) {
            this.progress_Configuration = {
                height: 100,
                width: 300,
                autoFit: false,
                percent: 0.536,
                barWidthRatio: 0.3,
                color: ['#F4664A', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RadarChart) {
            this.radarChart_Configuration = {
                data: [],
                xField: 'name',
                yField: 'star',
                appendPadding: [0, 10, 0, 10],
                meta: {
                    star: {
                        alias: 'star',
                        min: 0,
                        nice: true,

                    },
                },
                xAxis: {
                    tickLine: null,
                },
                yAxis: {
                    label: false,
                    grid: {
                        alternateColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
                point: {
                    size: 2,
                },
                area: {},
            };
        }
        else if (type === DashboardItemTypes.RadialBarChart) {
            this.radialBarChart_Configuration = {
                data: [],
                colorField: 'star',
                barStyle: { lineCap: 'round' },
                xField: 'name',
                yField: 'star',
                radius: 0.8,
                innerRadius: 0.2,
                tooltip: {
                },
            };
        }
        else if (type === DashboardItemTypes.Ring) {
            this.ring_Configuration = {
                height: 100,
                width: 100,
                autoFit: false,
                percent: 0.7,
                color: ['#5B8FF9', '#E8EDF3'],
            };
        }
        else if (type === DashboardItemTypes.RoseChart) {
            this.roseChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'value',
                seriesField: 'type',
                radius: 0.9,
                legend: {
                    position: 'bottom',
                },
            };
        }
        else if (type === DashboardItemTypes.SankeyPlot) {
            this.sankeyPlot_Configuration = {

                data: [],
                sourceField: 'source',
                targetField: 'target',
                weightField: 'value',
                nodeWidthRatio: 0.008,
                nodePaddingRatio: 0.03,
            };
        }
        else if (type === DashboardItemTypes.ScatterPlot) {
            this.scatterPlot_Configuration = {
                appendPadding: 10,
                data: [],
                xField: 'Revenue (Millions)',
                yField: 'Rating',
                shape: 'circle',
                colorField: 'Genre',
                size: 4,
                yAxis: {
                    nice: true,
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
                xAxis: {
                    min: -100,
                    grid: {
                        line: {
                            style: {
                                stroke: '#eee',
                            },
                        },
                    },
                    line: {
                        style: {
                            stroke: '#aaa',
                        },
                    },
                },
            };
        }
        else if (type === DashboardItemTypes.StockPlot) {
            this.stockPlot_Configuration = {
                data: [],
                xField: 'trade_date',
                yField: ['open', 'close', 'high', 'low'],
            };
        }
        else if (type === DashboardItemTypes.SunburstPlot) {
            this.sunburstPlot_Configuration = {
                data: [],
                innerRadius: 0.3,
                interactions: [
                    {
                        type: 'element-active',
                    },
                ],
                hierarchyConfig: {
                    field: 'sum',
                },
                drilldown: {
                    breadCrumb: {
                        rootText: '起始',
                    },
                },
            }
                ;
        }
        else if (type === DashboardItemTypes.VennPlot) {

        }
        else if (type === DashboardItemTypes.ViolinPlot) {
            this.violinPlot_Configuration = {
                height: 500,
                data: [],
                xField: 'x',
                yField: 'y',
            };
        }
        else if (type === DashboardItemTypes.WaterfallChart) {
            this.waterfallChart_Configuration = {
                data: [],
                xField: 'type',
                yField: 'money',
                appendPadding: [15, 0, 0, 0],
                meta: {
                    type: {
                        alias: 'Alias',
                    },
                    money: {

                    },
                },
                label: {
                    style: {
                        fontSize: 10,
                        fill: 'rgba(0,0,0,0.65)',
                    },
                    layout: [
                        {
                            type: 'interval-adjust-position',
                        },
                    ],
                },
                total: {
                    label: 'Label',
                    style: {
                        fill: '#96a6a6',
                    },
                },
            };
        }

        return;
    }

    @action
    async getReport(askDto: DashbordAskDto) {
        if (askDto.type === DashboardItemTypes.AreaChart) {
            let result = await dashboardService.getWidget_AreaChart(askDto);
            this.areaChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BarChart) {
            let result = await dashboardService.getWidget_BarChart(askDto);
            this.barChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BoxPlot) {
            let result = await dashboardService.getWidget_BoxPlot(askDto);
            this.boxPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.BubbleGraph) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.Bullet) {
            let result = await dashboardService.getWidget_Bullet(askDto);
            this.bullet_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Button) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.CirclePacking) {
            let result = await dashboardService.getWidget_CirclePacking(askDto);
            this.circlePacking_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ColumnGraph) {
            let result = await dashboardService.getWidget_ColumnGraph(askDto);
            this.columnGraph_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.DataGrid) {
            // Not Available
        }
        else if (askDto.type === DashboardItemTypes.DonutChart) {
            let result = await dashboardService.getWidget_DonutChart(askDto);
            this.donutChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.FunnelChart) {
            let result = await dashboardService.getWidget_FunnelChart(askDto);
            this.funnelChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Gauge) {
            let result = await dashboardService.getWidget_Gauge(askDto);
            this.gauge_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.HeatMap) {
            let result = await dashboardService.getWidget_HeatMap(askDto);
            this.heatMap_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Histogram) {
            let result = await dashboardService.getWidget_Histogram(askDto);
            this.histogram_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.LineChart) {
            let result = await dashboardService.getWidget_LineChart(askDto);
            this.lineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Liquid) {
            let result = await dashboardService.getWidget_Liquid(askDto);
            this.liquid_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.MultiLineChart) {
            let result = await dashboardService.getWidget_MultiLineChart(askDto);
            this.multiLineChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.PieChart) {
            let result = await dashboardService.getWidget_PieChart(askDto);
            this.pieChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Progress) {
            let result = await dashboardService.getWidget_Progress(askDto);
            this.progress_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadarChart) {
            let result = await dashboardService.getWidget_RadarChart(askDto);
            this.radarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RadialBarChart) {
            let result = await dashboardService.getWidget_RadialBarChart(askDto);
            this.radialBarChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.Ring) {
            let result = await dashboardService.getWidget_Ring(askDto);
            this.ring_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.RoseChart) {
            let result = await dashboardService.getWidget_RoseChart(askDto);
            this.roseChart_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SankeyPlot) {
            let result = await dashboardService.getWidget_SankeyPlot(askDto);
            this.sankeyPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ScatterPlot) {
            let result = await dashboardService.getWidget_ScatterPlot(askDto);
            this.scatterPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.StockPlot) {
            let result = await dashboardService.getWidget_StockPlot(askDto);
            this.stockPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.SunburstPlot) {
            let result = await dashboardService.getWidget_SunburstPlot(askDto);
            this.sunburstPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.VennPlot) {
            let result = await dashboardService.getWidget_VennPlot(askDto);
            this.vennPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.ViolinPlot) {
            let result = await dashboardService.getWidget_ViolinPlot(askDto);
            this.violinPlot_Configuration = result;
        }
        else if (askDto.type === DashboardItemTypes.WaterfallChart) {
            let result = await dashboardService.getWidget_WaterfallChart(askDto);
            this.waterfallChart_Configuration = result;
        }
        return;
    }

    //-------------------------------------------------

    @action
    async initUserDashboard() {
        this.user_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    @action
    async initPublicDashboard() {
        this.public_Dashboard = {
            totalRows: 0,
            rows: []
        };
        return;
    }

    //-------------------------------------------------

    @action
    async getUserDashboard() {
        let result = await dashboardService.getUserDashboard();
        this.user_Dashboard = result;
        return;
    }

    @action
    async getPublicDashboard() {
        let result = await dashboardService.getPublicDashboard();
        this.public_Dashboard = result;
        return;
    }

    //-------------------------------------------------

    @action
    async initUpdateObject() {


        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };


        this.userListCache_Security = [];
        this.userListSummary_Security = {
            totalCount: 0,
            items: []
        };

        this.entityNotesCache = [];
        this.entityNoteRecordsCache = [];
        this.defaultEntityNotes = [];

        this.defaultEntityNoteRecords = [];
        this.entityNotesSummary = {
            totalCount: 0,
            items: []
        }

        this.currentNotifications = [{
            id: 0,
            message: '',
            type: ''
        }];


    }

    @action
    async communication_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {


        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.communication_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.communication_entityStates = {
            totalCount: 0,
            items: []
        };

        this.communication_entityItems = {
            totalCount: 0,
            items: []
        };

        this.communication_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.communication_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityEdit.maxResultCount = maxResultCount;
        this.communication_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityCreate.maxResultCount = maxResultCount;

        this.communication_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.communication_entityDelete.maxResultCount = maxResultCount;

        this.communication_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async integrations_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.integrations_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityStates = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityItems = {
            totalCount: 0,
            items: []
        };

        this.integrations_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.integrations_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityEdit.maxResultCount = maxResultCount;
        this.integrations_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityCreate.maxResultCount = maxResultCount;

        this.integrations_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.integrations_entityDelete.maxResultCount = maxResultCount;

        this.integrations_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async inventory_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.inventory_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityStates = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityItems = {
            totalCount: 0,
            items: []
        };

        this.inventory_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.inventory_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityEdit.maxResultCount = maxResultCount;
        this.inventory_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityCreate.maxResultCount = maxResultCount;

        this.inventory_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.inventory_entityDelete.maxResultCount = maxResultCount;

        this.inventory_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async menu_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.menu_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.menu_entityStates = {
            totalCount: 0,
            items: []
        };

        this.menu_entityItems = {
            totalCount: 0,
            items: []
        };

        this.menu_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.menu_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.menu_entityEdit.maxResultCount = maxResultCount;
        this.menu_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.menu_entityCreate.maxResultCount = maxResultCount;

        this.menu_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        //this.menu_entityDelete.maxResultCount = 500;

        this.menu_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async payments_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.payments_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.payments_entityStates = {
            totalCount: 0,
            items: []
        };

        this.payments_entityItems = {
            totalCount: 0,
            items: []
        };

        this.payments_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.payments_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityEdit.maxResultCount = maxResultCount;
        this.payments_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityCreate.maxResultCount = maxResultCount;

        this.payments_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.payments_entityDelete.maxResultCount = maxResultCount;

        this.payments_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async people_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.people_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.people_entityStates = {
            totalCount: 0,
            items: []
        };

        this.people_entityItems = {
            totalCount: 0,
            items: []
        };

        this.people_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.people_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.people_entityEdit.maxResultCount = maxResultCount;
        this.people_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.people_entityCreate.maxResultCount = maxResultCount;

        this.people_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }


        this.people_entityDelete.maxResultCount = maxResultCount;

        this.people_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

        this.myPersonas_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

        this.myPersonas_HTMLBody = '';

    }

    @action
    async reports_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.reports_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.reports_entityStates = {
            totalCount: 0,
            items: []
        };

        this.reports_entityItems = {
            totalCount: 0,
            items: []
        };

        this.reports_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.reports_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityEdit.maxResultCount = maxResultCount;
        this.reports_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityCreate.maxResultCount = maxResultCount;

        this.reports_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.reports_entityDelete.maxResultCount = maxResultCount;

        this.reports_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async scheduling_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.scheduling_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityStates = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityItems = {
            totalCount: 0,
            items: []
        };

        this.scheduling_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.scheduling_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityEdit.maxResultCount = maxResultCount;
        this.scheduling_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityCreate.maxResultCount = maxResultCount;

        this.scheduling_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.scheduling_entityDelete.maxResultCount = maxResultCount;

        this.scheduling_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    @action
    async teams_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.teams_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.teams_entityStates = {
            totalCount: 0,
            items: []
        };

        this.teams_entityItems = {
            totalCount: 0,
            items: []
        };

        this.teams_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.teams_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityEdit.maxResultCount = maxResultCount;
        this.teams_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityCreate.maxResultCount = maxResultCount;

        this.teams_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.teams_entityDelete.maxResultCount = maxResultCount;

        this.teams_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };


    }

    @action
    async workOrders_initUpdateObject(entityType: number, itemType: number, skipCount: number, maxResultCount: number) {

        this.userListCache = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        };

        this.workOrders_multiEntityList = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityStates = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityItems = {
            totalCount: 0,
            items: []
        };

        this.workOrders_entityOptions = {
            totalCount: 0,
            items: []
        };

        //-------------------------------------------------

        this.workOrders_entityEdit = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityEdit.maxResultCount = maxResultCount;
        this.workOrders_entityCreate = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityCreate.maxResultCount = maxResultCount;

        this.workOrders_entityDelete = {
            relatedEntityType: entityType,
            itemType: itemType,
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        this.workOrders_entityDelete.maxResultCount = maxResultCount;

        this.workOrders_entityReturn = {
            name: '',
            value: '',
            description: '',
            id: 0,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        };

    }

    //-------------------------------------------------

    @action
    async initFileStores() {

        this.defaultEntityImages = [];
        this.defaultEntityFiles = [];
        this.fileCache =
        {
            entityType: 0,
            entityID: 0,
            fileList: []
        };
    }

    @action
    async initCacheStores() {

        this.communication_valuesCache = [];
        this.rolesSummary = {
            totalCount: 0,
            items: []
        };
        this.templateRecordCache = [];

        this.userListCache = [];
        this.defaultUserList = [];
        this.userListSummary = {
            totalCount: 0,
            items: []
        }
        this.userListSummary_Security = {
            totalCount: 0,
            items: []
        }

        this.personListSummary = {
            totalCount: 0,
            items: []
        }
        this.personListSummary_Security = {
            totalCount: 0,
            items: []
        }
        this.teamListSummary = {
            totalCount: 0,
            items: []
        }
        this.teamListSummary_Security = {
            totalCount: 0,
            items: []
        }

        this.communicationListCache = [];
        this.defaultCommunicationList = [];
        this.communicationListSummary = {
            totalCount: 0,
            items: []
        }

        this.inventoryListCache = [];
        this.defaultInventoryList = [];
        this.inventoryListSummary = {
            totalCount: 0,
            items: []
        }

        this.menuListCache = [];
        this.defaultMenuList = [];
        this.menuListSummary = {
            totalCount: 0,
            items: []
        }

        this.paymentsListCache = [];
        this.defaultPaymentsList = [];
        this.paymentsListSummary = {
            totalCount: 0,
            items: []
        }

        this.reportsListCache = [];
        this.defaultReportsList = [];
        this.reportsListSummary = {
            totalCount: 0,
            items: []
        }

        this.schedulesListCache = [];
        this.defaultSchedulesList = [];
        this.schedulesListSummary = {
            totalCount: 0,
            items: []
        }

        this.workOrdersListCache = [];
        this.defaultWorkOrdersList = [];
        this.workOrdersListSummary = {
            totalCount: 0,
            items: []
        }
        this.entitySummaryList = [];
        this.templateSummaryList = [];
        this.typeSummaryList = [];
        this.fieldSummaryList = [];
        this.peopleSummaryList = [];
        this.teamSummaryList = [];
    }

    @action
    async communication_initCacheStores() {

        this.communication_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.communication_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.communication_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async integrations_initCacheStores() {

        this.integrations_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.integrations_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async inventory_initCacheStores() {

        this.inventory_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.inventory_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async menu_initCacheStores() {

        this.menu_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.menu_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.menu_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async payments_initCacheStores() {

        this.payments_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.payments_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.payments_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async people_initCacheStores() {

        this.people_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.people_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.people_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async reports_initCacheStores() {

        this.reports_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.reports_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.reports_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async scheduling_initCacheStores() {

        this.scheduling_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.scheduling_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async teams_initCacheStores() {

        this.teams_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.teams_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.teams_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    @action
    async workOrders_initCacheStores() {

        this.workOrders_itemOptionsCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeItemsCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeStatesCache = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeItemsEdit = {
            totalCount: 0,
            items: []
        };

        this.workOrders_typeStatesEdit = {
            totalCount: 0,
            items: []
        };

    }

    //-------------------------------------------------

    @action
    async communication_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {


        this.communication_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myCommunication_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedCommunication_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await communicationService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: 50,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;
        entityParse.map((entity: any) => {


            if (entity !== undefined) {

                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.communication_entitySummary.items.push(entity);
                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myCommunication_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.communication?.communication?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.personas?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.integrations?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.inventory?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.menus?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.payments?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.inventory?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.teams?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.reports?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.schedules?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.communication?.workOrders?.map((x: any) => {
                        this.relatedCommunication_entitySummary.items.push(entity);
                    });
                }
            }
        });


    }

    @action
    async integrations_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.integrations_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myIntegrations_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedIntegrations_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await integrationService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.integrations_entitySummary.items.push(entity);
                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myIntegrations_entitySummary.items.push(entity);
                }


                currentLoginInfo.communication?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.integrations?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.inventory?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.menus?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.payments?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.inventory?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.teams?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.reports?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.schedules?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });

                currentLoginInfo.workOrders?.integrations?.map((x: any) => {

                    if (entity.id === x) {
                        this.relatedIntegrations_entitySummary.items.push(entity);
                    }

                });




            }
        });
    }

    @action
    async inventory_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.inventory_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myInventory_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedInventory_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await inventoryService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        console.warn(JSON.stringify(entitySummary))


        const entityParse = entitySummary.entities;



        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.inventory_entitySummary.items.push(entity);
                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myInventory_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.inventory?.communication?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.personas?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.integrations?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.inventory?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.menus?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.payments?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.inventory?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.teams?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.reports?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.schedules?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.inventory?.workOrders?.map((x: any) => {
                        this.relatedInventory_entitySummary.items.push(entity);
                    });
                }
            }
        });
    }

    @action
    async menu_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.menu_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myMenu_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        this.relatedMenu_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await menuService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.menu_entitySummary.items.push(entity);
                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myMenu_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.payments?.communication?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.personas?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.integrations?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.inventory?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.menus?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.payments?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.inventory?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.teams?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.reports?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.schedules?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.workOrders?.map((x: any) => {
                        this.relatedMenu_entitySummary.items.push(entity);
                    });
                }


            }
        });

    }

    @action
    async payments_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.payments_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myPayments_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        this.relatedPayments_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await paymentService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.payments_entitySummary.items.push(entity);
                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myPayments_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.payments?.communication?.map((x: any) => {

                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.personas?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);

                    });

                    currentLoginInfo.payments?.integrations?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.inventory?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.menus?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.payments?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.inventory?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.teams?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.reports?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.schedules?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });

                    currentLoginInfo.payments?.workOrders?.map((x: any) => {
                        this.relatedPayments_entitySummary.items.push(entity);
                    });
                }
            }
        });
    }

    @action
    async people_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.people_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myPersonas_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedPersonas_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await peopleService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.people_entitySummary.items.push(entity);
                }

                //if (entity.owner === currentLoginInfo.user.userID) {
                this.myPersonas_entitySummary.items.push(entity);
                //}
                // else {

                currentLoginInfo.personas?.communication?.map((x: any) => {

                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.personas?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);

                });

                currentLoginInfo.personas?.integrations?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.inventory?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.menus?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.payments?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.inventory?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.teams?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.reports?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.schedules?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });

                currentLoginInfo.personas?.workOrders?.map((x: any) => {
                    this.relatedPersonas_entitySummary.items.push(entity);
                });
                //}
            }
        });


        this.myPersonas_entitySummary.items.map(async (myProfile: any) => {

            this.myPersonas_entityReturn = await peopleService.get({
                entityType: RelatedEntityTypes.Entity,
                entityID: myProfile.id,
                filter: '',
                listFilter: false,
                availableFilter: false,
                entityMaxResultCount: this.entity_MaxResultCount,
                entitySkipCount: this.entity_SkipCount,
                actionMaxResultCount: this.action_MaxResultCount,
                actionSkipCount: this.action_SkipCount
            });

            const { data } = await axios.get(this.serverPath + this.myPersonas_entityReturn.entities[0].docEditBody, { responseType: 'text' });
            this.myPersonas_HTMLBody = data;
        });



    }

    @action
    async reports_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {


        this.reports_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        this.myReports_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedReports_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        let entitySummary = await reportService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.reports_entitySummary.items.push(entity);
                }



            }
        });



    }

    @action
    async scheduling_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.scheduling_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myScheduling_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedScheduling_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await schedulingService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });



        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.scheduling_entitySummary.items.push(entity);

                }

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myScheduling_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.schedules?.communication?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.personas?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.integrations?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.inventory?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.menus?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.payments?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.inventory?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.teams?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.reports?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.schedules?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.schedules?.workOrders?.map((x: any) => {


                        this.relatedScheduling_entitySummary.items.push(entity);


                    });
                }
            }
        });
    }

    @action
    async teams_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.teams_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myTeams_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.relatedTeams_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        let entitySummary = await teamService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });


        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                    this.teams_entitySummary.items.push(entity);
                }


                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myTeams_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.teams?.communication?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.personas?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.integrations?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.inventory?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.menus?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.payments?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.inventory?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.teams?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.reports?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.schedules?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.teams?.workOrders?.map((x: any) => {


                        this.relatedTeams_entitySummary.items.push(entity);


                    });
                }
            }
        });



    }

    @action
    async workOrders_getEntitySummary(keyword: any, currentLoginInfo: GetCurrentLoginInformations) {

        this.workOrders_entitySummary =
        {
            totalCount: 0,
            items: []
        }

        this.myWorkOrders_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        this.relatedWorkOrders_entitySummary =
        {
            totalCount: 0,
            items: []
        }


        let entitySummary = await workOrderService.getSummaryView({
            entityType: RelatedEntityTypes.Entity,
            entityID: 0,
            filter: keyword,
            listFilter: true,
            availableFilter: false,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        });

        const entityParse = entitySummary.entities;

        entityParse.map((entity: any) => {
            if (entity !== undefined) {
                //if (entity.relatedStatesVerbose[entity.currentState].type === StateTypes.last) {
                this.workOrders_entitySummary.items.push(entity);
                //}

                if (entity.owner === currentLoginInfo.user.userID) {
                    this.myWorkOrders_entitySummary.items.push(entity);
                }
                else {

                    currentLoginInfo.workOrders?.communication?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.personas?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.integrations?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.inventory?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.menus?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.payments?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.inventory?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.teams?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.reports?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.schedules?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });

                    currentLoginInfo.workOrders?.workOrders?.map((x: any) => {


                        this.relatedWorkOrders_entitySummary.items.push(entity);


                    });
                }
            }
        });
    }

    //-------------------------------------------------

    //--------------------------------------------------------------------------

    @action //gets Users for initial list
    async getUsers(entityID: number, relatedEntityType: number, mapRecords: boolean) {

        var userAsk = {
            entityType: RelatedEntityTypes.User,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(userAsk);

        this.users = {
            totalCount: 0,
            items: []
        }


        this.userListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultUserList = [];
        this.userListCache = [];
        this.usersearch_Mentions = [];

        result.users.map((x: any) => {
            this.users.items.push(x);
            this.users.totalCount = this.users.totalCount + 1;

            this.usersearch_Mentions.push({
                id: x.id,
                relatedEntity: x.userID,
                title: x.firstName + ' ' + x.lastName
            });




        });

        return;
    }

    @action //Add Notes to Temp List
    async addUser(user: UserListSummaryDto, isSecurity: boolean) {



        let userCache = user;

        if (isSecurity === true) {
            this.userListCache_Security.push(userCache);

            if (userCache.profileImageID === 0) {
                let url = await this.getProfileImage(userCache.profileImageID);
                if (url === '_') {
                    userCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    userCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.userListSummary_Security.items.push(userCache);
            this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount + 1;
        }
        else {
            this.userListCache.push(userCache);

            if (userCache.profileImageID === 0) {
                let url = await this.getProfileImage(userCache.profileImageID);
                if (url === '_') {
                    userCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    userCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.userListSummary.items.push(userCache);
            this.userListSummary.totalCount = this.userListSummary.totalCount + 1;
        }


        return;
    }

    @action //Remove Notes from Temp List
    async removeUser(recordID: number, isSecurity: boolean) {



        if (isSecurity === true) {
            this.userListCache_Security = this.userListCache_Security.filter((x: UserListSummaryDto) => x.userID !== recordID);
            this.userListSummary_Security.items = this.userListSummary_Security.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
            this.userListSummary_Security.totalCount = this.userListSummary_Security.totalCount - 1;
        }
        else {
            this.userListCache = this.userListCache.filter((x: UserListSummaryDto) => x.userID !== recordID);
            this.userListSummary.items = this.userListSummary.items.filter((x: UserListSummaryDto) => x.userID !== recordID);
            this.userListSummary.totalCount = this.userListSummary.totalCount - 1;
        }

        return;
    }

    @action //Sync Notes
    async syncUsers(entityID: number, entityRecords: any[]) {

        var userAddList = this.userListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.User) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.User,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Users,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.User,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await userService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action
    async getDocs(entityID: number, relatedEntityType: number, mapRecords: boolean) {

        var docAsk = {
            entityType: RelatedEntityTypes.InfoHub,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }


        let result = await communicationService.getSummaryView(docAsk);


        this.docs = {
            totalCount: 0,
            items: []
        }

        this.docListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultDocsList = [];
        this.docListCache = [];
        this.docsearch_Mentions = [];

        result.docs.map((x: any) => {
            this.docs.items.push(x);
            this.docs.totalCount = this.docs.totalCount + 1;
            this.docsearch_Mentions.push(x.name);


            if (mapRecords === true) {


            }
        });




        return;
    }

    @action
    async addDoc(doc: any) {

        let docCache = doc;
        this.docListCache.push(docCache);

        if (docCache.profileImageID === 0) {
            let url = await this.getProfileImage(docCache.profileImageID);
            if (url === '_') {
                docCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                docCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.docListSummary.items.push(docCache);
        this.docListSummary.totalCount = this.docListSummary.totalCount + 1;


        return;
    }

    @action
    async removeDoc(recordID: number) {

        this.docListCache = this.docListCache.filter((x: any) => x.docID !== recordID);
        this.docListSummary.items = this.docListSummary.items.filter((x: any) => x.docID !== recordID);
        this.docListSummary.totalCount = this.docListSummary.totalCount - 1;

        return;
    }

    @action //Sync Notes
    async syncDocs(entityID: number, entityRecords: any[]) {

        var userAddList = this.docListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.InfoHub) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.InfoHub,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.InfoHub,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.InfoHub,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            //await infoHubService.create(externalDto);
        }

        return;
    }
    //--------------------------------------------------------------------------

    @action
    async getPeople(entityID: number, relatedEntityType: number, mapRecords: boolean) {

        var teamAsk = {
            entityType: RelatedEntityTypes.People,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(teamAsk);

        this.persons = {
            totalCount: 0,
            items: []
        }

        this.personListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultPersonList = [];
        this.personListCache = [];
        this.personsearch_Mentions = [];

        result.people.map((x: any) => {
            this.persons.items.push(x);
            this.persons.totalCount = this.persons.totalCount + 1;
            this.personsearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.people_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.People && y.linkedEntityID === x.personID && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.personListSummary.items.push(x);
                        this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
                        this.defaultPersonList.push(x);
                        this.personListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addPerson(person: PersonListSummaryDto, isSecurity: boolean) {

        let personCache = person;

        if (isSecurity === true) {
            this.personListCache_Security.push(personCache);

            if (personCache.profileImageID === 0) {
                let url = await this.getProfileImage(personCache.profileImageID);
                if (url === '_') {
                    personCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    personCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.personListSummary_Security.items.push(personCache);
            this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount + 1;
        }
        else {
            this.personListCache.push(personCache);

            if (personCache.profileImageID === 0) {
                let url = await this.getProfileImage(personCache.profileImageID);
                if (url === '_') {
                    personCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    personCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.personListSummary.items.push(personCache);
            this.personListSummary.totalCount = this.personListSummary.totalCount + 1;
        }




        return;
    }

    @action
    async removePerson(recordID: number, isSecurity: boolean) {


        if (isSecurity === true) {
            this.personListCache_Security = this.personListCache_Security.filter((x: PersonListSummaryDto) => x.personID !== recordID);
            this.personListSummary_Security.items = this.personListSummary_Security.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
            this.personListSummary_Security.totalCount = this.personListSummary_Security.totalCount - 1;
        }
        else {
            this.personListCache = this.personListCache.filter((x: PersonListSummaryDto) => x.personID !== recordID);
            this.personListSummary.items = this.personListSummary.items.filter((x: PersonListSummaryDto) => x.personID !== recordID);
            this.personListSummary.totalCount = this.personListSummary.totalCount - 1;
        }


        return;
    }


    @action
    async syncPeople(entityID: number, entityRecords: any[]) {

        var userAddList = this.personListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.People) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.People,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Persons,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.People,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await peopleService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action //gets Teams for initial list
    async people_getTeams(entityID: number, relatedEntityType: number, mapRecords: boolean) {



        var teamAsk = {
            entityType: RelatedEntityTypes.Teams,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Persons,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await peopleService.getSummaryView(teamAsk);


        this.teams = {
            totalCount: 0,
            items: []
        }

        this.teamListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultTeamList = [];
        this.teamListCache = [];
        this.teamsearch_Mentions = [];

        result.teams.map((x: any) => {
            this.teams.items.push(x);
            this.teams.totalCount = this.teams.totalCount + 1;
            this.teamsearch_Mentions.push(x.name);
            if (mapRecords === true) {

                this.people_entityReturn.records.map((y: any) => {


                    if (y.linkedEntityType === RelatedEntityTypes.Teams && y.linkedEntityID === x.teamID && y.relatedEntityType === RelatedEntityTypes.Record) {

                        this.teamListSummary.items.push(x);
                        this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
                        this.defaultTeamList.push(x);
                        this.teamListCache.push(x);
                    }

                });

            }

        });


        return;
    }

    @action //Add Notes to Temp List
    async addTeam(team: TeamListSummaryDto, isSecurity: boolean) {


        let teamCache = team;

        if (isSecurity === true) {
            this.teamListCache_Security.push(teamCache);

            if (teamCache.profileImageID === 0) {
                let url = await this.getProfileImage(teamCache.profileImageID);
                if (url === '_') {
                    teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    teamCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.teamListSummary_Security.items.push(teamCache);
            this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount + 1;
        }
        else {
            this.teamListCache.push(teamCache);

            if (teamCache.profileImageID === 0) {
                let url = await this.getProfileImage(teamCache.profileImageID);
                if (url === '_') {
                    teamCache.profileImageURL = DataConsts.defaultProfileImageURL;
                }
                else {
                    teamCache.profileImageURL = url;
                }
            }

            //Add Note to Summary View
            this.teamListSummary.items.push(teamCache);
            this.teamListSummary.totalCount = this.teamListSummary.totalCount + 1;
        }


        return;
    }

    @action //Remove Notes from Temp List
    async removeTeam(recordID: number, isSecurity: boolean) {



        if (isSecurity === true) {
            this.teamListCache_Security = this.teamListCache_Security.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
            this.teamListSummary_Security.items = this.teamListSummary_Security.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
            this.teamListSummary_Security.totalCount = this.teamListSummary_Security.totalCount - 1;
        }
        else {
            this.teamListCache = this.teamListCache.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
            this.teamListSummary.items = this.teamListSummary.items.filter((x: TeamListSummaryDto) => x.teamID !== recordID);
            this.teamListSummary.totalCount = this.teamListSummary.totalCount - 1;
        }


        return;
    }

    @action //Sync Notes
    async syncTeams(entityID: number, entityRecords: any[]) {


        var userAddList = this.teamListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Teams) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Teams,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Teams,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Teams,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await teamService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action
    async getProfileImage(recordID: number) {

        if (recordID === 0 || recordID === 1) {
            return '_';
        }
        else {
            let result = await communicationService.getImage(recordID);
            return result.url;
        }
    }

    //--------------------------------------------------------------------------

    @action
    async getCommunication(entityID: number, relatedEntityType: number, mapRecords: boolean, entityReturn: any) {


        var summaryAsk = {
            entityType: RelatedEntityTypes.Communication,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        this.communication = {
            totalCount: 0,
            items: []
        }

        this.communicationListSummary = {
            totalCount: 0,
            items: []
        }

        let result = await communicationService.getSummaryView(summaryAsk);


        this.defaultCommunicationList = [];
        this.communicationListCache = [];
        this.communicationsearch_Mentions = [];
        result.communication.map((x: any) => {

            this.communication.items.push(x);
            this.communication.totalCount = this.communication.totalCount + 1;
            this.communicationsearch_Mentions.push(x.name);

            if (mapRecords === true) {

             
                entityReturn.records.map(async (y: any) => {

                    if (y.linkedEntityType === RelatedEntityTypes.Communication
                        && y.linkedEntityID === x.communicationID
                        && y.relatedEntityType === RelatedEntityTypes.Record) {
 
                        let updateItem: CommunicationListSummaryDto = {
                            id: this.communicationListSummary.totalCount,
                            communicationID: x.communicationID,
                            name: x.name,
                            title: x.title,
                            profileImageURL:  x.profileImageURL,
                            profileImageID: 0
                        }

                   

                        this.communicationListSummary.items.push(updateItem);
                        this.communicationListSummary.totalCount = this.communicationListSummary.totalCount + 1;
                        this.defaultCommunicationList.push(updateItem);
                        this.communicationListCache.push(updateItem);
 
                    }

                });

            }
        });

        return;
    }

    @action
    async addCommunication(communication: CommunicationListSummaryDto) {



        let communicationCache = communication;

        this.communicationListCache.push(communicationCache);

        if (communicationCache.profileImageID === 0) {
            let url = await this.getProfileImage(communicationCache.profileImageID);
            if (url === '_') {
                communicationCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                communicationCache.profileImageURL = url;
            }
        }


        //Add Note to Summary View
        this.communicationListSummary.items.push(communicationCache);
        this.communicationListSummary.totalCount = this.communicationListSummary.totalCount + 1;

        return;
    }

    @action
    async removeCommunication(recordID: number) {



        this.communicationListCache = this.communicationListCache.filter((x: CommunicationListSummaryDto) => x.communicationID !== recordID);
        this.communicationListSummary.items = this.communicationListSummary.items.filter((x: CommunicationListSummaryDto) => x.communicationID !== recordID);
        this.communicationListSummary.totalCount = this.communicationListSummary.totalCount - 1;


        return;
    }

    @action
    async syncCommunication(entityID: number, entityRecords: any[], linkType: number) {

        var userAddList = this.communicationListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Communication) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Communication,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: linkType,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: linkType,
                linkedEntityID: entityID
            })

        });

        await schedulingService.delete(deletDto);
        if (userAddList.length > 0) {
            await schedulingService.create(entityDto);
            await communicationService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action
    async getInventory(entityID: number, relatedEntityType: number, mapRecords: boolean) {

        var summaryAsk = {
            entityType: RelatedEntityTypes.Inventory,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(summaryAsk);

        this.inventory = {
            totalCount: 0,
            items: []
        }

        this.inventoryListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultInventoryList = [];
        this.inventoryListCache = [];
        this.inventorysearch_Mentions = [];

        result.inventory.map((x: any) => {
            this.inventory.items.push(x);
            this.inventory.totalCount = this.inventory.totalCount + 1;
            this.inventorysearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.inventory_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Inventory && y.linkedEntityID === x.inventoryID && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.inventoryListSummary.items.push(x);
                        this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount + 1;
                        this.defaultInventoryList.push(x);
                        this.inventoryListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addInventory(inventory: InventoryListSummaryDto) {



        let inventoryCache = inventory;
        this.inventoryListCache.push(inventoryCache);

        if (inventoryCache.profileImageID === 0) {
            let url = await this.getProfileImage(inventoryCache.profileImageID);
            if (url === '_') {
                inventoryCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                inventoryCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.inventoryListSummary.items.push(inventoryCache);
        this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount + 1;

        return;
    }

    @action
    async removeInventory(recordID: number) {



        this.inventoryListCache = this.inventoryListCache.filter((x: InventoryListSummaryDto) => x.inventoryID !== recordID);
        this.inventoryListSummary.items = this.inventoryListSummary.items.filter((x: InventoryListSummaryDto) => x.inventoryID !== recordID);
        this.inventoryListSummary.totalCount = this.inventoryListSummary.totalCount - 1;


        return;
    }

    @action
    async syncInventory(entityID: number, entityRecords: any[]) {

        var userAddList = this.inventoryListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Inventory) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Inventory,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Inventory,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Inventory,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await inventoryService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action
    async getMenus(entityID: number, relatedEntityType: number, mapRecords: boolean, entityRecords: any) {


        var summaryAsk = {
            entityType: RelatedEntityTypes.Menu,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(summaryAsk);

        this.menus = {
            totalCount: 0,
            items: []
        }

        this.menuListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultMenuList = [];
        this.menuListCache = [];
        this.menusearch_Mentions = [];

        result.menus.map((x: any) => {
            this.menus.items.push(x);
            this.menus.totalCount = this.menus.totalCount + 1;
            this.menusearch_Mentions.push(x.name);

            if (mapRecords === true) {

                entityRecords.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Menu && y.linkedEntityID === x.menuID && y.relatedEntityType === RelatedEntityTypes.Record) {

                        this.menuListSummary.items.push(x);
                        this.menuListSummary.totalCount = this.menuListSummary.totalCount + 1;
                        this.defaultMenuList.push(x);
                        this.menuListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addMenu(menu: MenuListSummaryDto) {



        let menuCache = menu;
        this.menuListCache.push(menuCache);

        if (menuCache.profileImageID === 0) {
            let url = await this.getProfileImage(menuCache.profileImageID);
            if (url === '_') {
                menuCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                menuCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.menuListSummary.items.push(menuCache);
        this.menuListSummary.totalCount = this.menuListSummary.totalCount + 1;

        return;
    }

    @action
    async removeMenu(recordID: number) {



        this.menuListCache = this.menuListCache.filter((x: MenuListSummaryDto) => x.menuID !== recordID);
        this.menuListSummary.items = this.menuListSummary.items.filter((x: MenuListSummaryDto) => x.menuID !== recordID);
        this.menuListSummary.totalCount = this.menuListSummary.totalCount - 1;

        return;
    }

    @action
    async syncMenus(entityID: number, entityRecords: any[]) {

        var userAddList = this.menuListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Menu) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Menu,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Menu,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Menu,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await menuService.create(externalDto);
        }

        return;
    }


    //--------------------------------------------------------------------------

    @action
    async getPayments(entityID: number, relatedEntityType: number, mapRecords: boolean) {


        var summaryAsk = {
            entityType: RelatedEntityTypes.Payments,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(summaryAsk);

        this.payments = {
            totalCount: 0,
            items: []
        }

        this.paymentsListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultPaymentsList = [];
        this.paymentsListCache = [];
        this.paymentsearch_Mentions = [];

        result.payments.map((x: any) => {
            this.payments.items.push(x);
            this.payments.totalCount = this.payments.totalCount + 1;
            this.paymentsearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.payments_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Payments && y.linkedEntityID === x.paymentID && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.paymentsListSummary.items.push(x);
                        this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount + 1;
                        this.defaultPaymentsList.push(x);
                        this.paymentsListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addPayment(payment: PaymentsListSummaryDto) {



        let paymentCache = payment;
        this.paymentsListCache.push(paymentCache);

        if (paymentCache.profileImageID === 0) {
            let url = await this.getProfileImage(paymentCache.profileImageID);
            if (url === '_') {
                paymentCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                paymentCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.paymentsListSummary.items.push(paymentCache);
        this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount + 1;

        return;
    }

    @action
    async removePayment(recordID: number) {



        this.paymentsListCache = this.paymentsListCache.filter((x: PaymentsListSummaryDto) => x.paymentID !== recordID);
        this.paymentsListSummary.items = this.paymentsListSummary.items.filter((x: PaymentsListSummaryDto) => x.paymentID !== recordID);
        this.paymentsListSummary.totalCount = this.paymentsListSummary.totalCount - 1;


        return;
    }

    @action
    async syncPayments(entityID: number, entityRecords: any[]) {

        var userAddList = this.paymentsListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Payments) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Payments,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Payments,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Payments,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await paymentService.create(externalDto);
        }

        return;
    }


    //--------------------------------------------------------------------------

    @action
    async getReports(entityID: number, relatedEntityType: number, mapRecords: boolean) {

        var summaryAsk = {
            entityType: RelatedEntityTypes.Reports,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await communicationService.getSummaryView(summaryAsk);

        this.reports = {
            totalCount: 0,
            items: []
        }

        this.reportsListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultReportsList = [];
        this.reportsListCache = [];
        this.reportsearch_Mentions = [];

        result.reports.map((x: any) => {
            this.reports.items.push(x);
            this.reports.totalCount = this.reports.totalCount + 1;
            this.reportsearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.reports_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Reports && y.linkedEntityID === x.reportID && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.reportsListSummary.items.push(x);
                        this.reportsListSummary.totalCount = this.reportsListSummary.totalCount + 1;
                        this.defaultReportsList.push(x);
                        this.reportsListCache.push(x);
                    }

                });

            }
        });

        return;
    }

    @action
    async addReport(report: ReportsListSummaryDto) {



        let reportCache = report;
        this.reportsListCache.push(reportCache);

        if (reportCache.profileImageID === 0) {
            let url = await this.getProfileImage(reportCache.profileImageID);
            if (url === '_') {
                reportCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                reportCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.reportsListSummary.items.push(reportCache);
        this.reportsListSummary.totalCount = this.reportsListSummary.totalCount + 1;

        return;
    }

    @action
    async removeReport(recordID: number) {



        this.reportsListCache = this.reportsListCache.filter((x: ReportsListSummaryDto) => x.reportID !== recordID);
        this.reportsListSummary.items = this.reportsListSummary.items.filter((x: ReportsListSummaryDto) => x.reportID !== recordID);
        this.reportsListSummary.totalCount = this.reportsListSummary.totalCount - 1;


        return;
    }

    @action
    async syncReports(entityID: number, entityRecords: any[]) {

        var userAddList = this.reportsListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Reports) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Reports,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Reports,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Reports,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await reportService.create(externalDto);
        }

        return;
    }


    //--------------------------------------------------------------------------

    @action
    async getSchedules(entityID: number, relatedEntityType: number, mapRecords: boolean) {



        var summaryAsk = {
            entityType: RelatedEntityTypes.Scheduling,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await inventoryService.getSummaryView(summaryAsk);

        this.schedules = {
            totalCount: 0,
            items: []
        }

        this.schedulesListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultSchedulesList = [];
        this.schedulesListCache = [];
        this.schedulingsearch_Mentions = [];

        result.schedules.map((x: any) => {
            this.schedules.items.push(x);
            this.schedules.totalCount = this.schedules.totalCount + 1;
            this.schedulingsearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.scheduling_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Scheduling && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.schedulesListSummary.items.push(x);
                        this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount + 1;
                        this.defaultSchedulesList.push(x);
                        this.schedulesListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addSchedule(schedule: SchedulingListSummaryDto) {



        let scheduleCache = schedule;
        this.schedulesListCache.push(scheduleCache);

        if (scheduleCache.profileImageID === 0) {
            let url = await this.getProfileImage(scheduleCache.profileImageID);
            if (url === '_') {
                scheduleCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                scheduleCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.schedulesListSummary.items.push(scheduleCache);
        this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount + 1;

        return;
    }

    @action
    async removeSchedule(recordID: number) {



        this.schedulesListCache = this.schedulesListCache.filter((x: SchedulingListSummaryDto) => x.scheduleID !== recordID);
        this.schedulesListSummary.items = this.schedulesListSummary.items.filter((x: SchedulingListSummaryDto) => x.scheduleID !== recordID);
        this.schedulesListSummary.totalCount = this.schedulesListSummary.totalCount - 1;

        return;
    }

    @action
    async syncSchedules(entityID: number, entityRecords: any[]) {

        var userAddList = this.schedulesListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Scheduling) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Scheduling,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Schedules,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Scheduling,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await schedulingService.create(externalDto);
        }

        return;
    }
    //--------------------------------------------------------------------------

    @action
    async getWorkOrders(entityID: number, relatedEntityType: number, mapRecords: boolean) {


        var summaryAsk = {
            entityType: RelatedEntityTypes.WorkOrder,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await inventoryService.getSummaryView(summaryAsk);

        this.workOrders = {
            totalCount: 0,
            items: []
        }

        this.workOrdersListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultWorkOrdersList = [];
        this.workOrdersListCache = [];
        this.workordersearch_Mentions = [];

        result.workOrders.map((x: any) => {
            this.workOrders.items.push(x);
            this.workOrders.totalCount = this.workOrders.totalCount + 1;
            this.workordersearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.workOrders_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.WorkOrder && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.workOrdersListSummary.items.push(x);
                        this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount + 1;
                        this.defaultWorkOrdersList.push(x);
                        this.workOrdersListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addWorkOrder(workOrder: WorkOrderListSummaryDto) {



        let workOrderCache = workOrder;
        this.workOrdersListCache.push(workOrderCache);

        if (workOrderCache.profileImageID === 0) {
            let url = await this.getProfileImage(workOrderCache.profileImageID);
            if (url === '_') {
                workOrderCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                workOrderCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.workOrdersListSummary.items.push(workOrderCache);
        this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount + 1;

        return;
    }

    @action
    async removeWorkOrder(recordID: number) {



        this.workOrdersListCache = this.workOrdersListCache.filter((x: WorkOrderListSummaryDto) => x.workOrderID !== recordID);
        this.workOrdersListSummary.items = this.workOrdersListSummary.items.filter((x: WorkOrderListSummaryDto) => x.workOrderID !== recordID);
        this.workOrdersListSummary.totalCount = this.workOrdersListSummary.totalCount - 1;

        return;
    }

    @action
    async syncWorkOrders(entityID: number, entityRecords: any[]) {

        var userAddList = this.workOrdersListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.WorkOrder) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.WorkOrder,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.WorkOrders,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.WorkOrder,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await workOrderService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    @action
    async getIntegrations(entityID: number, relatedEntityType: number, mapRecords: boolean) {


        var summaryAsk = {
            entityType: RelatedEntityTypes.Integrations,
            entityID: entityID,
            filter: '',
            moduleType: itemTypes.Communication,
            listFilter: false,
            availableFilter: false,
            relatedEntityType: relatedEntityType,
            entityMaxResultCount: this.entity_MaxResultCount,
            entitySkipCount: this.entity_SkipCount,
            actionMaxResultCount: this.action_MaxResultCount,
            actionSkipCount: this.action_SkipCount
        }

        let result = await integrationService.getSummaryView(summaryAsk);

        this.integrations = {
            totalCount: 0,
            items: []
        }

        this.integrationsListSummary = {
            totalCount: 0,
            items: []
        }

        this.defaultIntegrationsList = [];
        this.integrationsListCache = [];
        //this.integrationsearch_Mentions = [];

        result.integrations.map((x: any) => {
            this.integrations.items.push(x);
            this.integrations.totalCount = this.integrations.totalCount + 1;
            //this.integrationsearch_Mentions.push(x.name);

            if (mapRecords === true) {


                this.integrations_entityReturn.records.map((y: any) => {
                    if (y.linkedEntityType === RelatedEntityTypes.Integrations && y.linkedEntityID === x.id && y.relatedEntityType === RelatedEntityTypes.Record) {
                        this.integrationsListSummary.items.push(x);
                        this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount + 1;
                        this.defaultIntegrationsList.push(x);
                        this.integrationsListCache.push(x);
                    }

                });

            }
        });


        return;
    }

    @action
    async addIntegration(integration: IntegrationListSummaryDto) {



        let integrationCache = integration;
        this.integrationsListCache.push(integrationCache);

        if (integrationCache.profileImageID === 0) {
            let url = await this.getProfileImage(integrationCache.profileImageID);
            if (url === '_') {
                integrationCache.profileImageURL = DataConsts.defaultProfileImageURL;
            }
            else {
                integrationCache.profileImageURL = url;
            }
        }

        //Add Note to Summary View
        this.integrationsListSummary.items.push(integrationCache);
        this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount + 1;

        return;
    }

    @action
    async removeIntegration(recordID: number) {


        this.integrationsListCache = this.integrationsListCache.filter((x: IntegrationListSummaryDto) => x.integrationID !== recordID);
        this.integrationsListSummary.items = this.integrationsListSummary.items.filter((x: IntegrationListSummaryDto) => x.integrationID !== recordID);
        this.integrationsListSummary.totalCount = this.integrationsListSummary.totalCount - 1;

        return;
    }

    @action
    async syncIntegrations(entityID: number, entityRecords: any[]) {

        var userAddList = this.integrationsListCache;

        var deletDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        entityRecords.map((z: any) => {

            if (z.linkedEntityType === RelatedEntityTypes.Integrations) {

                deletDto.records.push({
                    id: z.id,
                    relatedEntityType: RelatedEntityTypes.Record,
                    relatedEntityID: z.relatedEntityID,
                    linkedEntityType: RelatedEntityTypes.Integrations,
                    linkedEntityID: z.linkedEntityID
                })
            }

        })

        var externalDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Integrations,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        var entityDto: any = {
            relatedEntityType: RelatedEntityTypes.Record,
            itemType: itemTypes.Communication,
            skipCount: 0,
            maxResultCount: 100,
            entities: [],
            items: [],
            options: [],
            states: [],
            types: [],
            records: [],
            templates: [],
            values: [],
            notes: [],
            noteRecords: [],
            templateRecords: []
        }

        userAddList.map((x: any) => {

            entityDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: entityID,
                linkedEntityType: RelatedEntityTypes.Integrations,
                linkedEntityID: x.id
            })

            externalDto.records.push({
                id: 0,
                relatedEntityType: RelatedEntityTypes.Record,
                relatedEntityID: x.id,
                linkedEntityType: RelatedEntityTypes.Communication,
                linkedEntityID: entityID
            })

        });

        await communicationService.delete(deletDto);
        if (userAddList.length > 0) {
            await communicationService.create(entityDto);
            await integrationService.create(externalDto);
        }

        return;
    }

    //--------------------------------------------------------------------------

    getMatchingUserImage(e: any): any {
        throw new Error('Method not implemented.');
    }
    getMatchingUserName(owner: any): any {
        throw new Error('Method not implemented.');
    }
    getMatchingTeamName(owner: any): any {
        throw new Error('Method not implemented.');
    }
    getMatchingPeopleName(owner: any): any {
        throw new Error('Method not implemented.');
    }
    getMatchingTeamImage(owner: any): any {
        throw new Error('Method not implemented.');
    }
    getMatchingPeopleImage(owner: any): any {
        throw new Error('Method not implemented.');
    }

}

export default DashboardStore;
