import { IsTenantAvaibleInput } from './dto/isTenantAvailableInput';
import IsTenantAvaibleOutput from './dto/isTenantAvailableOutput';
import http from '../httpService';
import ForgotPasswordDto  from '../user/dto/forgotPasswordDto';
import { ContactUsInput }  from './dto/contactUsInput';
import { SignUpInput }  from './dto/signUpInput'; 
import { ConfirmPasswordDto } from './dto/confirmPasswordDto';
import { CreateOrUpdateUserInput } from '../user/dto/createOrUpdateUserInput';
import { CreateProfileDto } from './dto/createProfileDto';

class AccountService {
  public async isTenantAvailable(isTenantAvaibleInput: IsTenantAvaibleInput): Promise<IsTenantAvaibleOutput> {
    let result = await http.post('api/services/app/Account/IsTenantAvailable', isTenantAvaibleInput);
 
    return result.data.result;
  }
 
  public async contactUs(contactUsInput: ContactUsInput) {  
    let result = await http.post('api/services/app/Account/ContactUsBSI', contactUsInput);
    return result.data.result;
  }

  public async contactInvoice(contactUsInput: ContactUsInput) {
    let result = await http.post('api/services/app/Apps/ConfirmOrderJBF', contactUsInput);
    return result.data.result;
  }

  public async signUp(signUpInput: SignUpInput) {
    let result = await http.post('api/services/app/Apps/SignUp', signUpInput);
    return result.data.result;
  }

  public async forgotPasswordReset(forgotPasswordDto: ForgotPasswordDto) {
    let result = await http.post('api/services/app/Apps/ForgotPasswordReset', forgotPasswordDto);
    return result.data.result;
  }

  public async confirmPassword(confirmPasswordInput: ConfirmPasswordDto) {
    let result = await http.post('api/services/app/Account/ConfirmPassword', confirmPasswordInput);
    return result.data.result;
  }

  public async registerUser(confirmPasswordInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/Account/RegisterUser', confirmPasswordInput);
    return result.data.result;
  }

  public async registerProfile(confirmPasswordInput: CreateProfileDto) {
    let result = await http.post('api/services/app/Account/RegisterProfile', confirmPasswordInput);
    return result.data.result;
  }

  public async userSignup(confirmPasswordInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/Account/UserSignup', confirmPasswordInput);
    return result.data.result;
  }

  public async registerNewsletter(confirmPasswordInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/Account/RegisterNewsletter', confirmPasswordInput);
    return result.data.result;
  }
 
  public async forgotPassword(confirmPasswordInput: CreateOrUpdateUserInput) {
    let result = await http.post('api/services/app/Account/ForgotPassword', confirmPasswordInput);
    return result.data.result;
  }

}

export default new AccountService();
