import { action, observable } from 'mobx';
import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import  SMSVerification  from '../services/account/dto/smsVerification';
import accountService from '../services/account/accountService';
import type { RegisterInput } from '../services/account/dto/registerInput';

import type { SignUpInput } from '../services/account/dto/signUpInput';
import type { ContactUsInput } from '../services/account/dto/contactUsInput';
import type { ScheduleDemoInput } from '../services/account/dto/scheduleDemoInput';

import  RegisterOutput  from '../services/account/dto/registerOutput';
import  ForgotPasswordDto  from '../services/user/dto/forgotPasswordDto';
import { ConfirmPasswordDto } from '../services/account/dto/confirmPasswordDto';
import { CreateOrUpdateUserInput } from '../services/user/dto/createOrUpdateUserInput';
import { CreateProfileDto } from '../services/account/dto/createProfileDto';

class AccountStore {
  @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();
  @observable registerOutput: RegisterOutput = new RegisterOutput();
  @observable verifySMSOutput: SMSVerification = new SMSVerification();
  @observable forgotPasswordOutput: ForgotPasswordDto = new ForgotPasswordDto();
  @observable registerInput!: RegisterInput;
  @observable contactInput!: ContactUsInput;
  @observable signUpInput!: SignUpInput;
  @observable scheduleDemoInput!: ScheduleDemoInput;
  @observable forgotPasswordInput!: ForgotPasswordDto;

  @action
  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });
 
    //this.tenant = {
   //   state: 1,
   //  tenantId:  
   // }
  };
 
  @action
  public confirmPassword = async (confirmPasswordDtp: ConfirmPasswordDto) => {
    var returnObject = await accountService.confirmPassword(confirmPasswordDtp);
    return returnObject; 
  };

  @action
  public registerUser = async (createUserInput: CreateOrUpdateUserInput) => {
  

    var returnObject = await accountService.registerUser(createUserInput);
 

    return returnObject; 
  };

  @action
  public registerProfile = async (createUserInput: CreateProfileDto) => {
 

    var returnObject = await accountService.registerProfile(createUserInput);

 

    return returnObject; 
  };

  @action
  public userSignup = async (createUserInput: CreateOrUpdateUserInput) => {
 

    var returnObject = await accountService.userSignup(createUserInput);
 

    return returnObject; 
  };

  @action
  public registerNewsletter = async (createUserInput: CreateOrUpdateUserInput) => {
 

    var returnObject = await accountService.registerNewsletter(createUserInput);
 
  
    return returnObject; 
  };

 
  @action
  public forgotPassword = async (createUserInput: CreateOrUpdateUserInput) => {
 
    var returnObject = await accountService.forgotPassword(createUserInput);
    return returnObject; 
  };

  @action
  public storeContactInfo = async (contactUsInput: ContactUsInput) => {
 
    this.contactInput = { 
      email: contactUsInput.email,
      name: contactUsInput.name,
      message: contactUsInput.message,
      scheduleDate: contactUsInput.scheduleDate,
      scheduleID: contactUsInput.scheduleID,
      interest: contactUsInput.interest
    }
  };

  @action
  public storeSignUp = async (signUpInput: SignUpInput) => {

    this.signUpInput = {
      
      name: signUpInput.email,
      phone: signUpInput.phone,
      companyName: signUpInput.companyName,
      email: signUpInput.email,
      criteria: ['none']
    }
  };

  @action
  public storeScheduleDemo = async (scheduleDemoInput: ScheduleDemoInput) => {

    this.scheduleDemoInput = {
      
      name: scheduleDemoInput.name,
      surname: scheduleDemoInput.surname,
      email: scheduleDemoInput.email,
      message: scheduleDemoInput.message
    }
  };
 
  @action
  public contact = async (contact: ContactUsInput) => {
    var returnObject = await accountService.contactUs(contact);
    this.registerOutput = returnObject;
  };

  @action
  public contactInvoice = async (location: any) => {
    var returnObject = await accountService.contactInvoice(this.contactInput);
    this.registerOutput = returnObject;


     const { state } =  location;
      window.location = state ? state.from.pathname : '/logout';
  };

  @action
  public signUp = async () => {
    var returnObject = await accountService.signUp(this.signUpInput);
    this.registerOutput = returnObject;
  };
   

}

export default AccountStore;
