import http from "../httpService";
import type {ScanbotKeyDto} from "../session/dto/scanbotKeyDto";
import type {ScanbottokenDto} from "../session/dto/scanbotTokenDto";
import { ScanBotScanSummaryDto } from "./dto/scanBotScanSummaryDto";
import {  ScanbotScanDto, ScanbotSessionCreateDto } from "./dto/scanbotAskDto";
import { ScanbotGetAskDto } from "./dto/scanbotGetAskDto";
import {   ScannerInfo, ScannerItem, ScannerSessionReturnDto } from "./dto/scanbotSessionReturnDto";
import { ScannerDeleteDto } from "./dto/scannerDeleteDto";
import { ScannerHistoryListReturnDto, HistoryListRecord } from "./dto/scannerHistoryListReturnDto";
import { ScannerHistoryReturnDto, HistoryRecord, JobRecord, QueueRecord } from "./dto/scannerHistoryReturnDto";
import { ScannerJobsReturnDto, Job } from "./dto/scannerJobsReturnDto";
import { ScannerListReturnDto, Scanner } from "./dto/scannerListReturnDto";
import { ScannerSessionCreateReturn, ScannerSessionResults, ScannerSessionStatus } from "./dto/scannerSessionCreateReturn";
import { PossibleSettingValuesDto, ScannerSettingsReturnDto, SettingValuesDto } from "./dto/scannerSettingsReturnDto";
import { ScannerStateReturnDto, State } from "./dto/scannerStateReturnDto";
import { TokenDto } from "./dto/tokenDto";
 
declare var abp: any;

class ScanbotService {
  
    public async startScan(askDto: ScanbotScanDto): Promise<ScanBotScanSummaryDto> {
 
        let getRecordsResult = await http.post('api/services/app/ScanBot/Scan', askDto ); 
        return getRecordsResult.data.result;
    } 
 

    public async getScanbotAPIToken(askDto: ScanbotKeyDto): Promise<ScanbottokenDto> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetAPIToken', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async getPrivateKey(askDto: ScanbotGetAskDto): Promise<ScanbottokenDto> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetPrivateKey', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async getScannerSession(askDto: ScanbotGetAskDto): Promise<ScannerSessionReturnDto<ScannerItem<ScannerInfo>>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetScannerSession', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async getScannerState(askDto: ScanbotGetAskDto): Promise<ScanbottokenDto> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetScannerState', { params: askDto });
        return getRecordsResult.data.result;
    } 

    public async getScannerStatus(askDto: ScanbotGetAskDto): Promise<ScanbottokenDto> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetScannerStatus', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async GetDetailedHistoryRecord(askDto: ScanbotGetAskDto): Promise<ScannerHistoryReturnDto<HistoryRecord,JobRecord,QueueRecord>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetDetailedHistoryRecord', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    //-------------------------------------------------------------
 
    public async getScannerSummary(askDto: TokenDto): Promise<ScannerListReturnDto<Scanner>> {
    
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetScannerSummary', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async getJobSummary(askDto: TokenDto): Promise<ScannerJobsReturnDto<Job>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetJobSummary', { params: askDto });
        return getRecordsResult.data.result;
    } 
  
    public async getStateSummary(askDto: TokenDto): Promise<ScannerStateReturnDto<State>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetStateSummary', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    public async getHistorySummary(askDto: TokenDto): Promise<ScannerHistoryListReturnDto<HistoryListRecord>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetHistorySummary', { params: askDto });
        return getRecordsResult.data.result;
    } 
   
    public async GetSettingSummary(askDto: TokenDto): Promise<ScannerSettingsReturnDto<SettingValuesDto<PossibleSettingValuesDto>>> {
   
        let getRecordsResult = await http.get('api/services/app/ScanBot/GetSettingSummary', { params: askDto });
        return getRecordsResult.data.result;
    } 

    //-------------------------------------------------------------
  
    public async createScanner(askDto: Scanner): Promise<ScannerListReturnDto<Scanner>> {
   
        let getRecordsResult = await http.post('api/services/app/ScanBot/CreateScanner', askDto);
        return getRecordsResult.data.result;
    } 

    public async updateScanner(askDto: Scanner): Promise<ScannerListReturnDto<Scanner>> {
   
        let getRecordsResult = await http.put('api/services/app/ScanBot/UpdateScanner', { params: askDto });
        return getRecordsResult.data.result;
    } 

    public async deleteScanner(askDto: ScannerDeleteDto): Promise<ScannerListReturnDto<Scanner>> {
   
        let getRecordsResult = await http.delete('api/services/app/ScanBot/DeleteScanner', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
    //-------------------------------------------------------------
     
    public async startScannerSession(askDto: ScanbotSessionCreateDto): Promise<ScannerSessionCreateReturn<ScannerSessionResults<ScannerSessionStatus>>> {
   
        let getRecordsResult = await http.post('api/services/app/ScanBot/StartScannerSession', { params: askDto });
        return getRecordsResult.data.result;
    } 

    public async endScannerSession(askDto: string): Promise<ScannerSessionCreateReturn<ScannerSessionResults<ScannerSessionStatus>>> {
   
        let getRecordsResult = await http.post('api/services/app/ScanBot/EndScannerSession', { params: askDto });
        return getRecordsResult.data.result;
    } 
 
}

export default new ScanbotService();
