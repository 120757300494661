export default class Stores 
{
  //Default & Account/User Stores
  static AuthenticationStore: string = 'authenticationStore';
  static RoleStore: string = 'roleStore';
  static TenantStore: string = 'tenantStore';
  static UserStore: string = 'userStore';
  static SessionStore: string = 'sessionStore';
  static AccountStore: string = 'accountStore';
 
  static InventoryStore: string = 'inventoryStore';
  static CommunicationStore: string = 'communicationStore';
  static IntegrationStore: string = 'integrationStore';
  static MenuStore: string = 'menuStore';
  static PeopleStore: string = 'peopleStore';
  static TeamStore: string = 'teamStore';
  static ReportStore: string = 'reportStore';
  static SchedulingStore: string = 'schedulingStore';
  static WorkOrderStore: string = 'workOrderStore'; 
  static PaymentStore: string = 'paymentStore';
  static DashboardStore: string = 'dashboardStore';
  static InfoHubStore: string = 'infoHubStore';
  static ScanbotStore: string = 'scanbotStore';
  static Stores: any;
}
