import RoleStore from './roleStore';
import TenantStore from './tenantStore';
import UserStore from './userStore';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import DashboardStore from './dashboardStore';
import InventoryStore from './inventoryStore';
import PaymentStore from './paymentStore';
import CommunicationStore from './communicationStore';
import IntegrationStore from './integrationStore';
import MenuStore from './menuStore';
import PeopleStore from './peopleStore';
import TeamStore from './teamStore';
import ReportStore from './reportStore';
import SchedulingStore from './schedulingStore';
import WorkOrderStore from './workOrderStore';
import InfoHubStore from './infohubStore';
import ScanbotStore from './scanbotStore';



export default function initializeStores() {
  return {
     
    //Default & Account/User Stores
    authenticationStore: new AuthenticationStore(),
    roleStore: new RoleStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    sessionStore: new SessionStore(),
    accountStore: new AccountStore(),
 
    inventoryStore: new InventoryStore(),
    paymentStore: new PaymentStore(),
    communicationStore: new CommunicationStore(),
    integrationStore: new IntegrationStore(),
    menuStore: new MenuStore(),
    peopleStore: new PeopleStore(),
    teamStore: new TeamStore(),
    reportStore: new ReportStore(),
    schedulingStore: new SchedulingStore(),
    workOrderStore: new WorkOrderStore(),
    dashboardStore: new DashboardStore(),
    infoHubStore: new InfoHubStore(),
    scanbotStore: new ScanbotStore(),
  };
}
